import Exit from '../containers/exit'
import { error } from '../containers/const'
import serviceApi from './serviceApi';

//Получение списка заказов текущего пользователя
export const ordersList = (min = 1, max = 20, select_from = null, select_to = null) =>
    serviceApi(`/ticket-wc-api/v1/get-orders-list/`, {
        min: min,
        max: max,
        select_from: select_from,
        select_to: select_to,
        print_all_count: true,
    })
        .then(function (response) {
            return response.data
        }).catch((error) => {
            //
            return error.notFound
        });

//Получение заказа любого пользователя
export const anyOrder = (order_id, ticket_template_type_id) =>
    serviceApi(`/ticket-wc-api/v1/get-any-order/`, {
        order_id: order_id,
        ticket_template_type_id
    })
        .then(function (response) {
            return response.data
        })
        .catch((error) => {

        });

//Получение списка типов шаблонов билетов
export const ticketTemplateTypesList = () =>
    serviceApi(`/ticket-wc-api/v1/get-ticket-template-types-list/`)
        .then(function (response) {
            return response.data
        })
        .catch((error) => {

        });

// Удаление записи заказа текущего пользователя
export const orderEntityDelete = (order_entity_id) =>
    serviceApi(`/ticket-wc-api/v1/order-entity-delete/`, {
        order_id: order_entity_id
    })
        .then(function (response) {
            return response.data
        })
        .catch((error) => {
            return error
        });

//Удаление заказа текущего пользователя
export const orderDelete = (order_id) =>
    serviceApi(`/ticket-wc-api/v1/order-delete/`, {
        order_id: order_id
    })
        .then(function (response) {
            return response.data
        })
        .catch((error) => {
            throw error
        });

// Возврат записи заказа (одного билета)
export const sellerOrderEntityReturn = (order_entity_id, ident_type_id, is_cash_active = false, check_no = null) =>
    serviceApi(`/ticket-wc-api/v1/seller-order-entity-return/`, {
        order_entity_id: order_entity_id,
        ident_type_id: ident_type_id,
        is_cash_active: is_cash_active,
        check_no: check_no
    })
        .then(function (response) {
            return response.data
        })
        .catch((error) => {
            throw error
        });

export const reservePlaced = () =>
    serviceApi(`/ticket-api/v1/ticket-reserve/`).then(function (response) {
        return response.data
    })

export const currentOrder = () =>
    serviceApi(`/ticket-api/v1/get-order/`)

export const reservedOrder = (search) =>
    serviceApi(`/ticket-wc-api/v1/get-any-special-basket/`, {
        ...search
    }).then(function (response) {
        return response.data
    }).catch((err) => {
        return error.notFound
    }
    )

export const reservedList = () =>
    serviceApi(`/ticket-wc-api/v1/get-special-baskets-list/`, {
        is_active_only: true,
        min: 1,
        max: 20,
    }).then(function (response) {
        return response.data
    }).catch((error) => {
        return error
    }
    )

export const reportsList = (show_details = false, sale_from, sale_to, report_format = 2) =>
    serviceApi(`/ticket-wc-api/v1/seller-user-sales-report/`, {
        show_details: show_details,
        sale_from: sale_from,
        sale_to: sale_to,
        report_format: report_format,
    }).then(function (response) {
        return response.data
    }).catch((error) => {
        return error
    }
    )

export const ticketCheckProm = (basket_id, discount = null, promo = null) =>
    serviceApi(`/ticket-wc-api/v1/ticket-check-promo/`, {
        basket_id: basket_id,
        discount: discount,
        promo: promo,
    }).then(function (response) {
        return response.data
    }).catch((error) => {
        return error.response.data
    });

export const deleteTicketSpecialBasket = (special_basket_entity_id = "1") =>
    serviceApi(`/ticket-wc-api/v1/special-basket-entity-delete/`, {
        special_basket_entity_id: special_basket_entity_id,
    }).then(function (response) {
        return response.data
    }).catch((er) => {
        return error.notFound
    }
    )

export const listSpecialBaskets = (min, max) =>
    serviceApi(`/ticket-wc-api/v1/get-all-special-baskets-list/`, {
        is_active_only: true,
        min: min,
        max: max,
    }).then(function (response) {
        return response.data
    }).catch((er) => {
        return error.notFound
    }
    )



export const deleteSpecialBasket = (special_basket_entity_id) =>
    serviceApi(`/ticket-wc-api/v1/special-basket-delete/`, {
        special_basket_id: special_basket_entity_id
    }).then(function (response) {
        return response.data
    }).catch((error) => {
        return error
    }
    )


export const ticketByPrice = (priceId, count, discount_id = null, promo = null) =>
    serviceApi(`/ticket-wc-api/v1/tickets-reserve-by-price/`,
        {
            price_id: priceId,
            count: count,
            discount: discount_id,
            promo: promo,

        }).then(function (response) {
            return response.data.basket_entities
        }).catch((err) => {
            throw err
        }
        )

export const getPdfTickets = (order_id, ticket_template_type_id) => {
    return serviceApi(`/ticket-wc-api/v1/order-get-ticket/`,
        {
            order_id: order_id,
            ticket_template_type_id: ticket_template_type_id,

        }).then(function (response) {
            return response.data
        }).catch((err) => {
            throw err
        }
        )
}

export const getTicketsTemplate = () => {
    return serviceApi(`/ticket-wc-api/v1/get-ticket-template-types-list/`)
        .then(function (response) {
            return response.data
        }).catch((err) => {
            throw err
        }
        )
}

export const getAccessTicketsTemplate = (order_id) => {
    return serviceApi(`/ticket-wc-api/v1/get-order-valid-tictempl-types/`, {
        min: 0,
        max: 999,
        order_id,
    })
        .then(function (response) {
            return response.data
        }).catch((err) => {
            throw err
        }
        )

}