import React, {Component} from "react";
import Header from "../SwitchingHeader";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {reportsList} from "../../api/ticket";
import {connect} from "react-redux";
import {parseXML} from "../../helpers";
import axios from "axios";
import Spinner from "../Spinner";
import printJS from "print-js";

class Reports extends Component{
    constructor(props) {
        super(props);

        this.state = {
            showDetails: false,
            dateFrom: new Date( new Date().setDate(new Date().getDate() - 6)),
            dateTo: new Date(),
            reportFormat: 2,
            isSpinner: false,
            urlReportsXml: null,
            urlReportsPdf: null
        }
    }

    componentDidMount() {
        this.getReportsList();
    }

    getReportsList = async () => {
        this.setState({
            isSpinner: true,
        });
        const { auth } = this.props;
        const { showDetails, dateFrom, dateTo, reportFormat } = this.state;
        const currentDateFrom = !!dateFrom ? moment(dateFrom).format('YYYY-MM-DD'): dateFrom;
        const currentDateTo = !!dateTo ? moment(dateTo).format('YYYY-MM-DD') : dateTo;
        let listReports = await reportsList(showDetails, currentDateFrom, currentDateTo, reportFormat);
        let listReportsPdf = await reportsList(showDetails, currentDateFrom, currentDateTo, 8);
        this.setState({
            urlReportsXml: listReports.path,
            urlReportsPdf: listReportsPdf.path
        })
        axios.get(listReports.path)
            .then((respons) => {
                // console.log(respons)
                const result = parseXML(respons.data, 'Worksheet');
                // console.log(result)
                // debugger;
                this.setState({
                    isSpinner: false,
                });
            })
            .catch((error) => {
                console.log(error)
            })
        // const result = parseXML(listReports.path);
    };

    handleChangeDateFrom = (date) => {
        this.setState({
            dateFrom: date,
        }, function () {
            // this.getOrdersList();
        })
    };

    handleChangeDateTo = date => {
        this.setState({
            dateTo: date
        }, function () {
            // this.getOrdersList();
        })
    };


    render() {
        const {showDetails, dateFrom, dateTo, urlReportsXml, urlReportsPdf, isSpinner} = this.state;

        const datePickerFrom = !!dateFrom ? dateFrom : new Date();
        const datePickerTo = !!dateTo ? dateTo : new Date();

        return (
            <>
                <nav className="nav">
                    <Header page="reports" />
                </nav>
                <div className="content-wrap">
                    <div className="content">
                        <div className="heading">
                            <h1>Отчеты</h1>
                        </div>
                        <div className="table-filter">
                            <div className="table-filter__title">Период</div>
                            <div className="table-filter__date-range">
                                <div className="input">
                                    <span className="svg-icon icon-calendar"></span>
                                    <DatePicker
                                        dateFormat="dd MMM yyyy"
                                        selected={datePickerFrom}
                                        onChange={this.handleChangeDateFrom}
                                        locale='ru'
                                        maxDate={datePickerTo}
                                    />
                                </div>
                                <div className="input">
                                    <span className="svg-icon icon-calendar"></span>
                                    <DatePicker
                                        dateFormat="dd MMM yyyy"
                                        selected={datePickerTo}
                                        onChange={this.handleChangeDateTo}
                                        locale='ru'
                                        minDate={datePickerFrom}
                                    />
                                </div>
                            </div>
                            <div className="table-filter__checkbox js-table-details">
                                <input type="checkbox" id="checkbox" onChange={() => this.setState({showDetails: !showDetails})}/>
                                <label htmlFor="checkbox" className="checkbox"></label>
                                <label htmlFor="checkbox">Детализированно</label>
                            </div>
                            <div className="table-filter__button">
                                <button type="button" className="btn btn-orange" onClick={this.getReportsList}>Показать отчет</button>
                            </div>
                        </div>
                        <div className="table-wrap">
                            {!isSpinner ?
                                <div className="table report-table">
                                    <div className="table-head">
                                        <div className="table-row">
                                            <div className="table-col report-user">Пользователь</div>
                                            <div className="table-col report-date">Дата</div>
                                            <div className="table-col report-time">Время</div>
                                            <div className="table-col report-number">№ заказа</div>
                                            <div className="table-col report-method">Способ оплаты</div>
                                            <div className="table-col report-event">Мероприятие</div>
                                            <div className="table-col report-type">Тип</div>
                                            <div className="table-col report-price">Цена, BYN</div>
                                            <div className="table-col report-count">Количество</div>
                                            <div className="table-col report-cost">Стоимость, BYN</div>
                                        </div>
                                    </div>
                                    <div className="table-body">
                                        <div className="table-row">
                                            <div className="table-col report-user">pos_ticket</div>
                                            <div className="table-col report-date">1 сен 2019</div>
                                            <div className="table-col report-time">18:00</div>
                                            <div className="table-col report-number">654321</div>
                                            <div className="table-col report-method">Банковская карта</div>
                                            <div className="table-col report-event">Король лев 2D</div>
                                            <div className="table-col report-type">Кино</div>
                                            <div className="table-col report-price">8,25</div>
                                            <div className="table-col report-count">437</div>
                                            <div className="table-col report-cost">23 258,25</div>
                                        </div>
                                        <div className="table-row">
                                            <div className="table-col report-user">pos_ticket</div>
                                            <div className="table-col report-date">1 сен 2019</div>
                                            <div className="table-col report-time">18:00</div>
                                            <div className="table-col report-number">434657</div>
                                            <div className="table-col report-method">Наличные</div>
                                            <div className="table-col report-event">Король лев 2D</div>
                                            <div className="table-col report-type">Кино</div>
                                            <div className="table-col report-price">8,25</div>
                                            <div className="table-col report-count">437</div>
                                            <div className="table-col report-cost">23 258,25</div>
                                        </div>
                                        <div className="table-row">
                                            <div className="table-col report-user">tickeы_456t</div>
                                            <div className="table-col report-date">28 авг 2019</div>
                                            <div className="table-col report-time">18:00</div>
                                            <div className="table-col report-number">436547</div>
                                            <div className="table-col report-method">Банковская карта</div>
                                            <div className="table-col report-event">ФК “Батє” - ФК “Сараево”</div>
                                            <div className="table-col report-type">Спорт</div>
                                            <div className="table-col report-price">2,00</div>
                                            <div className="table-col report-count">437</div>
                                            <div className="table-col report-cost">23 258,25</div>
                                        </div>
                                        <div className="table-row">
                                            <div className="table-col report-user">events_for_your_family</div>
                                            <div className="table-col report-date">20 авг 2019</div>
                                            <div className="table-col report-time">18:00</div>
                                            <div className="table-col report-number">432347</div>
                                            <div className="table-col report-method">Банковская карта</div>
                                            <div className="table-col report-event">ФК “Батє” - ФК “Сараево”</div>
                                            <div className="table-col report-type">Спорт</div>
                                            <div className="table-col report-price">2,00</div>
                                            <div className="table-col report-count">437</div>
                                            <div className="table-col report-cost">23 258,25</div>
                                        </div>
                                        <div className="table-row">
                                            <div className="table-col report-user">pos_ticket</div>
                                            <div className="table-col report-date">18 авг 2019</div>
                                            <div className="table-col report-time">16:00</div>
                                            <div className="table-col report-number">656354</div>
                                            <div className="table-col report-method">Банковская карта</div>
                                            <div className="table-col report-event">ФК “Батє” - ФК “Сараево”</div>
                                            <div className="table-col report-type">Спорт</div>
                                            <div className="table-col report-price">2,00</div>
                                            <div className="table-col report-count">437</div>
                                            <div className="table-col report-cost">23 258,25</div>
                                        </div>
                                        <div className="table-row">
                                            <div className="table-col report-user">pos_ticket</div>
                                            <div className="table-col report-date">1 сен 2019</div>
                                            <div className="table-col report-time">18:00</div>
                                            <div className="table-col report-number">654321</div>
                                            <div className="table-col report-method">Банковская карта</div>
                                            <div className="table-col report-event">Король лев 2D</div>
                                            <div className="table-col report-type">Кино</div>
                                            <div className="table-col report-price">8,25</div>
                                            <div className="table-col report-count">437</div>
                                            <div className="table-col report-cost">23 258,25</div>
                                        </div>
                                        <div className="table-row">
                                            <div className="table-col report-user">pos_ticket</div>
                                            <div className="table-col report-date">1 сен 2019</div>
                                            <div className="table-col report-time">18:00</div>
                                            <div className="table-col report-number">434657</div>
                                            <div className="table-col report-method">Наличные</div>
                                            <div className="table-col report-event">Король лев 2D</div>
                                            <div className="table-col report-type">Кино</div>
                                            <div className="table-col report-price">8,25</div>
                                            <div className="table-col report-count">437</div>
                                            <div className="table-col report-cost">23 258,25</div>
                                        </div>
                                        <div className="table-row">
                                            <div className="table-col report-user">tickeы_456t</div>
                                            <div className="table-col report-date">28 авг 2019</div>
                                            <div className="table-col report-time">18:00</div>
                                            <div className="table-col report-number">436547</div>
                                            <div className="table-col report-method">Банковская карта</div>
                                            <div className="table-col report-event">ФК “Батє” - ФК “Сараево”</div>
                                            <div className="table-col report-type">Спорт</div>
                                            <div className="table-col report-price">2,00</div>
                                            <div className="table-col report-count">437</div>
                                            <div className="table-col report-cost">23 258,25</div>
                                        </div>
                                        <div className="table-row">
                                            <div className="table-col report-user">events_for_your_family</div>
                                            <div className="table-col report-date">20 авг 2019</div>
                                            <div className="table-col report-time">18:00</div>
                                            <div className="table-col report-number">432347</div>
                                            <div className="table-col report-method">Банковская карта</div>
                                            <div className="table-col report-event">ФК “Батє” - ФК “Сараево”</div>
                                            <div className="table-col report-type">Спорт</div>
                                            <div className="table-col report-price">2,00</div>
                                            <div className="table-col report-count">437</div>
                                            <div className="table-col report-cost">23 258,25</div>
                                        </div>
                                        <div className="table-row">
                                            <div className="table-col report-user">pos_ticket</div>
                                            <div className="table-col report-date">18 авг 2019</div>
                                            <div className="table-col report-time">16:00</div>
                                            <div className="table-col report-number">656354</div>
                                            <div className="table-col report-method">Банковская карта</div>
                                            <div className="table-col report-event">ФК “Батє” - ФК “Сараево”</div>
                                            <div className="table-col report-type">Спорт</div>
                                            <div className="table-col report-price">2,00</div>
                                            <div className="table-col report-count">437</div>
                                            <div className="table-col report-cost">23 258,25</div>
                                        </div>
                                    </div>
                                    <div className="table-foot">
                                        <div className="table-row">
                                            <div className="table-col">Итого по операциям в валюте BYN</div>
                                            <div className="table-col report-total">2 321 458,25</div>
                                        </div>
                                    </div>
                                </div>
                                : <Spinner />
                            }
                        </div>

                        {!isSpinner &&
                            <div className="pagination">
                                <div className="pagination__actions">
                                    <button type="button" className="btn btn-orange" onClick={() => {printJS(urlReportsPdf)}}>
                                        <span className="icon icon-printdoc"></span>Печатать
                                    </button>
                                    {urlReportsXml &&
                                        <a href={urlReportsXml} type="button" className="btn btn-orange" download>
                                            <span className="icon icon-excel"></span>Скачать Excel
                                        </a>
                                    }
                                    {urlReportsPdf &&
                                        <a href={urlReportsPdf} type="button" className="btn btn-orange" download>
                                            <span className="icon icon-pdf"></span>Скачать PDF
                                        </a>
                                    }
                                </div>
                                < div className="pagination__links">
                                    <a href="#" className="pagination__item active">1</a>
                                    <a href="#" className="pagination__item">2</a>
                                    <a href="#" className="pagination__item">3</a>
                                    <a href="#" className="pagination__item">
                                    <span className="svg-icon icon-right"></span>
                                    </a>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </>
        )
    }
}

const MapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(MapStateToProps)(Reports)
