import React, { Component } from "react";
import {
  getBasket,
  deleteTicket,
  orderCreate,
  orederPay,
  deletedOrder,
  payTwoSum,
  setOrderAnalog,
  orderDelete
} from "../../api/basket";
import {
  totalPlanList,
  eventsList,
  eventsCitiesList,
  eventsPlacesList,
  categoriesEvent
} from "../../api/event";
import { getPricesList } from "../../api/fastbuy";
import { svgTotalEvent } from "../../api/event";
import { ticketByPrice, getAccessTicketsTemplate, getPdfTickets} from "../../api/ticket";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { setMax } from "../../store/actions/event";
import Score from "../Basket/score";
import EventType from "../EventType/eventType";
import Category from "../QuickSale/category";
import Header from "../SwitchingHeader/index";
import { getCancelablePromise } from "../../helpers/index";
import Sale from "../Basket/Sale";
import Ticket from "../CheckoutBasket/Ticket";
import { parseNameAttribute } from "../../helpers/index";
import { error } from "../../containers/const";
import PerfectScrollbar from "react-perfect-scrollbar";
import DimmerLoader from "../DimmerLoader/dimmerLoader";
import Modal from "../Modal";
import CashRegister from "../../containers/CashRegister";
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import printJS from "print-js";

class FastBuy extends Component {
  refCash = React.createRef();
  refCart = React.createRef();

  state = {
    tickets: [],
    selectedTickets: [],
    priceTicked: 0,
    payOrder: 0,
    cardMoney: 0,
    cashMoney: 0,
    quantity: 0,
    planList: false,
    ident_type_id: true,
    cityCurrent: false,
    eventCurrent: false,
    placedCurrent: false,
    categoriesEvents: false,
    time: false,
    pricesList: [],
    preloader: true,
    event: [],
    pricesListZones: [],
    ticketsOrder: {},
    errorModal: false,
    paramsPay: false,
    errorCashRegister: false,
    order_id: false,
    typesTickets: [],
    choiceTypeTickets: false,

    promocodes: {
      analog_code: '',
      amount: '',
      loaderPromocode: false,
      valueCoastPromo: false,
      errorPromo: false,
    },
    setTimeoutPromo: false,
    deals: {
      coast_cods: 0,
      coast_sertificate: 0,
    }
  };

  componentDidMount = async () => {
    const { auth, match } = this.props;

    const plan = getCancelablePromise(totalPlanList());
    const totEvent = getCancelablePromise(svgTotalEvent(match.params.eventID));
    const pricesListZones = getCancelablePromise(
      getPricesList(match.params.eventID)
    );

    Promise.all([totEvent.promise, plan.promise, pricesListZones.promise]).then(
      data => {
        if (data[0] !== error.notFound) {
          data[2].forEach(element => {
            element.price.map(item => {
              item.countTicket = 0;
            });
          });
          this.setState({
            pricesListZones: data[2],
            event: data[0].event,
            eventCurrent: data[0].event.descriptions[0].name,
            placedCurrent: data[0].venue.descriptions[0].name,
            cityCurrent: data[0].city.descriptions[0].name,
            categoriesEvents: data[0].event_categories[0].descriptions[0].name,
            time: data[0].event.date_time_from,
            pricesList: data[0].prices
          });
        } else if (data[0] === error.notFound) {
          this.props.history.push("/");
        }
        this.setState({
          planList: data[1],
          preloader: false
        });
      }
    );
    this.updateTickets();
  };

  goBackMap = () => {
    this.props.history.push(`/event/${this.props.match.params.eventID}/`);
  };

  orderPay = async () => {
    const { auth } = this.props;
    const {
      ident_type_id,
      payOrder,
      priceTicked,
      tickets,
      paramsPay
    } = this.state;
    if (priceTicked === payOrder && ident_type_id && tickets.length) {
      this.setState({ preloader: true });

      let terminal = await CashRegister.check(
        this.state.tickets,
        this.state.listEvents,
        this.state.priceTicked
      );
      var is_cash_active = false;
      var check_no = false;
      if (terminal) {
        is_cash_active = true;
        check_no = terminal;
      }

      if(terminal === undefined && !this.state.order_id) {
        this.setState({ errorModal: true, errorCashRegister: true });
        return;
      }

      // let order_id = getCancelablePromise(orderCreate());
      // Promise.all([order_id.promise])
        // .then(async orderList => {
              payTwoSum(
                this.state.order_id,
                paramsPay,
                is_cash_active,
                check_no
              ).then(data => {
                this.setState({
                  tickets: [],
                  listEvents: [],
                  order_id: false,
                })
                getPdfTickets(
                  data.id,
                  this.state.choiceTypeTickets
                )
                .then(async (res) => {
                  await printJS(res?.path);
                  this.setState({
                    tickets: [],
                    priceTicked: 0,
                    preloader: false,
                    listEvents: [],
                    order_id: false
                  });
                }).catch(er => {
                  this.setState({
                    tickets: [],
                    priceTicked: 0,
                    preloader: false,
                    listEvents: []
                  });
                  ToastsStore.error(er.response?.data?.text || er.response?.data?.error)
                })
                this.updateTickets();
                this.clearCountTickets();
                ToastsStore.success('Заказ успешно подвержен')
              })
              .catch(er => {
                // console.log(er)
                this.setState({
                  preloader: false,
                });
                ToastsStore.error(er.response?.data?.text || er.response?.data?.error)
              })
              .finally(() => {
                this.setState({ preloader: false });
              })
            // var order = getCancelablePromise(orederPay(orderList[0].order_id, ident_type_id));
            // Promise.all([order.promise])
            //   .then(data => {
            //     if (data[0]) {
            //       this.setState({
            //         tickets: [],
            //         priceTicked: 0,
            //         quantityTicket: 0,
            //         preloader: false
            //       });
            //     } else {
            //       console.error("error");
            //     }
            //   })
            //   .catch(err => {
            //     console.error("error");
            //     this.setState({ preloader: false });
            //   });
        // })
        // .catch(err => {
        //   console.error("error");
        //   this.orderPay();
        //   this.setState({ preloader: false });
        // });
    } else if (!tickets.length) {
      ToastsStore.error('Не выбранны билеты');
    } else ToastsStore.error('Недостаточная сумма');
  };

  paymentOrder = e => {
    let pey;
    const { priceTicked } = this.state;
    var params;

    if (+this.refCash.current.value > 0) {
      const f = x =>
        x.toString().includes(".")
          ? x
              .toString()
              .split(".")
              .pop().length
          : 0;
      if (f(this.refCash.current.value) > 2) {
      }
      if (this.refCash.current.value.includes(".")) {
        const f = x =>
          x.toString().includes(".")
            ? x
                .toString()
                .split(".")
                .pop().length
            : 0;
        if (f(this.refCash.current.value) >= 2) {
          let a = parseFloat(this.refCash.current.value - 0).toFixed(2);
          this.refCash.current.value = a;
        }
      }
    }

    if (+this.refCart.current.value > 0) {
      if (this.refCart.current.value.includes(".")) {
        const f = x =>
          ~(x + "").indexOf(".") ? (x + "").split(".")[1].length : 0;
        if (f(this.refCart.current.value) >= 2) {
          let a = parseFloat(this.refCart.current.value - 0).toFixed(2);
          this.refCart.current.value = a;
        }
      }
    }

    if (
      +this.refCash.current.value > priceTicked &&
      +this.refCart.current.value === 0
    ) {
      this.refCash.current.value = priceTicked;
    }
    if (
      +this.refCart.current.value > priceTicked &&
      +this.refCash.current.value === 0
    ) {
      this.refCart.current.value = priceTicked;
    }
    if (e.target.name === "MS") {
      if (
        +this.refCart.current.value >
        priceTicked - this.refCash.current.value
      ) {
        this.refCart.current.value = parseFloat(
          priceTicked - +this.refCash.current.value
        ).toFixed(2);
      }
    }
    if (e.target.name === "CASH") {
      if (
        +this.refCash.current.value >
        priceTicked - this.refCart.current.value
      ) {
        this.refCash.current.value = parseFloat(
          priceTicked - +this.refCart.current.value
        ).toFixed(2);
      }
    }
    if (+this.refCash.current.value > 0 && +this.refCart.current.value === 0) {
      pey = "CASH";
      params = {
        ident_type_1: "CASH",
        pay_sum_1: this.refCash.current.value
      };
    } else if (
      +this.refCart.current.value > 0 &&
      +this.refCash.current.value > 0
    ) {
      params = {
        ident_type_1: "MS",
        pay_sum_1: this.refCart.current.value,
        ident_type_2: "CASH",
        pay_sum_2: this.refCash.current.value
      };
      pey = "MS";
    } else if (+this.refCart.current.value > 0) {
      pey = "MS";
      params = {
        ident_type_1: "CASH",
        pay_sum_1: this.refCart.current.value
      };
    } else {
      params = false;
    }

    this.setState({
      payOrder: +this.refCash.current.value + +this.refCart.current.value,
      ident_type_id: pey,
      paramsPay: params
    });
  };

  setTypeDiscount = async (id, itemId, discount, type) => {
    const newTickets = [];
    this.state.pricesListZones.map(element => {
      if (_.filter(element.price, { id: itemId }) && element.node_id === id) {
        let ticketId = _.filter(element.price, { id: itemId })[0];
        if(!ticketId.hasOwnProperty('count')) {
          ticketId.count = 0;
        }
        ticketId[type] = discount;

        if(discount === 'all') {
          ticketId[type] = null;
        }
        }
      newTickets.push(element);
    });
    this.setState({ pricesListZones: newTickets });
  };

  buyTicket = async (id, itemId, count = 1) => {
    const newTickets = [];
    this.state.pricesListZones.map(element => {
      if (_.filter(element.price, { id: itemId }) && element.node_id === id) {
        let ticketId = _.filter(element.price, { id: itemId })[0];
        if (count === 1) {
          ticketId.countTicket = +ticketId.countTicket + +count;
        } else {
          ticketId.countTicket = +count;
        }
      }
      newTickets.push(element);
    });
    console.log('newTickets');
    console.log(newTickets)
    this.setState({ pricesListZones: newTickets });
  };

  deleteIdZones = (id, itemId) => {
    const ara = [];
    this.state.pricesListZones.map(element => {
      if (_.filter(element.price, { id: itemId }) && element.node_id === id) {
        let ticketId = _.filter(element.price, { id: itemId })[0];
        if (ticketId.countTicket > 0) {
          ticketId.countTicket = +ticketId.countTicket - 1;
        }
      }
      ara.push(element);
    });

    this.setState({ pricesListZones: ara });
  };

  clearCountTickets = () => {
    const a = [];
    this.state.pricesListZones.map(element => {
      if (element.price.length) {
        element.price.forEach(item => {
          item.countTicket = 0;
        });
        a.push(element);
      }
    });
    this.setState({ pricesListZones: a });
  };

  handleCloseModal = () => this.setState({ errorModal: false });

  buyAllTickets = () => {
    this.setState({ preloader: true });
    var countAlltickets = 0;
    var countBuytickets = 0;

    this.state.pricesListZones.forEach(element => {
      if (element.price.length) {
        element.price.forEach(item => {
          if (item.countTicket > 0) {
            if (item.countTicket > 0) {
              countAlltickets = ++countAlltickets;
            }
            const ticket = getCancelablePromise(
              ticketByPrice(item.id, item.countTicket, item.discount_id, item?.promo || null)
            );            
            Promise.allSettled([ticket.promise])
              .then(res => {
                countBuytickets = ++countBuytickets;
                if (_.some(res, { status: 'rejected' })) {
                  // this.setState({ errorModal: true });
                  // res[0] !== error.notFound && this.updateTicket(res[0]);
                  res.map(item => {
                    ToastsStore.error(item?.reason?.response?.data?.text || item?.reason?.response?.data?.error)
                  })
                }
                if (countBuytickets === countAlltickets) {
                  this.updateTickets();
                  this.clearCountTickets();
                  this.setState({ preloader: false });
                }
              })
              .catch(err => {
                this.setState({ preloader: false });
              });
          }
        });
      }
    });
    if (countAlltickets === 0) {
      this.setState({ preloader: false });
    }
  };

  removeTicketFromBasket = async id => {
    const { auth } = this.props;

    const ticketOrder = getCancelablePromise(deleteTicket(id));

    Promise.all([ticketOrder.promise]).then(data => {
      data[0] !== error.notFound && this.updateTicket(data[0]);
    });
  };

  updateTickets = async () => {
    const { auth } = this.props;
    this.setState({ tickets: await getBasket() }, async function() {
      let coastTickets = 0;
      this.state.tickets.map(item => {
        return (coastTickets =
          +coastTickets.toFixed(2) + +item.price.toFixed(2));
      });
      this.setState({ priceTicked: coastTickets });
    });
  };

  updateTicket = data => {
    this.setState({ tickets: data });
    let coastTickets = 0;
    data.map(
      item => (coastTickets = +coastTickets.toFixed(2) + +item.price.toFixed(2))
    );
    this.setState({ priceTicked: coastTickets });
  };

  checkedTicket = id => {
    if (this.state.tickets.length) {
      if (!this.state.event.season_events.length) {
        return _.filter(this.state.tickets, { price_id: id }).length;
      } else {
        return _.unionBy(
          _.filter(this.state.tickets, "parent_seat_id"),
          "parent_seat_id"
        ).length;
      }
    }
    return 0;
  };
  deleteOrder = () => {
    const { tickets } = this.state;
    tickets.length !== 0 && this.setState({ preloader: true });
    tickets.length !== 0 &&
      _.filter(tickets, function(item) {
        return item.ticket_type_id < 5;
      })
        .concat(
          _.intersectionBy(
            _.filter(tickets, { ticket_type_id: 5 }),
            "parent_seat_id"
          )
        )
        .forEach(item => {
          const deleteOrder = getCancelablePromise(deletedOrder(item.id));
          Promise.all([deleteOrder.promise]).then(data => {
            if (data[0] !== error.notFound) {
              // data[0].length === 0 &&
                this.setState({
                  preloader: false,
                  tickets: [],
                  listEvents: [],
                  priceTicked: 0,
                  quantityTicket: 0
                });
            }
          });
        });
  };

  createOrder = () => {
    //
    this.setState({ preloader: true });

    orderCreate()
    .then(res => {
      console.log(res);
      this.setState({
        order_id: res.order_id
      })
      getAccessTicketsTemplate(this.state.order_id)
      .then(res => {
        this.setState({
          typesTickets: res,
          choiceTypeTickets: res.length && res[0].id
        })
      })
      .finally(() => {
        this.setState({ preloader: false });
      })

    })
    .catch(er => {
      this.setState({ preloader: false });
      console.log(er);
      ToastsStore.error(er.response?.data?.text || er.response?.data?.error)
    })
  }

  _orderDelete = () => {
    if(this.state.order_id) {
      this.setState({ preloader: true });
      orderDelete(this.state.order_id)
      .then(res => {
        this.updateTickets();
        this.clearCountTickets();
        this.setState({
          order_id: false,
        })
      })
      .catch(er => {
        console.log(er);
        ToastsStore.error(er.response?.data?.text || er.response?.data?.error)
      })
      .finally(() => {
        this.setState({ preloader: false });
      })
    }
  }

  onChangePromocodes = (type, value) => {
    this.setState({
      promocodes: {
        ...this.state.promocodes,
        [type]: value,
        loaderPromocode: false,
        valueCoastPromo: false,
        errorPromo: false,
      }
    })
  }

  setOrderDiscount = (analog_code, amount) => {
    console.log('start')
    if(this.state.order_id) {
    this.setState({
      promocodes: {
        ...this.state.promocodes,
        loaderPromocode: true,
        valueCoastPromo: false,
      }
    })
    try {
    clearTimeout(this.state.setTimeoutPromo)
    if (analog_code && amount) {
      this.setState({
        promocodes: {
          ...this.state.promocodes,
          errorPromo: false,
        },
        setTimeoutPromo: setTimeout(() => {
          setOrderAnalog({
            order_id: this.state.order_id,
            analog_code,
            amount
          })
            .then(({data}) => {
              this.updateOrder(data)

              this.setState({
                promocodes: {
                  ...this.state.promocodes,
                valueCoastPromo: amount
                }
              })
            })
            .catch(er => {
              this.setState({
                promocodes: {
                  ...this.state.promocodes,
                  errorPromo: er.response.data.text,
                }
              })
            })
        }, 1000)
      })
    } 
  } catch(er) {
    console.error(er)
  }
} else {
  ToastsStore.error('Необходимо создать заказ!')
}
  }

  updateOrder = (data) => {
    try {
        let coast_cods = 0;
        let coast_sertificate = 0;
        data.used_analogs.forEach(item => {
          console.log(item)
          if(item.analog_type_id === 2) {
              coast_cods = Number(coast_cods) + Number(item.used_amount)
          } else {
            coast_sertificate = Number(coast_sertificate) + Number(item.used_amount)
          }
        })
        this.setState({
          priceTicked: +data.sale_amount,
          deals: {
            coast_cods: coast_cods.toFixed(2),
            coast_sertificate: coast_sertificate.toFixed(2)
          },
          promocodes: {
            ...this.state.promocodes,
            analog_code: '',
            amount: '',
          }
        })
      } catch(er) {
        console.log(er)
      }
      }
  changeTypeTemplate = (e) => {
    this.setState({
      choiceTypeTickets: e,
    })
  }

  render() {
    const { preloader, errorModal } = this.state;
    return (
      <>
        <nav className="nav">
          <div onClick={this.props.history.goBack} className="nav-btn">
            <button type="button" className="btn">
              <span className="icon-left svg-icon"></span>
            </button>
            <span>Назад</span>
          </div>
          <Header />
          <div className="nav-link">
            <button className="btn btn-blue" onClick={this.goBackMap}>
              Выбор мест
            </button>
          </div>
        </nav>
        <div className="content-wrap">
          <section className="content">
            <EventType
              time={
                this.state.time &&
                this.state.time.substring(8, 10) +
                  " " +
                  moment(this.state.time)
                    .format("MMMM")
                    .substring(0, 3) +
                  "."
              }
              date={this.state.time && moment(this.state.time).format("H:mm")}
              name={this.state.eventCurrent}
              location={this.state.placedCurrent}
              city={this.state.cityCurrent}
              type={this.state.categoriesEvents}
            />
            <div className="quick-sale">
              <div className="table-wrap">
                <div className="table quick-sale-table">
                  <PerfectScrollbar>
                    {this.state.planList && (
                      <div style={{ maxHeight: "20vh" }}>
                        <Category
                          pricesListZones={this.state.pricesListZones}
                          pricesList={this.state.pricesList}
                          parseNameAttribute={parseNameAttribute}
                          deleteTicket={this.deleteIdZones}
                          checkedTicket={this.checkedTicket}
                          buyTicket={this.buyTicket}
                          planList={this.state.planList}
                          ticketsOrder={this.state.ticketsOrder}
                          setDiscount={this.setTypeDiscount}
                        />
                      </div>
                    )}
                  </PerfectScrollbar>
                </div>
              </div>
              <div className="quick-sale__button">
                <button
                  onClick={this.buyAllTickets}
                  type="button"
                  disabled={!!this.state.order_id}
                  className="btn btn-orange"
                >
                  <span className="icon icon-circle-plus"></span>Добавить
                </button>

                <button
                  onClick={this.createOrder}
                  type="button"
                  className="btn btn-orange"
                  disabled={!!this.state.order_id}
                >
                  <span className="icon icon-circle-plus"></span>Создать заказ
                </button>
              </div>
            </div>
            <Sale
              refCash={this.refCash}
              refCart={this.refCart}
              paymentOrder={this.paymentOrder}
              orderPay={this.orderPay}
              deleteOrder={this._orderDelete}
              changeTypeTemplate={this.changeTypeTemplate}

              typesTickets={this.state.typesTickets}
              choiceTypeTickets={this.state.choiceTypeTickets}
                      // promocodes
              values={this.state.promocodes}
              setFieldValue={this.onChangePromocodes}
              setOrderDiscount={this.setOrderDiscount}

              disabledOrder={this.state.order_id}
            />
          </section>
          <aside className="sidebar sidebar-right">
            <div className="basket">
              <div className="sidebar-head">
                <span className="icon icon-backet"></span>Корзина
              </div>
              <div className="sidebar-body">
                <div className="ticket-list">
                  <PerfectScrollbar>
                    {this.state.tickets.length
                      ? _.filter(this.state.tickets, function(item) {
                          return item.ticket_type_id < 5;
                        })
                          .concat(
                            _.intersectionBy(
                              _.filter(this.state.tickets, {
                                ticket_type_id: 5
                              }),
                              "parent_seat_id"
                            )
                          )
                          .map(item => {
                            return (
                              this.state.planList !== false && (
                                <Ticket
                                  ticketDelete={this.choiceTicket}
                                  price={item.price}
                                  id={item.id}
                                  packet={item.ticket_type_id}
                                  data={parseNameAttribute(
                                    this.state.planList,
                                    item.ticket_name
                                  )}
                                  key={item.id}
                                  onClickHandler={e => {
                                    this.removeTicketFromBasket(item.id);
                                  }}
                                />
                              )
                            );
                          })
                      : null}
                  </PerfectScrollbar>
                </div>
                {this.state.tickets.length ? (
                  <div
                    className="ticket-list__action"
                    name="deleteAll"
                    onClick={this.deleteOrder}
                  >
                    <div className="ticket-list__action-clear">
                      <span className="icon icon-delete"></span>Очистить все
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="payment">
              <div className="sidebar-head">
                <span className="icon icon-cards"></span>Оплата
              </div>
              <div className="sidebar-body">
                <div className="payment-icon">
                  <span className="icon icon-cards"></span>
                </div>
                {/*{console.log((_.filter(this.state.tickets, function(item) { return item.ticket_type_id < 5; })).length + (_.intersectionBy(_.filter(this.state.tickets, { "ticket_type_id": 5 }), 'parent_seat_id')).length)}*/}
                <div className="payment-count">
                  Итого билетов в корзине:{" "}
                  <b>
                    {_.filter(this.state.tickets, function(item) {
                      return item.ticket_type_id < 5;
                    }).length +
                      _.intersectionBy(
                        _.filter(this.state.tickets, { ticket_type_id: 5 }),
                        "parent_seat_id"
                      ).length}{" "}
                    шт
                  </b>
                </div>
                <div className="payment-price">
                  К оплате:{" "}
                  <b>
                    {Number.isInteger(this.state.priceTicked)
                      ? this.state.priceTicked
                      : this.state.priceTicked.toFixed(2)}{" "}
                    BYN
                  </b>
                </div>
                <div className="payment-discount">
                  Скидка: <b>0,00 BYN</b>
                </div>
              </div>
            </div>
            <div className="paid">
              <Score
              deals={this.state.deals}
                cart={
                  this.refCart.current !== null && (
                    <span>
                      {this.refCart.current.value === ""
                        ? 0
                        : this.refCart.current.value}
                    </span>
                  )
                }
                cash={
                  this.refCash.current !== null && (
                    <span>
                      {this.refCash.current.value === ""
                        ? 0
                        : this.refCash.current.value}
                    </span>
                  )
                }
              />
            </div>
          </aside>
          <div className="sidebar-icon">
            <button className="btn">
              <span className="icon icon-backet"></span>
            </button>
          </div>
          {preloader && <DimmerLoader active={preloader} />}
        </div>
        {errorModal && (
          <Modal isClose hideModal={this.handleCloseModal}>
            <div className="modal-text">
              {!this.state.errorCashRegister ? (
                <>Произошла ошибка</>
              ) : (
                <>Произошла ошибка с кассовым апаратом, повторите операцию!</>
              )}
            </div>
          </Modal>
        )}
          <ToastsContainer position={ToastsContainerPosition.TOP_RIGHT} store={ToastsStore} />
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});
const mapDispatchToProps = {
  setMax
};
export default connect(mapStateToProps, mapDispatchToProps)(FastBuy);
