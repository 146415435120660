export const AUTH = {
    LOGIN: 'AUTH_LOGIN',
    GET_CLIENT: 'GET_CLIENT',
    LOGOUT: 'LOGOUT',
    LOADING: 'LOADING',
};

export const EVENT = {
    ADD_EVENTS: 'ADD_EVENTS',
    SET_SEARCH_INPUT: 'SET_SEARCH_INPUT',
    DEFAULT_SEARCH: 'DEFAULT_SEARCH',
    SET_EVENTS: 'SET_EVENTS',
    SET_VENUES: 'SET_VENUES',
    SET_CATEGORIES: 'SET_CATEGORIES',
    SET_SELECT_TO: 'SET_SELECT_TO',
    SET_SELECT_FROM: 'SET_SELECT_FROM',
    SET_CITIES: 'SET_CITIES',
    SET_MIN: 'SET_MIN',
    SET_MAX: 'SET_MAX',
    CURRENT_PAGE: 'CURRENT_PAGE',
    BUTTONS: 'BUTTONS',
    PRELOADER: 'PRELOADER',
    ERROR: 'ERROR',
    SET_CURRENT_DAY: 'SET_CURRENT_DAY',
};
