import store from '../store/store'
import Exit from '../containers/exit'
import { error } from '../containers/const'
import serviceApi from './serviceApi';

export const categoriesEvent = () =>
  serviceApi(`/ticket-wc-api/v1/get-event-categories-list/`, {
    min: 1,
    max: 500
  })
    .then(function (response) {
      return response.data
    }).catch((err) => {

      return error.notFound
    }
    )

export const eventsPlacesList = () =>
  serviceApi(`/ticket-wc-api/v1/get-places-list/`, {
    min: 1,
    max: 500
  })
    .then(function (response) {
      return response.data
    }).catch((err) => {
      return error.notFound
    }
    )

export const eventsCitiesList = () =>
  serviceApi(`/ticket-wc-api/v1/get-cities-list/`, {
    min: 1,
    max: 500
  })
    .then(function (response) {
      return response.data
    }).catch((err) => {
      return error.notFound
    }
    )

export const eventsList = () =>
  serviceApi(`/ticket-wc-api/v1/get-events-list/`, {
    name_filter: store.getState().event.searchParameters.name_filter,
    promoter_id: store.getState().event.searchParameters.promoter_id,
    event_type_id: store.getState().event.searchParameters.event_type_id,
    events: store.getState().event.searchParameters.events,
    venues: store.getState().event.searchParameters.venues.length ? store.getState().event.searchParameters.venues.join() : null,
    event_categories: store.getState().event.searchParameters.event_categories.length ? store.getState().event.searchParameters.event_categories.join() : null,
    select_to: store.getState().event.searchParameters.select_to,
    select_from: store.getState().event.searchParameters.select_from,
    cities: store.getState().event.searchParameters.cities.length ? store.getState().event.searchParameters.cities.join() : null,
    min: store.getState().event.searchParameters.min,
    max: store.getState().event.searchParameters.max,
  })
    .then(function (response) {
      return response.data
    }).catch((err) => {
      throw err;
    }
    )

export const svgForEvent = (event_id) =>
  serviceApi(`/ticket-wc-api/v1/get-seller-svg/`, {
    event_id: event_id,
  }).then((res) => {
    return res.data.path
  })

export const svgTotalEvent = (event_id) => {
  let path = `/ticket-wc-api/v1/get-seller-svg-full/`
  return serviceApi(path, {
    event_id: event_id
  })
    .then((res) => {
      return res.data;
    })
    .catch((er) => {
      return error.notFound
    }
    )
}

export const totalPlanList = () => {
  let path = `/ticket-wc-api/v1/get-plan-obj-types-list/`
  return serviceApi(path, {
    min: 1,
    max: 500
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return error.notFound
    }
    )
}
export const getSchemeForSeats = () => {
  let path = `/ticket-wc-api/v1/get-plan-obj-types-list/`
  return serviceApi(path)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error

    }
    )
}
export const addTicket = (ticket_id, price, quan) => {
  let path = `/ticket-wc-api/v1/ticket-reserve/`;
  return serviceApi(path, {
    count: quan,
    ticket_id: ticket_id,
    price: price,
  })
    .then((res) => {
      return res.data;
    }).catch((error) => {
      return error

    }
    )
}
export const removeTicket = (basket_id) => {
  let path = `/ticket-wc-api/v1/ticket-delete/`
  return serviceApi(path, { basket_id: basket_id })
    .then((res) => {
      return res.data;
    }).catch((error) => {
      return error

    }
    )
}
export const getBasket = () => {
  let path = `/ticket-wc-api/v1/get-basket/`
  return serviceApi(path)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error
    }
    )
}
