import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export default class Item extends Component {
    state = {
        hover: false
    }

    toggleHover = () => {
        this.setState({ hover: !this.state.hover })
    }

    render() {
        const { id, event, placed, city, time, date, eventType } = this.props;
        return (
            <div key={id}>
                <Link key={id} onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover} className="table-row" to={`/event/${id}/`}>
                    <span className="table-col event-date">{date}</span>
                    <span className="table-col event-time">{time}</span>
                    <span className="table-col event-name">{event}</span>
                    <span className="table-col event-location">{placed}</span>
                    <span className="table-col event-city">{city}</span>
                    <span className="table-col event-type">{eventType}
                        <span className="icon-print svg-icon"></span> {this.state.hover && <span className="hover-btn">
                            <span className="icon-print svg-icon"></span>
                            Выбрать билет
                                </span>}
                    </span>
                </Link>
            </div>
        )
    }
}

Item.propTypes = {
    id: PropTypes.number.isRequired,
    event: PropTypes.string.isRequired,
    placed: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    eventType: PropTypes.string.isRequired,
}