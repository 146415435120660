import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
            <footer className="footer">
                <div className="footer__content">
                    <a href="#" className="reference">Политика конфиденциальности</a>
                    <div className="footer__links">
                        <a href="#" className="reference">Помощь | Служба поддержки</a>
                    </div>
                </div>
            </footer>
        )
    }
}
