import store from '../store/store';
import instance from './index';
import Exit from '../containers/exit';

export const serviceApi = async (method, parameters) => {
    let params = null;
    if (method !== '/ticket-wc-api/v1/get-client-info/') {
        params = Object.assign({}, { client_id: store.getState().auth.client.client_id }, parameters);
    }
    let data = instance.get(`${method}${localStorage.getItem('ssoclid')}`, { params })
    data.catch(er => {
        er.response.data && er.response.data.error === 801 && Exit.exit();
    })
    return await data;
}

export default serviceApi;
