import { Dimmer, Loader } from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';

export default function DimmerLoader (props) {
  return <Dimmer active={props.active} inverted><div className="spinner">
                <span className="spinner-icon">
                    <span></span>
                </span>
            </div>
        </Dimmer>;
}

DimmerLoader.propTypes = {
  active: PropTypes.bool,
};
