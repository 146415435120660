import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { svgForEvent, svgTotalEvent, getSchemeForSeats, getBasket, addTicket, removeTicket } from '../../api/event';
import { getCancelablePromise, parseNameAttribute, parseNameAttributeFull, getWidthAndHeightOfSvg } from '../../helpers';
import axios from 'axios';
import SVG from '../../components/Svg/SVG'
import Ticket from '../../components/CheckoutBasket/Ticket';
import Payment from '../../components/CheckoutBasket/Payment';
import Legend from '../../components/CheckoutBasket/Legend';
import TicketDetails from '../../components/CheckoutBasket/TicketDetails';
import TicketGroupPopUp from '../../components/CheckoutBasket/TicketGroupPopUp';
import Header from '../../components/SwitchingHeader/index'
import PerfectScrollbar from "react-perfect-scrollbar";
import Spinner from "../../components/Spinner";
import _ from 'lodash';

class Checkout extends Component {
  constructor(props) {
    super(props);
    // Redirect to "/" if client is not authorized
    if (!props.client.client_id) {
      props.history.push('/');
    }
    // Fallback currency is 'BYN'
    this.state = {
      svgPath: null,
      svg: null,
      svgIsNew: false,
      prices: [],
      legendToggled: false,
      groupElements: null,
      hoveredSeat: null,
      selectedSeats: {},
      seatScheme: null,
      event: null,
      venue: null,
      city: null,
      category: null,
      date: null,
      time: null,
      currency: 'BYN',
      groupTicketPopup: false,
      groupTicketQuantity: 1,
      groupTicketData: null,
      groupTicketPrice: 0,
      isSpinner: false,

    };
    // Initial render of AutoSizer requires explicitly stated width and height to get initial fitToViewer() to work properly
    // Default sizes is completely arbitrary and will be assigned upon ComponentDidMount
    this.__svgContainer = React.createRef();
    this.__svgContainerWidth = 500;
    this.__svgContainerHeight = 500;
    this.__svgContainerSizesIsSet = false;
    this.__svgHeight = 500;
    this.__svgWidth = 500;
    // -----------------------------------------------------------------------------------------------------------
    this.__svgTransformationMatrix = null;
    this.Viewer = null;
    this.axiosCancelToken = axios.CancelToken;
    this.svgRequest = null;
    // Array of promise cancelation methods to be called while unmounting
    this.__asyncCancelationMethods = {
      svgReload: null,
      addTicket: null,
      removeTicket: null,
      mount: []
    };
    this.toggleLegend = this.toggleLegend.bind(this);
    this.navigateToNextPage = this.navigateToNextPage.bind(this);
  }

  toggleLegend = (e) => {
    this.setState({
      legendToggled: !this.state.legendToggled
    });
  }

  resetGroupTicketPopUp = () => {
    this.setState({
      groupTicketPopup: false,
      groupTicketQuantity: 1,
      groupTicketData: null,
      groupTicketPrice: 0
    });
  }

  getNumberOfTickets = () => {
    return Object.keys(this.state.selectedSeats).length;
  }
  getTotalPaymentAmount = () => {
    let price = 0;
    Object.keys(this.state.selectedSeats).forEach((seatID) => {
      price += this.state.selectedSeats[seatID].price;
    });
    return price;
  }

  getNewSVG = () => {
    // Cancel previos svg download
    if (this.svgRequest) {
      this.svgRequest.cancel('Request is canceled.');
    }
    this.svgRequest = this.axiosCancelToken.source();
    axios.get(this.state.svgPath, {
      cancelToken: this.svgRequest.token
    })
      .then((svg) => {
        // let sizes = getWidthAndHeightOfSvg(svg.data);
        // this.svgRequest = null;
        // this.__svgWidth = parseInt(sizes.width);
        // this.__svgHeight = parseInt(sizes.height);
        this.setState({
          svg: svg.data,
          svgIsNew: true
        });
      })
      .catch((err) => {
        console.error('Request error', err);
        this.svgRequest = null;
      })
  }
  reloadSVG = () => {
    let { auth, client, match } = this.props;
    if (typeof this.__asyncCancelationMethods.svgReload === 'function') {
      this.__asyncCancelationMethods.svgReload();
    }
    const svgForEventPromise = getCancelablePromise(svgForEvent(match.params.eventID));
    this.__asyncCancelationMethods.svgReload = svgForEventPromise.cancel;
    svgForEventPromise.promise
      .then((path) => {
        let transformationMatrix = document.querySelector('g[transform]');
        if (transformationMatrix) {
          transformationMatrix = transformationMatrix.getAttribute('transform');
        } else if (this.__svgTransformationMatrix) {
          transformationMatrix = this.__svgTransformationMatrix;
        } else {
          transformationMatrix = null;
        }
        this.__asyncCancelationMethods.svgReload = null;
        this.__svgTransformationMatrix = transformationMatrix;
        this.setState({
          svgPath: path
        });
      })
      .catch((err) => {
        this.__asyncCancelationMethods.svgReload = null;
      });
  }
  moveSeatOnSVG = (elem, remove, groupSeatsQuantity = 0) => {
    if (!elem) {
      return false;
    }
    let elemSelector = elem.outerHTML.match(/^<(circle|rect)/)[1];
    if (remove) {
      if (groupSeatsQuantity > 0) {
        return true;
      }
    } else if (elemSelector === 'rect' && elem.parentNode.getAttribute('fill') === '#000000') {
      return true;
    }

    let priceID = elem.parentNode.getAttribute('price_id');
    let selector = `g[fill="#000000"][price_id="${priceID}"]`;
    // For removing find target seat and get it's parent
    if (remove) {
      selector = `g:not([fill="#000000"])[price_id="${priceID}"] circle`;
    }
    let targetNode = document.querySelector(selector);
    if (remove && targetNode) {
      if (!targetNode.parentNode) {
        return false;
      }
      targetNode = targetNode.parentNode;
    }
    // -------------------------------------------------
    if (targetNode) {
      targetNode.appendChild(elem);
      return true;
    }
    return false;
  }

  addTicketToBasket = (seatToSelect) => {
    this.setState({
      isSpinner: true
    });
    let quantity = this.state.groupTicketQuantity;
    let { seatID, priceID, name, price } = seatToSelect;
    let target = seatToSelect.target;
    const addTicketPromise = getCancelablePromise(addTicket(seatID, priceID, quantity));
    this.__asyncCancelationMethods.addTicket = addTicketPromise.cancel;

    addTicketPromise.promise
      .then((data) => {
        this.__asyncCancelationMethods.addTicket = null;
        let selectedSeats = { ...this.state.selectedSeats };
        // data.basket_entities.forEach((bask_ent) => {
          
          ((_.filter(data.basket_entities, function (item) { return item.ticket_type_id < 5; })))
          .concat((_.intersectionBy(_.filter(data.basket_entities, { "ticket_type_id": 5 }), 'parent_seat_id'))).forEach(bask_ent => {

          if (bask_ent.ticket_id === seatID
            || bask_ent.group_ticket_id === seatID
            || bask_ent.parent_seat_id === seatID
            || bask_ent.parent_group_seat_id === seatID) {
            selectedSeats[bask_ent.ticket_id] = {
              name,
              price,
              currency: bask_ent.currency,
              basketID: bask_ent.id,
              priceID: bask_ent.price_id,
              groupID: bask_ent.group_ticket_id,
              ticket_type_id: bask_ent.ticket_type_id
            }
          }
        });
        if (!this.state.svg || !this.moveSeatOnSVG(target)) {
          this.reloadSVG();
        }
        this.setState({
          selectedSeats,
          groupTicketPopup: false,
          groupTicketQuantity: 1,
          groupTicketData: null,
          groupTicketPrice: 0,
          isSpinner: false
        });
      })
      .catch((err) => {
        this.__asyncCancelationMethods.addTicket = null;
        this.setState({
          isSpinner: false
        });
        console.error('Adding ticket has failed: ', err);
      });
  }

  removeTicketFromBasket = (basketID) => {
    this.setState({
      isSpinner: true
    });
    const removeTicketPromise = getCancelablePromise(removeTicket(basketID));

    removeTicketPromise.promise
      .then((data) => {
        this.__asyncCancelationMethods.removeTicket = null;
        let selectedSeats = { ...this.state.selectedSeats };
        let targetName = null, groupLength = 0;
        Object.keys(selectedSeats).forEach((ticketID) => {
          if (selectedSeats[ticketID].basketID === basketID) {
            targetName = selectedSeats[ticketID].name;
            delete selectedSeats[ticketID];
          } else if (selectedSeats[ticketID].groupID) {
            groupLength += 1;
          }
        });
        let target = document.querySelector(`circle[name="${targetName}"]`);
        if (!target) {
          target = document.querySelector(`rect[name="${targetName}"]`);
        }
        if (!this.state.svg || !this.moveSeatOnSVG(target, true, groupLength)) {
          this.reloadSVG();
        }
        this.setState({
          selectedSeats
        });
        // if (!groupLength) {
          this.setState({
            isSpinner: false
          });
        // }
      })
      .catch((err) => {
        this.__asyncCancelationMethods.removeTicket = null;
        this.setState({
          isSpinner: false
        });
        console.error('Removing ticket has failed: ', err);
      });
  }
  removeAllTickets = () => {
    // TODO: change sequentual removal to group one when API is ready

    Object.keys(this.state.selectedSeats).map((ticketID) => {
      this.removeTicketFromBasket(this.state.selectedSeats[ticketID].basketID);
    });
  }

  seatOnClickHandler = (e) => {
    let seatToSelect = { ...this.state.hoveredSeat };
    if (e.target.getAttribute('group_seat_count')) {
      this.setState({
        groupTicketPopup: true,
        groupTicketQuantity: 1,
        groupTicketData: seatToSelect,
        groupTicketPrice: this.state.groupTicketQuantity * seatToSelect.price
      });
    } else {
      this.addTicketToBasket(seatToSelect);
    }
  };

  groupMouseOverListener = (e) => {
    let seatID = parseInt(e.target.getAttribute('id'));
    let priceID = parseInt(e.target.parentNode.getAttribute('price_id'));
    let name = e.target.getAttribute('name');
    let price, color, discounts = [];
    if (priceID) {
      e.target.addEventListener('click', this.seatOnClickHandler);
    }
    this.state.prices.forEach((elem) => {
      if (elem.id === priceID) {
        price = elem.price;
        color = elem.color;
        elem.discounts.forEach((discount) => {
          // Set discount names from first(ru) discount description
          // TODO: lang change to proper name if lang parsing is required
          discounts.push(discount.descriptions[0].name);
        });
      }
    });
    if (price) {
      this.setState({
        hoveredSeat: {
          color,
          price,
          name,
          discounts,
          seatID,
          priceID,
          target: e.target
        }
      });
    }
  }
  groupMouseOutListener = (e) => {
    e.target.removeEventListener('click', this.seatOnClickHandler);
    this.setState({
      hoveredSeat: null
    });
  }

  removeListenersFromGoups = (groupsElems) => {
    groupsElems.forEach((elem) => {
      elem.removeEventListener('mouseover', this.groupMouseOverListener);
      elem.removeEventListener('mouseout', this.groupMouseOutListener);
    });
  }

  navigateToNextPage = () => {
    // Check if there is dicounts
    /* let discounts = false;
    Object.keys(this.state.selectedSeats).forEach((seatID) => {
      this.state.prices.forEach((priceObj) => {
        if (priceObj.id === this.state.selectedSeats[seatID].priceID) {
          discounts = true;
        }
      });
    }); */
    // Forbid redirecting from the page if there is any active ticket manipulations
    if (this.__asyncCancelationMethods.addTicket || this.__asyncCancelationMethods.removeTicket) {
      return false;
    }
    // Navigate to order page
    this.props.history.push('/discount');
  }

  backPage = () => {
    this.props.history.push('/');
  }
  fastBuy = () => {
    this.props.history.push(`/fastbuy/${this.props.match.params.eventID}`);
  }
  handleClickOutsideGroupTicket = (e) => {
    const { groupTicketPopup } = this.state;
    const emojiBlock = document.getElementsByClassName('scheme-ticket')[0];
    if (
      !e.path.includes(emojiBlock) &&
      groupTicketPopup === true &&
      !e.target.getAttribute('group_seat_count')
    ) {
      this.setState({ groupTicketPopup: false });
    }
  }

  componentDidMount() {
    let { auth, client, match } = this.props;
    if (!this.__svgContainerSizesIsSet && this.__svgContainer) {
      let boundRect = this.__svgContainer.current.getBoundingClientRect();
      this.__svgContainerWidth = Math.round(boundRect.width);
      this.__svgContainerHeight = Math.round(boundRect.height);
      this.__svgContainerSizesIsSet = true;
    }
    const totEvent = getCancelablePromise(svgTotalEvent(match.params.eventID));
    this.__asyncCancelationMethods.mount.push(totEvent.cancel);
    const seatScheme = getCancelablePromise(getSchemeForSeats());
    this.__asyncCancelationMethods.mount.push(seatScheme.cancel);
    const basket = getCancelablePromise(getBasket());
    this.__asyncCancelationMethods.mount.push(basket.cancel);

    Promise.all([totEvent.promise, seatScheme.promise, basket.promise])
      .then((data) => {
        if (data[0] && data[0].error) {
          throw new Error(data[0].error);
        }
        if (data[1] && data[1].error) {
          throw new Error(data[1].error);
        }
        if (data[2] && data[2].error) {
          throw new Error(data[1].error);
        }
        // TODO: Do something if data for some reason wasn't retrieved and there is no error message
        if (!data[0] || !data[1] || !data[2]) {
          throw new Error('Something went wrong while loading.');
        }
        this.__asyncCancelationMethods.mount = [];
        const selectedSeats = {};
        let currency = null;
        // Initialising seat data from get-basket response
        ((_.filter(data[2].basket_entities, function (item) { return item.ticket_type_id < 5; })))
        .concat((_.intersectionBy(_.filter(data[2].basket_entities, { "ticket_type_id": 5 }), 'parent_seat_id'))).forEach(bask_ent => {
        // data[2].basket_entities.forEach((bask_ent) => {
          // console.log(data)
          // console.log(bask_ent)
          // console.log('---------')
          if (data[0].event.id === bask_ent.event_id || data[0].event.id === bask_ent.parent_event_id) {
            // Since there is no currency data for the event
            //  use currency of the first ticket as currency for the page
            if (!currency) {
              currency = bask_ent.currency;
            }
            selectedSeats[bask_ent.ticket_id] = {
              name: bask_ent.ticket_name,
              price: bask_ent.price,
              basketID: bask_ent.id,
              currency: bask_ent.currency,
              priceID: bask_ent.price_id,
              groupID: bask_ent.group_ticket_id,
              ticket_type_id: bask_ent.ticket_type_id
            }
          }
        });
        // Use default currency if no tickets where selected
        if (!currency) {
          currency = this.state.currency;
        }
        let months = [
          'янв.',
          'фев.',
          'мар.',
          'апр.',
          'мая',
          'июн.',
          'июл.',
          'авг.',
          'сен.',
          'окт.',
          'ноя.',
          'дек.'
        ];
        // Generate event date values for the header
        let parsedDate = new Date(data[0].event.date_time_from);
        let time = parsedDate.getHours() + ':';
        let minutes = parsedDate.getMinutes().toString();
        if (minutes.length < 2) {
          minutes = '0' + minutes;
        }
        time += minutes;
        let date = parsedDate.getDay() + ' ';
        date += months[parsedDate.getMonth()];
        // -----------------------------------------
        this.setState({
          svgPath: data[0].schema.path,
          event: data[0].event.descriptions[0].name,
          venue: data[0].venue.descriptions[0].name,
          city: data[0].city.descriptions[0].name,
          category: data[0].event_categories[0].descriptions[0].name,
          date,
          time,
          prices: data[0].prices,
          seatScheme: data[1],
          selectedSeats,
          currency
        });
      })
      .catch((err) => {
        this.__asyncCancelationMethods.mount = [];
        console.error('Initial mounting error: ', err);
        // TODO: Add proper init error handling
        // Currently it redirects to the event list page
        // this.props.history.push('/');
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.svgIsNew) {
      if (prevState.groupElements) {
        this.removeListenersFromGoups(prevState.groupElements);
      }
      let groups = document.querySelectorAll('svg g[fill]');
      groups.forEach((elem, index) => {
        elem.addEventListener('mouseover', this.groupMouseOverListener);
        elem.addEventListener('mouseout', this.groupMouseOutListener);
      });
      // Center svg in the container for every new svg if there is no previos transformation data
      if (this.__svgTransformationMatrix) {
        document.querySelector('g[transform]').setAttribute('transform', this.__svgTransformationMatrix);
      } else {
        this.Viewer.fitToViewer('center', 'center');
      }
      this.setState({
        groupElements: groups,
        svgIsNew: false
      });
    }
    if (prevState.svgPath !== this.state.svgPath) {
      this.getNewSVG();
    }
  }

  componentWillUnmount() {
    if (this.state.groupElements) {
      this.removeListenersFromGoups(this.state.groupElements);
    }
    if (this.svgRequest) {
      this.svgRequest.cancel('Request is canceled from unmount.');
    }
    Object.keys(this.__asyncCancelationMethods).forEach((name) => {
      if (name === 'mount') {
        this.__asyncCancelationMethods.mount.forEach((method) => {
          if (typeof method === 'function') {
            method();
          }
        });
      } else if (typeof this.__asyncCancelationMethods[name] === 'function') {
        this.__asyncCancelationMethods[name]();
      }
    });
    document.removeEventListener('click', this.handleClickOutsideGroupTicket, false);
  }

  componentWillMount() {
    document.addEventListener('click', this.handleClickOutsideGroupTicket, false);
  }

  render() {
    const { isSpinner } = this.state;
    let svg = null;
    if (this.state.svg) {
      svg = (
        <SVG
          contWidth={this.__svgContainerWidth}
          contHeight={this.__svgContainerHeight}
          svgWidth={this.__svgWidth}
          svgHeight={this.__svgHeight}
          svg={this.state.svg}
          setViewer={(Viewer) => {
            this.Viewer = Viewer;
          }} />
      )
    }
    let ticketDetails = null;
    if (this.state.hoveredSeat) {
      ticketDetails = (
        <TicketDetails
          price={this.state.hoveredSeat.price}
          color={this.state.hoveredSeat.color}
          discounts={this.state.hoveredSeat.discounts}
          data={parseNameAttributeFull(this.state.seatScheme, this.state.hoveredSeat.name)} />
      );
    }

    let { event, venue, city, selectedSeats, category, date, time } = this.state;
    let tickets = Object.keys(selectedSeats).map((ticketID) => {
      return {
        name: selectedSeats[ticketID].name,
        price: selectedSeats[ticketID].price,
        id: selectedSeats[ticketID].basketID,
        currency: selectedSeats[ticketID].currency,
        ticket_type_id: selectedSeats[ticketID].ticket_type_id
      };
    });
    let ticketPopUp = null;
    if (this.state.groupTicketPopup) {
      ticketPopUp = (
        <TicketGroupPopUp
          name={parseNameAttributeFull(this.state.seatScheme, this.state.groupTicketData.name)}
          quantity={this.state.groupTicketQuantity}
          cost={this.state.groupTicketPrice}
          price={this.state.groupTicketData.price}
          color={this.state.groupTicketData.color}
          decreaseQuantityHandler={(e) => {
            let quan = this.state.groupTicketQuantity;
            if (quan > 0) {
              quan -= 1;
            }
            this.setState({
              groupTicketQuantity: quan,
              groupTicketPrice: quan * this.state.groupTicketData.price
            });
          }}
          increaseQuantityHandler={(e) => {
            this.setState({
              groupTicketQuantity: parseInt(this.state.groupTicketQuantity + 1),
              groupTicketPrice: parseInt(this.state.groupTicketQuantity + 1) * this.state.groupTicketData.price
            });
          }}
          onChangeHandler={(e) => {
            let value = parseInt(e.target.value);
            if (value) {
              this.setState({
                groupTicketQuantity: value,
                groupTicketPrice: value * this.state.groupTicketData.price
              });
              return true;
            } else if (e.target.value === '') {
              this.setState({
                groupTicketQuantity: '',
                groupTicketPrice: 0
              });
              return true;
            }
            return false;
          }}
          onSubmitHandler={(e) => {
            if (this.state.groupTicketQuantity) {
              this.addTicketToBasket(this.state.groupTicketData);
            }
          }}
          onCloseHandler={(e) => {
            this.resetGroupTicketPopUp();
          }} />
      );
    }

    return (
      <>
        <nav className="nav">
          <div className="nav-btn" onClick={this.backPage}>
            <button type="button" className="btn">
              <span className="icon-left svg-icon"></span>
            </button>
            <span>Назад</span>
          </div>
          <Header />
          <div className="nav-link">
            <a onClick={this.fastBuy} className="btn btn-blue">Быстрая продажа</a>
          </div>
        </nav>
        <div className="content-wrap align-items-stretch">
          <section className="content">
            <div className="scheme">
              <div className="scheme__header">
                <a href="#" className="table-row">
                  <span className="table-col event-date">{date}</span>
                  <span className="table-col event-time">{time}</span>
                  <span className="table-col event-name">{event}</span>
                  <span className="table-col event-location">{venue}</span>
                  <span className="table-col event-city">{city}</span>
                  <span className="table-col event-type">{category}</span>
                </a>
              </div>
              <div
                className="scheme-svg"
                ref={this.__svgContainer} >
                {svg ? svg :
                  <Spinner />
                }
              </div>
              <Legend
                toggled={this.state.legendToggled}
                prices={this.state.prices}
                toggleHandler={this.toggleLegend} >
                {ticketDetails}
              </Legend>
            </div>
          </section>
          <aside className="sidebar sidebar-right">
            <div className="basket">
              <div className="sidebar-head">
                <span className="icon icon-backet"></span>
                Корзина
            </div>
              <div className="sidebar-body">
                {!isSpinner ?
                  <div className="ticket-list">
                    <PerfectScrollbar>
                      {tickets.length ?
                        tickets.map((ticket) => {
                          return (
                            <Ticket key={ticket.id}
                              packet={ticket.ticket_type_id}
                              price={ticket.price}
                              data={parseNameAttribute(this.state.seatScheme, ticket.name)}
                              onClickHandler={(e) => {
                                this.removeTicketFromBasket(ticket.id);
                              }} >
                            </Ticket>
                          );
                        })
                        : "Ничего не выбрано"
                      }
                    </PerfectScrollbar>
                  </div>
                  : <Spinner />
                }
                <div className="ticket-list__action">
                  <div
                    className="ticket-list__action-clear"
                    onClick={(e) => {
                      this.removeAllTickets();
                    }} >
                    <span className="icon icon-delete"></span>
                    Очистить все
                </div>
                </div>
              </div>
              <Payment
                ticketQuantity={this.getNumberOfTickets()}
                paymentAmount={this.getTotalPaymentAmount()}
                currency={this.state.currency}
                onClickHandler={this.navigateToNextPage} />
            </div>
          </aside>
          {ticketPopUp}
        </div>
      </>
    );
  };
}

Checkout.propTypes = {
  auth: PropTypes.string.isRequired,
  client: PropTypes.shape({
    client_id: PropTypes.number.isRequired,
    login: PropTypes.string,
    fio: PropTypes.string,
    is_it_promoter: PropTypes.bool,
    is_it_seller: PropTypes.bool
  }).isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth.ssoclid,
  client: state.auth.client
})
export default connect(mapStateToProps)(Checkout);
