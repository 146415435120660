import { EVENT } from '../const'

const initialState = {
    searchParameters: {
        name_filter: '',
        promoter_id: null,
        event_type_id: null,
        events: null,
        venues: [],
        event_categories: [],
        select_to: null,
        select_from: null,
        cities: [],
        min: 1,
        max: 15,
    },
    currentPage: 1,
    count: 0,
    buttonEvents: 0,
    searchInput: null,
    events: [],
    preloader: true,
    current_day: false,
};

export default function event(state = initialState, action) {
    switch (action.type) {
        case EVENT.PRELOADER:
            return Object.assign({}, state, { preloader: action.preloader, current_day: state.current_day });
        case EVENT.SET_CURRENT_DAY:
            return Object.assign({}, state, { current_day: action.value });
        case EVENT.BUTTONS:
            return Object.assign({}, state, { buttonEvents: action.value, current_day: state.current_day });
        case EVENT.CURRENT_PAGE:
            return Object.assign({}, state, { currentPage: action.page, current_day: state.current_day });
        case EVENT.ADD_EVENTS:
            console.log(action.events.events)
            return Object.assign({}, state, { events: action.events.events, count: action.events.all_count, current_day: state.current_day });
        case EVENT.DEFAULT_SEARCH:
            return Object.assign({}, state, {
                currentPage: 1,
                searchParameters: {
                    name_filter: '',
                    promoter_id: null,
                    event_type_id: null,
                    events: null,
                    venues: [],
                    event_categories: [],
                    select_to: null,
                    select_from: null,
                    cities: [],
                    min: 1,
                    max: 15,
                }, current_day: false
            });
        case EVENT.SET_SEARCH_INPUT:
            return Object.assign({}, state, {
                currentPage: 1,
                searchParameters: {
                    name_filter: action.searchInput,
                    promoter_id: null,
                    event_type_id: null,
                    events: state.searchParameters.events,
                    venues: state.searchParameters.venues,
                    event_categories: state.searchParameters.event_categories,
                    select_to: state.searchParameters.select_to,
                    select_from: state.searchParameters.select_from,
                    cities: state.searchParameters.cities,
                    min: 1,
                    max: 15,
                }, current_day: state.current_day
            });
        case EVENT.SET_EVENTS:
            return Object.assign({}, state, {
                currentPage: 1,
                searchParameters: {
                    name_filter: state.searchParameters.name_filter,
                    promoter_id: null,
                    event_type_id: null,
                    events: action.value,
                    venues: state.searchParameters.venues,
                    event_categories: state.searchParameters.event_categories,
                    select_to: state.searchParameters.select_to,
                    select_from: state.searchParameters.select_from,
                    cities: state.searchParameters.cities,
                    min: 1,
                    max: 15,
                }, current_day: state.current_day
            });
        case EVENT.SET_VENUES:
            return Object.assign({}, state, {
                currentPage: 1,
                searchParameters: {
                    name_filter: state.searchParameters.name_filter,
                    promoter_id: null,
                    event_type_id: null,
                    events: state.searchParameters.events,
                    venues: action.value,
                    event_categories: state.searchParameters.event_categories,
                    select_to: state.searchParameters.select_to,
                    select_from: state.searchParameters.select_from,
                    cities: state.searchParameters.cities,
                    min: 1,
                    max: 15,
                }, current_day: state.current_day
            });
        case EVENT.SET_CATEGORIES:
            return Object.assign({}, state, {
                currentPage: 1,
                searchParameters: {
                    name_filter: state.searchParameters.name_filter,
                    promoter_id: null,
                    event_type_id: null,
                    events: state.searchParameters.events,
                    venues: state.searchParameters.venues,
                    event_categories: action.value,
                    select_to: state.searchParameters.select_to,
                    select_from: state.searchParameters.select_from,
                    cities: state.searchParameters.cities,
                    min: 1,
                    max: 15,
                }, current_day: state.current_day
            });
        case EVENT.SET_SELECT_TO:
            return Object.assign({}, state, {
                currentPage: 1,
                searchParameters: {
                    name_filter: state.searchParameters.name_filter,
                    promoter_id: null,
                    event_type_id: null,
                    events: state.searchParameters.events,
                    venues: state.searchParameters.venues,
                    event_categories: state.searchParameters.event_categories,
                    select_to: action.value,
                    select_from: state.searchParameters.select_from,
                    cities: state.searchParameters.cities,
                    min: state.searchParameters.min,
                    max: state.searchParameters.max,
                }, current_day: state.current_day
            });
        case EVENT.SET_SELECT_FROM:
            return Object.assign({}, state, {
                currentPage: 1,
                searchParameters: {
                    name_filter: state.searchParameters.name_filter,
                    promoter_id: null,
                    event_type_id: null,
                    events: state.searchParameters.events,
                    venues: state.searchParameters.venues,
                    event_categories: state.searchParameters.event_categories,
                    select_to: state.searchParameters.select_to,
                    select_from: action.value,
                    cities: state.searchParameters.cities,
                    min: state.searchParameters.min,
                    max: state.searchParameters.max,
                }, current_day: state.current_day
            });
        case EVENT.SET_CITIES:
            return Object.assign({}, state, {
                currentPage: 1,
                searchParameters: {
                    name_filter: state.searchParameters.name_filter,
                    promoter_id: null,
                    event_type_id: null,
                    events: state.searchParameters.events,
                    venues: state.searchParameters.venues,
                    event_categories: state.searchParameters.event_categories,
                    select_to: state.searchParameters.select_to,
                    select_from: state.searchParameters.select_from,
                    cities: action.value,
                    min: state.searchParameters.min,
                    max: state.searchParameters.max,
                }, current_day: state.current_day
            });
        case EVENT.SET_MIN:
            return Object.assign({}, state, {
                currentPage: state.currentPage,
                searchParameters: {
                    name_filter: state.searchParameters.name_filter,
                    promoter_id: null,
                    event_type_id: null,
                    events: state.searchParameters.events,
                    venues: state.searchParameters.venues,
                    event_categories: state.searchParameters.event_categories,
                    select_to: state.searchParameters.select_to,
                    select_from: state.searchParameters.select_from,
                    cities: state.searchParameters.cities,
                    min: action.value,
                    max: state.searchParameters.max,
                }, current_day: state.current_day
            });
        case EVENT.SET_MAX:
            return Object.assign({}, state, {
                currentPage: state.currentPage,
                searchParameters: {
                    name_filter: state.searchParameters.name_filter,
                    promoter_id: null,
                    event_type_id: null,
                    events: state.searchParameters.events,
                    venues: state.searchParameters.venues,
                    event_categories: state.searchParameters.event_categories,
                    select_to: state.searchParameters.select_to,
                    select_from: state.searchParameters.select_from,
                    cities: state.searchParameters.cities,
                    min: state.searchParameters.min,
                    max: action.value,
                }, current_day: state.current_day
            });
        default:
            return state;
    }
};
