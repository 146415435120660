import React, { Component } from "react";
import { connect } from "react-redux";
import {
  categoriesEvent,
  eventsList,
  eventsCitiesList,
  eventsPlacesList
} from "../../api/event";
import {
  setPreloader,
  setSelectTo,
  setSelectFrom,
  setDefaultSearch,
  setVenues,
  setCurrentPage,
  setCurrentButtons,
  setMin,
  setMax,
  setEvents,
  setCategories,
  setCities,
  setSearch,
  setCurrentDay,
  setAsyncEvent
} from "../../store/actions/event";
import _ from "lodash";
import DatePicker, { registerLocale } from "react-datepicker";
import ListItem from "../ListItem/listItem";
import Item from "../EventItem";
import "react-datepicker/dist/react-datepicker.css";
import Header from "../SwitchingHeader/index";
import moment from "moment";
import "moment/locale/ru";
import { getCancelablePromise } from "../../helpers/index";
import ru from "date-fns/locale/ru";
import { Pagination } from "semantic-ui-react";
import Spinner from "../Spinner";
import PropTypes from "prop-types";
import { error } from "../../containers/const";
import PerfectScrollbar from "react-perfect-scrollbar";
import Modal from "../Modal";

registerLocale("ru", ru);

class Event extends Component {
  state = {
    currentPage: 1,
    categoriesEventList: [],
    sortedPlaces: [],
    sortedCities: [],
    eventsCitiesList: [],
    eventsPlacesList: [],
    buttonEvents: 0,
    countEvents: 15,
    startDate: new Date(),
    endDate: new Date(),
    quantityPlacesList: 3,
    quantityCitiesList: 3,
    quantityCitiesName: `Показать все`,
    quantityPlacesName: `Показать все`,
    openBar: "",
    searchTimeout: false,
    errorEvents: false
  };

  componentDidMount = async () => {
    const { auth } = this.props;
    this.props.setMax(15);
    this.props.setPreloader(true);
    this.props.setDefaultSearch();
    if (auth.client.client_id !== false) {
      const categories = getCancelablePromise(categoriesEvent());
      const events = getCancelablePromise(eventsList());
      const cities = getCancelablePromise(eventsCitiesList());
      const places = getCancelablePromise(eventsPlacesList());

      Promise.all([
        cities.promise,
        places.promise,
        events.promise,
        categories.promise
      ])
        .then(data => {
          if (data[2] !== error.notFound) {
            this.props.setEvents(data[2]);
            this.props.setPreloader(false);
            this.props.setCurrentButtons(
              Math.ceil(data[2].all_count / this.state.countEvents)
            );
            if (
              data[0] !== error.notFound &&
              data[1] !== error.notFound &&
              data[3] !== error.notFound
            ) {
              this.setState({ categoriesEventList: data[3] });
              this.setState({
                eventsCitiesList: data[0],
                sortedCities: data[0]
              });
              this.setState({
                eventsPlacesList: data[1],
                sortedPlaces: data[1]
              });
            }
          }
        })
        .catch(er => {
          console.log(er);
          this.setState({ errorEvents: true });
          // error
        });
    }
  };
  componentWillUnmount = () => {
    this.props.setDefaultSearch();
  };
  totalVenue = id => {
    if (
      this.state.eventsPlacesList.length &&
      Array.isArray(this.state.eventsPlacesList)
    ) {
      return _.find(this.state.eventsPlacesList, { id: id }).descriptions[0]
        .name;
    }
    return "";
  };

  totalEvents = id => {
    if (
      this.state.categoriesEventList.length &&
      Array.isArray(this.state.categoriesEventList)
    ) {
      return _.find(this.state.categoriesEventList, { id: id }).descriptions[0]
        .name;
    }
    return "";
  };

  totalCities = id => {
    if (
      this.state.eventsPlacesList.length &&
      this.state.eventsCitiesList.length &&
      Array.isArray(this.state.eventsPlacesList) &&
      Array.isArray(this.state.sortedPlaces)
    ) {
      let id_places = _.find(this.state.eventsPlacesList, { id: id }).city_id;
      return _.find(this.state.eventsCitiesList, { id: id_places })
        .descriptions[0].name;
    }
    return "";
  };

  categories = e => {
    let option;
    if (e.target.name !== undefined) {
      this.setDefaultSearch();
      option = e.target.name;
      if (
        !this.props.events.searchParameters.event_categories.includes(option)
      ) {
        let places = this.props.events.searchParameters.event_categories;
        places.push(option);
        this.props.setCategories(places);
        this.searchEvents();
      } else {
        let places = this.props.events.searchParameters.event_categories.filter(
          name => name !== option
        );
        this.props.setCategories(places);
        this.searchEvents();
      }
    }
  };

  placesChange = e => {
    let option;
    if (e.target.name !== undefined && e.target.name !== null) {
      option = e.target.name;
      this.setDefaultSearch();
      e.target.check = true;
      if (!this.props.events.searchParameters.venues.includes(option)) {
        let places = this.props.events.searchParameters.venues;
        places.push(option);
        this.props.setVenues(places);
        this.searchEvents();
      } else {
        let places = this.props.events.searchParameters.venues.filter(
          name => name !== option
        );
        this.props.setVenues(places);
        this.searchEvents();
      }
    }
  };

  citiesChange = e => {
    let option;
    if (e.target.name !== undefined) {
      this.setDefaultSearch();
      option = e.target.name;
      if (!this.props.events.searchParameters.cities.includes(option)) {
        let places = this.props.events.searchParameters.cities;
        places.push(option);
        this.props.setCities(places);
        this.searchEvents();
      } else {
        let places = this.props.events.searchParameters.cities.filter(
          name => name !== option
        );
        this.props.setCities(places);
        this.searchEvents();
      }
    }
  };

  searchEvents = async () => {
    let { searchTimeout } = this.state;
    clearTimeout(searchTimeout);
    this.props.setPreloader(true);
    let data;
    this.setState({
      searchTimeout: setTimeout(async () => {
        data = await this.props.setAsyncEvent();
        if (!data.events) {
          this.setState({ errorEvents: true });
          this.props.setDefaultSearch();
          this.searchEvents();
        }
      }, 200)
    });
  };

  setPageNum = (e, { activePage }) => {
    this.props.setCurrentPage(activePage);
    this.props.setMin((activePage - 1) * 15 + 1);
    this.props.setMax(activePage * 15);
    this.searchEvents();
  };

  currentDaySet = type => {
    this.setDefaultSearch();
    const { events } = this.props;
    if (!events.current_day) {
      this.props.setCurrentDay(true);
      this.props.setSelectTo(moment(new Date()).format("YYYY-MM-DD"));
      this.props.setSelectFrom(
        moment(new Date())
          .add(1, "d")
          .format("YYYY-MM-DD")
      );
    } else {
      this.props.setCurrentDay(false);
      this.props.setSelectTo(null);
      this.props.setSelectFrom(null);
    }
    this.searchEvents();
  };

  handleChangeStartDate = date => {
    this.setDefaultSearch();
    this.props.setCurrentDay(false);
    const { events } = this.props;
    if (
      events.searchParameters.select_from === moment(date).format("YYYY-MM-DD")
    ) {
      this.props.setSelectFrom(null);
      this.searchEvents();
    } else {
      this.props.setSelectFrom(moment(date).format("YYYY-MM-DD"));
      this.setState(
        {
          startDate: date
        },
        function () {
          this.searchEvents();
        }
      );
    }
  };

  handleChangeEndDate = date => {
    this.setDefaultSearch();
    this.props.setCurrentDay(false);
    const { events } = this.props;
    if (
      events.searchParameters.select_to === moment(date).format("YYYY-MM-DD")
    ) {
      this.props.setSelectTo(null);
      this.searchEvents();
    } else {
      this.props.setSelectTo(moment(date).format("YYYY-MM-DD"));
      this.setState(
        {
          endDate: date
        },
        function () {
          this.searchEvents();
        }
      );
    }
  };

  quantityCitiesList = () => {
    if (this.state.quantityCitiesList === 3) {
      this.setState({
        quantityCitiesList: this.state.sortedCities.length,
        quantityCitiesName: `Скрыть`
      });
    } else {
      this.setState({
        quantityCitiesList: 3,
        quantityCitiesName: `Показать все`
      });
    }
  };

  changePlaces = e => {
    let search = e.target.value;
    let sorted = this.state.eventsPlacesList.filter(str =>
      str.descriptions[0].name.toLowerCase().includes(search.toLowerCase())
    );
    this.setState({ sortedPlaces: sorted });
  };

  changeCities = e => {
    let search = e.target.value;
    let sorted = this.state.eventsCitiesList.filter(str =>
      str.descriptions[0].name.toLowerCase().includes(search.toLowerCase())
    );
    this.setState({ sortedCities: sorted });
  };

  quantityPlacesList = () => {
    if (this.state.quantityPlacesList === 3) {
      this.setState({
        quantityPlacesList: this.state.sortedPlaces.length,
        quantityPlacesName: `Скрыть`
      });
    } else {
      this.setState({
        quantityPlacesList: 3,
        quantityPlacesName: `Показать все`
      });
    }
  };

  openedFilters = () => {
    if (this.state.openBar === "filter-open") {
      this.setState({ openBar: `` });
    } else {
      this.setState({ openBar: `filter-open` });
    }
  };

  setDefaultSearch = () => {
    this.props.setCurrentPage(1);
    this.props.setMin(1);
    this.props.setMax(15);
  };

  handleCloseModal = () => this.setState({ errorEvents: false });

  render() {
    const { preloader, events } = this.props;
    const { errorEvents } = this.state;
    return (
      <>
        <div className={`table-wrap ${this.state.openBar}`}>
          <div className="filter">
            <div className="filter__header">
              <span
                onClick={this.openedFilters}
                className="filter__close filter-open"
              >
                <span className="svg-icon icon-close"></span>
                Скрыть фильтры
              </span>
            </div>
            <div className="filter__content">
              <div className="filter__section">
                <div className="filter__section-title">Период</div>
                <div className="filter__switch">
                  Сегодня
                  <input
                    type="checkbox"
                    id="checkbox"
                    checked={events.current_day}
                  />
                  <label
                    onClick={this.currentDaySet}
                    htmlFor="checkbox"
                    className="switch"
                  ></label>
                </div>
                <div className="period">
                  <span>c</span>
                  <div className="input">
                    <DatePicker
                      dateFormat="dd MMM"
                      selected={this.state.startDate}
                      onChange={this.handleChangeStartDate}
                      locale="ru"
                    />
                  </div>
                </div>
                <div className="period">
                  <span>по</span>
                  <div className="input">
                    <DatePicker
                      dateFormat="dd MMM"
                      selected={this.state.endDate}
                      locale="ru"
                      onChange={this.handleChangeEndDate}
                    />
                  </div>
                </div>
              </div>

              <div className="filter__section">
                <div className="filter__section-title">Место проведения</div>
                <div className="input filter__search">
                  <input
                    type="text"
                    onChange={this.changePlaces}
                    placeholder="место"
                  />
                  <span className="svg-icon icon-search"></span>
                </div>
                <PerfectScrollbar>
                  <div style={{ maxHeight: "500px" }}>
                    {Array.isArray(this.state.sortedPlaces) &&
                      this.state.sortedPlaces.map(
                        (item, i) =>
                          i < this.state.quantityPlacesList && (
                            <ListItem
                              include={
                                this.props.events.searchParameters.venues
                              }
                              name={item.descriptions[0].name}
                              selectedIt={this.placesChange}
                              id={item.id}
                              key={item.id}
                            />
                          )
                      )}
                  </div>
                </PerfectScrollbar>
                {this.state.sortedPlaces.length > 3 && (
                  <div
                    onClick={this.quantityPlacesList}
                    className="filter__show-all"
                  >
                    {this.state.quantityPlacesName}
                    {this.state.quantityPlacesName === "Показать все" && (
                      <> ({this.state.sortedPlaces.length})</>
                    )}
                  </div>
                )}
              </div>
              <div className="filter__section">
                <div className="filter__section-title">Город</div>
                <div className="input filter__search">
                  <input
                    onChange={this.changeCities}
                    type="text"
                    placeholder="город"
                  />
                  <span className="svg-icon icon-search"></span>
                </div>
                <PerfectScrollbar>
                  <div style={{ maxHeight: "500px" }}>
                    {Array.isArray(this.state.sortedCities) &&
                      this.state.sortedCities.map(
                        (item, i) =>
                          i < this.state.quantityCitiesList && (
                            <ListItem
                              include={
                                this.props.events.searchParameters.cities
                              }
                              name={item.descriptions[0].name}
                              selectedIt={this.citiesChange}
                              id={item.id}
                              key={item.id}
                            />
                          )
                      )}
                  </div>
                </PerfectScrollbar>
                {this.state.sortedCities.length > 3 && (
                  <div
                    onClick={this.quantityCitiesList}
                    className="filter__show-all"
                  >
                    {this.state.quantityCitiesName}
                    {this.state.quantityCitiesName === "Показать все" && (
                      <> ({this.state.sortedCities.length})</>
                    )}
                  </div>
                )}
              </div>

              <div className="filter__section">
                <div className="filter__section-title">Категория</div>
                <PerfectScrollbar>
                  <div style={{ maxHeight: "500px" }}>
                    {Array.isArray(this.state.categoriesEventList) &&
                      this.state.categoriesEventList.map(item => (
                        <ListItem
                          key={item.id}
                          include={
                            this.props.events.searchParameters.event_categories
                          }
                          name={item.descriptions[0].name}
                          selectedIt={this.categories}
                          id={item.id}
                        />
                      ))}
                  </div>
                </PerfectScrollbar>
              </div>
            </div>
          </div>
          <div className="table-content">
            <nav className="nav">
              <div className="nav-btn" onClick={this.openedFilters}>
                <button type="button" className="btn hidden">
                  <span className="icon-filter svg-icon"></span>
                </button>
              </div>
              <Header page="event" />
            </nav>
            {preloader && <Spinner />}
            {this.props.events.events.length ? (
              !preloader && (
                <div className="table" onClick={this.setEvent} multiple={true}>
                  {this.props.events.events.map(item => {
                    return (
                      <Item
                        key={item.id}
                        id={item.id}
                        date={
                          item.date_time_from.substring(8, 10) +
                          " " +
                          moment(item.date_time_from)
                            .format("MMMM")
                            .substring(0, 3) +
                          "."
                        }
                        time={moment(item.date_time_from).format("H:mm")}
                        event={item.descriptions[0].name}
                        placed={this.totalVenue(
                          item.place_id,
                          this.state.eventsPlacesList
                        )}
                        city={this.totalCities(
                          item.place_id,
                          this.state.eventsPlacesList
                        )}
                        eventType={this.totalEvents(item.root_category_id)}
                      />
                    );
                  })}
                </div>
              )
            ) : (
              <div>
                <br />
                Ничего не найдено
              </div>
            )}
            <div className="pagination">
              <div className="pagination__count">
                {this.props.events.count !== 0 &&
                  this.props.events.searchParameters.min && (
                    <span>
                      Отображено:
                      {this.props.events.currentPage !== 1 ? (
                        <span>
                          {" "}
                          {(this.props.events.currentPage - 1) * 15} -
                        </span>
                      ) : null}
                      <>
                        {" "}
                        {this.props.events.searchParameters.min -
                          1 +
                          this.props.events.events.length}
                      </>{" "}
                      мероприятий из {this.props.events.count}
                    </span>
                  )}
              </div>
              <div className="pagination__links">
                {
                  !this.props.preloader && (
                    <Pagination
                      activePage={this.props.events.currentPage}
                      totalPages={this.props.events.buttonEvents}
                      siblingRange={1}
                      onPageChange={this.setPageNum}
                      firstItem={null}
                      lastItem={null}
                      boundaryRange={0}
                      ellipsisItem={null}
                      pageItem={{ className: "pagination__item" }}
                      prevItem={
                        this.props.events.currentPage !== 1 && {
                          className: "pagination__item",
                          content: <span className="svg-icon icon-arr-left"></span>,
                          icon: true
                        }
                      }
                      nextItem={
                        this.props.events.buttonEvents !== 0 &&
                        this.props.events.buttonEvents !==
                        this.props.events.currentPage && {
                          className: "pagination__item",
                          content: <span className="svg-icon icon-right"></span>,
                          icon: true
                        }
                      }
                    />
                  )
                }
              </div>
              <div></div>
            </div>
          </div>
        </div>
        {errorEvents && (
          <Modal isClose hideModal={this.handleCloseModal}>
            <div className="modal-text">Произошла ошибка</div>
          </Modal>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  events: state.event,
  preloader: state.event.preloader
});

const mapDispatchToProps = {
  setDefaultSearch,
  setVenues,
  setCurrentPage,
  setMin,
  setMax,
  setEvents,
  setCities,
  setCategories,
  setCurrentButtons,
  setSelectTo,
  setSelectFrom,
  setPreloader,
  setSearch,
  setCurrentDay,
  setAsyncEvent
};

Event.propTypes = {
  eventsPlacesList: PropTypes.array,
  eventsCitiesList: PropTypes.array,
  sortedCities: PropTypes.array,
  sortedPlaces: PropTypes.array,
  categoriesEventList: PropTypes.array,
  currentPage: PropTypes.number,
  buttonEvents: PropTypes.number,
  countEvents: PropTypes.number
};

export default connect(mapStateToProps, mapDispatchToProps)(Event);
