import React, { Component } from "react";

export default class TicketsOrder extends Component{
    render() {
        const {id, date, time, price, currency, user, method, showOrderTickets, currentOrderId} = this.props;

        return (
            <div className={`table-row ${currentOrderId === id ? 'active': ''}`} id={id} onClick={showOrderTickets} key={id}>
                <div className="table-col order-number" >№{id}</div>
                <div className="table-col order-date">{date}</div>
                <div className="table-col order-time">{time}</div>
                <div className="table-col order-price">{price} {currency}</div>
                <div className="table-col order-user">{user}</div>
                <div className="table-col order-method">{method}</div>
                <div className="table-col order-icon">
                    <span className="icon icon-tickets"></span>
                    <span className="hover-btn">
                        <span className="icon icon-tickets"></span>
                        Смотреть билеты
                    </span>
                </div>
            </div>
        );
    }
}
