import React, { Component } from 'react';
import { ordersList, reservedList, ticketCheckProm } from '../../api/ticket';
import { getBasket, deleteTicket } from '../../api/basket';
import { totalPlanList, eventsList, eventsCitiesList, eventsPlacesList, categoriesEvent, svgTotalEvent } from '../../api/event';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import TicketItem from './ticketItem';
import moment from 'moment';
import 'moment/locale/ru';
import Header from '../SwitchingHeader/index';
import { parseNameAttribute } from '../../helpers/index';
import EventType from '../EventType/eventType';
import { getCancelablePromise } from '../../helpers/index';
import Spinner from "../Spinner";
import DimmerLoader from '../DimmerLoader/dimmerLoader';
import { Dropdown } from "semantic-ui-react";
import { error } from '../../containers/const'
import Modal from "../Modal";
import PerfectScrollbar from "react-perfect-scrollbar";

moment.locale('ru');

const typeDiscount = [
    {
        key: 0,
        value: 0,
        text: 'Выберите скидку',
        promo_needed: 0
    }
];

class ListOrder extends Component {

    state = {
        tickets: [],
        planList: false,
        eventCurrent: false,
        placedCurrent: false,
        cityCurrent: false,
        categoriesEvents: false,
        selectedTickets: [],
        time: false,
        coast: 0,
        events: [],
        listEvents: [],
        preloader: true,
        eventsDiscount: [],
        showModal: false,
        errorMessages: '',
        isAllPromo: false,
        discountAllId: null,
        promoAllValue: '',
        promoAllStatus: null,
        defaultAllSelected: false,
    };

    componentDidMount = async () => {
        const { auth } = this.props;
        this.setState({ tickets: await getBasket() }, async function () {
            if (this.state.tickets !== error.notFound && this.state.tickets.length) {
                let ar = [];
                let total = [];
                ((_.filter(this.state.tickets, function (item) { return item.ticket_type_id < 5; }))).concat((_.intersectionBy(_.filter(this.state.tickets, { "ticket_type_id": 5 }), 'parent_event_id'))).forEach(item => {
                    total.push(item.event_id);
                });
                let arrayUniqBy = []
                await _.uniq(total).map(item => {
                    let a = getCancelablePromise(svgTotalEvent(item));
                    Promise.all([a.promise])
                        .then((data) => {
                            if (data[0] !== error.notFound) {
                                ar.push(data[0]);
                                arrayUniqBy = _.uniqBy(ar);
                            }
                        })
                        .catch(
                            this.setState({ listEvents: [] })
                        )
                        .finally(() => {
                            this.setState({
                                listEvents: arrayUniqBy,
                            }, () => {
                                this.getEventsDiscounts();
                                this.setState({ preloader: false });
                            })
                        })
                });


                let coastTickets = 0;
                this.state.tickets.map(item => (coastTickets = +coastTickets.toFixed(2) + +((item.price)).toFixed(2)));

                const plan = getCancelablePromise(totalPlanList());

                Promise.all([plan.promise])
                    .then((data) => {
                        this.setState({
                            planList: data[0]
                        })
                    });
                this.setState({ coast: coastTickets, })
            } else {
                this.setState({ preloader: false })
            }
        });
    };

    updateTickets = async () => {
        this.setState({ tickets: await getBasket() }, async function () {
            let ar = [];
            this.state.tickets.forEach(ticket => {
                this.state.listEvents.forEach(item => {
                    (_.find(item, { id: ticket.event_id }) && ar.push(item));
                })
            });

            this.setState({ listEvents: _.uniqBy(ar) });
            let coastTickets = 0;
            this.state.tickets.map(item => (coastTickets = +coastTickets.toFixed(2) + +((item.price)).toFixed(2)));
            this.setState({ coast: coastTickets });
        })
    };

    getInfoEvent = () => {

    };

    choiceTicket = (e) => {
        let id = e.target.getAttribute("name");
        if (id !== undefined && id !== null) {
            if (!this.state.selectedTickets.includes(Number(id))) {
                let ar = this.state.selectedTickets;
                ar.push(Number(id));
                this.setState({
                    selectedTickets: ar
                }, function () {
                    this.getEventsDiscounts();
                });
            } else {
                let ar = this.state.selectedTickets.filter(name => name !== Number(id));
                this.setState({
                    selectedTickets: ar
                }, function () {
                    this.getEventsDiscounts();
                });
            }
        }
    };

    deleteSelectedTickets = (key) => {
        const { tickets, selectedTickets } = this.state;



        tickets.forEach(item => {
            if (item.event_id === key) {
                if (selectedTickets.includes(item.id)) {
                    const ticketOrder = getCancelablePromise(deleteTicket(item.id));
                    this.setState(prevState => ({
                        selectedTickets: prevState.selectedTickets.filter(name => name !== Number(item.id))
                    }));
                    Promise.all([ticketOrder.promise])
                        .then((data) => {
                            if (data[0] !== error.notFound) {
                                this.setState({ tickets: data[0], preloader: true, })
                                let ar = [];
                                data[0].forEach(ticket => {
                                    this.state.listEvents.forEach(item => {
                                        (_.find(item, { id: ticket.event_id }) && ar.push(item));
                                    })
                                });
                                this.setState({ listEvents: _.uniqBy(ar) });
                                let coastTickets = 0;
                                data[0].map(item => (coastTickets = +coastTickets.toFixed(2) + +((item.price)).toFixed(2)));

                                this.setState({ coast: coastTickets });
                            }
                        })
                        .finally(() => {
                            this.setState({
                                preloader: false,
                            });
                        })
                }
            }
        })
    }

    choiceAllTickets = (key) => {
        const { tickets } = this.state;
        let arrayChoiceTicket = [];
        _.filter(this.state.tickets, { event_id: key }).forEach(id => {
            arrayChoiceTicket.push(id.id)
        });

        if (!_.intersection(this.state.selectedTickets, arrayChoiceTicket).length) {
            tickets.forEach(item => {
                if (item.event_id === key) {
                    let ar = this.state.selectedTickets;
                    ar.push(item.id);
                    this.setState({ selectedTickets: ar }, function () {
                        this.getEventsDiscounts();
                    });
                }
            });
        } else {
            var places = []
            tickets.forEach(item => {
                if (item.event_id === key) {
                    places.push(item.id)
                }
            });
            this.setState({ selectedTickets: _.pullAll(this.state.selectedTickets, places) }, function () {
                this.getEventsDiscounts();
            })
        }
    };

    setDiscount = async (ticketsDiscount) => {
        this.setState({ preloader: true })
        this.setState({ tickets: await getBasket() }, async function () {
            console.log('------ setDiscount ------')
            // console.log('--|-- ', this.state.tickets)
            let coastTickets = 0;
            this.state.tickets.map(item => (coastTickets = +coastTickets.toFixed(2) + +((item.price)).toFixed(2)));
            this.setState({
                coast: coastTickets,
                preloader: false,
            });
        })
        // Promise.all([ticketsDiscount])
        //     .then((data) => {
        //         console.log('ticketsDiscount', data[0])
        //         if (typeof data[0] === "string") {
        //             this.setState({
        //                 showModal: true,
        //                 errorMessages: data[0]
        //             });
        //         } else if (data[0]) {
        //             let coastTickets = 0;
        //             data[0].map(item => (coastTickets = +coastTickets.toFixed(2) + +((item.price)).toFixed(2)));
        //             this.setState({
        //                 tickets: data[0],
        //                 coast: coastTickets
        //             });
        //         }
        //         this.setState({
        //             preloader: false,
        //             // isModal: false,
        //             // errorMessages: ''
        //         })
        //     })
    };

    setDiscountEvent = (e, value, eventId) => {
        const { selectedTickets, tickets } = this.state;
        this.setState({ preloader: true })
        console.log('------ setDiscountEvent ------')
        let promises = [];

        if (selectedTickets.length) {
            this.setState({
                preloader: true,
                promoAllStatus: null
            });
            const promoNeeded = +e.target.getAttribute('promo_needed');
            if (promoNeeded) {
                this.setState({
                    isAllPromo: true,
                    discountAllId: value,
                    defaultAllSelected: value,
                    preloader: false,
                });
                return false
            } else {
                this.setState({
                    isAllPromo: false,
                    promoAllValue: '',
                    defaultAllSelected: false
                });
                const discount = value ? value : null;
                selectedTickets.map((ticketID) => {
                    if (tickets.filter(ticket => ticket.id === ticketID && ticket.event_id === eventId).length) {
                        promises.push(
                            ticketCheckProm(ticketID, discount)
                        );
                    }
                });
            }
        }
        Promise.allSettled(promises)
            .finally(() => {
                this.setDiscount();
                this.setState({ preloader: false });
            })
    };

    getEventsDiscounts = () => {
        const { listEvents, tickets, selectedTickets } = this.state;

        const selectedPrices = [];
        if (selectedTickets.length) {
            selectedTickets.forEach(ticketSelected => {
                selectedPrices.push({
                    eventId: _.find(tickets, { id: ticketSelected }).event_id,
                    ticketId: ticketSelected,
                    priceId: _.find(tickets, { id: ticketSelected }).price_id
                })
            })
        }

        const arrEventsDiscount = [];
        if (listEvents.length) {
            listEvents.forEach(event => {
                let eventDiscount = [];
                let checkTicketsEvent = [];
                tickets.forEach(ticket => {
                    if (ticket.event_id === event.event.id) {
                        checkTicketsEvent.push(ticket.id);
                        if (selectedTickets.includes(ticket.id)) {
                            let discounts = _.find(event.prices, { id: ticket.price_id }) && _.find(event.prices, { id: ticket.price_id }).discounts;
                            if (discounts) {
                                if (eventDiscount.length) {
                                    let arrTemp = [];
                                    discounts.forEach(discount => {
                                        arrTemp.push({
                                            key: discount.id,
                                            value: discount.id,
                                            text: discount.descriptions[0].name,
                                            promo_needed: discount.promo_needed
                                        })
                                    });
                                    arrTemp.push(...typeDiscount);
                                    eventDiscount = _.intersectionBy(eventDiscount, arrTemp, 'key');
                                    // console.log('compare')
                                } else {
                                    let arrTemp = [];
                                    discounts.forEach(discount => {
                                        arrTemp.push({
                                            key: discount.id,
                                            value: discount.id,
                                            text: discount.descriptions[0].name,
                                            promo_needed: discount.promo_needed
                                        })
                                    });
                                    eventDiscount = [...typeDiscount, ...arrTemp]
                                    // discounts.forEach(discount => {
                                    //     eventDiscount.push({
                                    //         key: discount.id,
                                    //         value: discount.id,
                                    //         text: discount.descriptions[0].name,
                                    //         promo_needed: discount.promo_needed
                                    //     })
                                    // });
                                    // console.log('push')
                                }
                            }
                            // eventDiscount
                            // console.log('--eventDiscount--', eventDiscount)
                        }
                    }
                });
                // console.log('checkTicketsEvent',checkTicketsEvent)
                if (!_.intersection(selectedTickets, checkTicketsEvent).length) {
                    // console.log('empty')
                    eventDiscount = typeDiscount;
                    // tickets.forEach(ticket => {
                    //     if (ticket.event_id === event.event.id){
                    //         let discounts = _.find(event.prices, {id: ticket.price_id}).discounts;
                    //         if (discounts) {
                    //             if (eventDiscount.length) {
                    //                 let arrTemp = [];
                    //                 discounts.forEach(discount => {
                    //                     arrTemp.push({
                    //                         key: discount.id,
                    //                         value: discount.id,
                    //                         text: discount.descriptions[0].name,
                    //                         promo_needed: discount.promo_needed
                    //                     })
                    //                 });
                    //                 eventDiscount = _.intersectionBy(eventDiscount, arrTemp, 'key');
                    //                 // console.log('compare')
                    //             } else {
                    //                 discounts.forEach(discount => {
                    //                     eventDiscount.push({
                    //                         key: discount.id,
                    //                         value: discount.id,
                    //                         text: discount.descriptions[0].name,
                    //                         promo_needed: discount.promo_needed
                    //                     })
                    //                 });
                    //                 // console.log('push')
                    //             }
                    //         }
                    //     }
                    // })
                }
                arrEventsDiscount.push({
                    eventId: event.event.id,
                    discounts: eventDiscount
                });
            })
        }
        this.setState({
            eventsDiscount: arrEventsDiscount
        }, function () {
            // console.log('eventsDiscount', this.state.eventsDiscount)
        });
        // console.log('list')
    };

    checkAllPromo = async () => {
        const { promoAllValue, discountAllId } = this.state;
        if (promoAllValue) {
            const { auth, id } = this.props;
            const discount = discountAllId ? discountAllId : null;
            const ticketsDiscount = await ticketCheckProm(id, discount, promoAllValue);
            if (!ticketsDiscount.error) {
                this.setState({
                    promoAllStatus: true,
                })
            } else {
                this.setState({
                    promoAllStatus: false
                })
            }
        } else {
            this.setState({
                promoAllStatus: null
            })
        }
    };

    handleCloseModal = () => {
        this.setState({
            showModal: false
        });
    };

    render() {
        const { listEvents, planList, tickets,
            preloader, selectedTickets, eventsDiscount, showModal, errorMessages,
            isAllPromo, promoAllValue, promoAllStatus, defaultAllSelected, discountAllId
        } = this.state;

        let promoAllIcon = '';
        if (promoAllStatus !== null) {
            if (promoAllStatus) {
                promoAllIcon = <span className="icon icon-circle-check"></span>;
            } else {
                promoAllIcon = <span className="icon icon-circle-close"></span>;
            }
        }

        return (
            <>
                <nav className="nav">
                    <div onClick={this.props.history.goBack} className="nav-btn">
                        <button type="button" className="btn">
                            <span className="icon-left svg-icon"></span>
                        </button>
                        <span>Назад</span>
                    </div>
                    <Header page="discount" />
                </nav>
                <div className="content-wrap">
                    <div className="content">
                        <PerfectScrollbar>
                            <div className="content-list-order-height">
                                {!preloader && listEvents?.length ?
                                    listEvents?.map(item => {
                                        if (item.event) {
                                            return (
                                                <>
                                                    <EventType
                                                        time={!item.event.season_events.length
                                                            ? item.event.date_time_from.substring(8, 10) + ' ' + moment(item.event.date_time_from)
                                                                .format('MMMM').substring(0, 3) + '.'
                                                            : <div>Пакет</div>}
                                                        date={!item.event.season_events.length ? moment(item.event.date_time_from).format('H:mm') : null}
                                                        name={(item.event.descriptions[0].name)}
                                                        location={item.venue.descriptions[0].name}
                                                        city={(item.city.descriptions[0].name)}
                                                        type={(item.event_categories[0].descriptions[0].name)}
                                                    />
                                                    <div className="table-wrap">
                                                        <div className="table discount-table">
                                                            <div className="table-body">

                                                                {planList ?
                                                                    tickets.map((ticket) => {
                                                                        let discountsList = [];
                                                                        if (_.find(item.prices, { id: ticket.price_id })) {
                                                                            discountsList = _.find(item.prices, { id: ticket.price_id }).discounts;
                                                                        }
                                                                        if (ticket.event_id === item.event.id) {
                                                                            return (
                                                                                <TicketItem
                                                                                    ticket_type_id={ticket.ticket_type_id}
                                                                                    key={ticket.id}
                                                                                    include={selectedTickets}
                                                                                    choiceTicket={this.choiceTicket}
                                                                                    coast={ticket.price}
                                                                                    id={ticket.id}
                                                                                    data={parseNameAttribute(planList, ticket.ticket_name)}
                                                                                    discountsList={discountsList}
                                                                                    discountId={ticket.discount_id}
                                                                                    priceId={ticket.price_id}
                                                                                    pricesList={item.prices}
                                                                                    promoValue={ticket.promo}
                                                                                    setDiscount={this.setDiscount}
                                                                                    selectedTickets={selectedTickets}
                                                                                />
                                                                            )
                                                                        }
                                                                    }) : <Spinner />
                                                                }

                                                            </div>
                                                            <div className="table-foot">
                                                                <div className="table-row">
                                                                    <div className="table-col discount-check">
                                                                        <span onClick={() => this.choiceAllTickets(item.event.id)} className="discount-check__all">Выбрать все</span>
                                                                    </div>
                                                                    <div className="table-col discount-seats">
                                                                        <div className="discount-seats__info">Установить скидку для выбранных</div>
                                                                    </div>
                                                                    <div className="table-col discount-type">
                                                                        <Dropdown
                                                                            placeholder="Выберите скидку"
                                                                            selection
                                                                            upward
                                                                            value={defaultAllSelected ? defaultAllSelected : discountAllId}
                                                                            options={
                                                                                eventsDiscount?.length && _.find(eventsDiscount, { eventId: item.event.id }) &&
                                                                                _.find(eventsDiscount, { eventId: item.event.id }).discounts
                                                                            }
                                                                            onChange={(e, data) => this.setDiscountEvent(e, data.value, item.event.id)}
                                                                        />
                                                                    </div>
                                                                    <div className="table-col discount-promocode">
                                                                        <input
                                                                            type="text"
                                                                            placeholder="Промо-код"
                                                                            disabled={!isAllPromo}
                                                                            value={promoAllValue}
                                                                            onChange={e => this.setState({ promoAllValue: e.target.value })}
                                                                            onBlur={e => { this.checkAllPromo(e.target.value) }}
                                                                        />
                                                                        {promoAllIcon}
                                                                    </div>
                                                                    <div className="table-col discount-price">
                                                                        <span onClick={() => this.deleteSelectedTickets(item.event.id)} className="discount-delete">
                                                                            <span className="icon icon-delete"></span>Удалить
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        } else {
                                            return null
                                        }
                                    }
                                    ) : this.state.tickets?.length ? "В обработке" : "Ничего не найдено"
                                }


                                {preloader && <DimmerLoader active={preloader} />}
                            </div>
                        </PerfectScrollbar>
                    </div>
                    <aside className="sidebar sidebar-right">
                        <div className="payment">
                            <div className="sidebar-head"><span className="icon icon-brief"></span>К оплате</div>
                            <div className="sidebar-body">
                                <div className="payment-icon">
                                    <span className="icon icon-brief"></span>
                                </div>
                                <div className="payment-count">Итого билетов в корзине: <b>{(_.filter(this.state?.tickets, function (item) { return item?.ticket_type_id < 5; })).length + (_.intersectionBy(_.filter(this.state.tickets, { "ticket_type_id": 5 }), 'parent_seat_id')).length} шт</b></div>
                                <div className="payment-price">К оплате: <b>{Number.isInteger(this.state.coast) ? this.state.coast : this.state.coast.toFixed(2)} BYN</b></div>
                                <div className="payment-button">
                                    <Link to={`/basket`}>
                                        <button type="button" className="btn btn-orange">
                                            <span className="icon icon-cards"></span>Оплата
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </aside>
                </div>
                {showModal && (
                    <Modal
                        isClose
                        hideModal={this.handleCloseModal}
                    >
                        <div className="modal-text">{errorMessages}</div>
                    </Modal>
                )}
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, null)(ListOrder)
