import React from 'react';
import PropTypes from 'prop-types';
import PriceBox from './PriceBox';

const Legend = ( props ) => {
  let prices = null;
  let { toggled, toggleHandler } = props;
  if (props.prices) {
    prices = props.prices.map(( priceObj ) => (<PriceBox key={priceObj.id} color={priceObj.color} price={priceObj.price} />));
  }

  prices.unshift((
    (<PriceBox key={0} price={'Не доступное'} color={'#999999'} />)
  ));
  prices.push((
    (<PriceBox key={prices.length} price={'Ваши билеты'} color={'#000000'} />)
  ));

  let contClassName = 'scheme__legend';
  if (toggled) {
    contClassName += ' open';
  }

  return (
    <div className={contClassName}>
      <div className="scheme__legend-header">
        { props.children }
        <span
          className="legend-switch"
          onClick={toggleHandler} >
          Легенда
          <span className="svg-icon icon-top"></span>
        </span>
      </div>
      <div className="scheme__legend-body">
          {prices}
      </div>
    </div>
  );
}

Legend.propTypes = {
  toggled: PropTypes.bool.isRequired,
  prices: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired
  })),
  toggleHandler: PropTypes.func.isRequired
}

export default Legend;
