import React from 'react';
import PropTypes from 'prop-types';

const TicketGroupPopUp = ({ quantity, cost, price, name, color, increaseQuantityHandler, decreaseQuantityHandler, onChangeHandler, onSubmitHandler, onCloseHandler }) => {
  let costString = cost;
  if (cost === parseInt(cost)) {
    costString += ',00';
  }
  return (
    <div className="scheme-ticket">
      <div className="scheme-ticket__left">
        <div className="scheme-ticket__title">
          {
            name.map((data, index) => {
              return (
                <p key={index}>{data.attr}: {data.data}</p>
              )
            })
          }
        </div>
        <div className="scheme-ticket__text">Укажите нужно количество билетов</div>
        <div className="scheme-ticket__count">
          <div className="count">
            <button
              className="count-dec"
              onClick={decreaseQuantityHandler} >
              −
            </button>
            <input
              type="text"
              className="count-field"
              value={quantity}
              onChange={onChangeHandler}
               />
            <button
              className="count-inc"
              onClick={increaseQuantityHandler} >
              +
            </button>
          </div>
        </div>
      </div>
      <div className="scheme-ticket__right">
        <div className="scheme-ticket__table">
          <div className="scheme-ticket__row">
            <div className="scheme-ticket__col">Тариф:</div>
            <div className="scheme-ticket__col">
              <div className="color">
                <span className="color-value" style={{backgroundColor: color}}></span>
                <span>{price}</span>
              </div>
            </div>
          </div>
          <div className="scheme-ticket__row">
            <div className="scheme-ticket__col">Сумма:</div>
            <div className="scheme-ticket__col">{costString}</div>
          </div>
        </div>
        <div className="scheme-ticket__button">
          <button
            className="btn btn-orange"
            onClick={onSubmitHandler} >
            <span className="icon icon-backet"></span>
            В корзину
          </button>
        </div>
      </div>
    </div>
  );
}

TicketGroupPopUp.propTypes = {
  name: PropTypes.arrayOf(
    PropTypes.shape({
      attr: PropTypes.string.isRequired,
      data: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  quantity: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  price: PropTypes.number.isRequired,
  cost: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  increaseQuantityHandler: PropTypes.func.isRequired,
  decreaseQuantityHandler: PropTypes.func.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  onCloseHandler: PropTypes.func.isRequired
}

export default TicketGroupPopUp;
