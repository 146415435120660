import instance from './index';
import serviceApi from './serviceApi';

export const clientInfo = () =>
serviceApi(`/ticket-wc-api/v1/get-client-info/`, )
        .then(function (response) {
            return { error: false, response: response }
        })
        .catch(function (error) {
            return { error: true }
        })