import React, { Component } from "react";

export default class Sale extends Component {
  validateNumber = evt => {
    var e = evt || window.event;
    var key = e.keyCode || e.which;
    evt.target.value = evt.target.value.replace(
      /[-;":'a-zA-Zа-яА-Я\\=`\*++!(),@#$%\^&_№?><]/g,
      ""
    );
    if (evt.target.value.indexOf(".") !== -1) {
      if (
        (!e.shiftKey && !e.altKey && !e.ctrlKey && key >= 48 && key <= 57) ||
        (key >= 96 && key <= 105) ||
        key === 8 ||
        key === 9 ||
        key === 13 ||
        key === 35 ||
        key === 36 ||
        key === 37 ||
        key === 39 ||
        key === 46 ||
        key === 45
      ) {
      } else {
        e.returnValue = false;
        if (e.preventDefault) e.preventDefault();
      }
    } else {
      if (
        (!e.shiftKey && !e.altKey && !e.ctrlKey && key >= 48 && key <= 57) ||
        (key >= 96 && key <= 105) ||
        key === 8 ||
        key === 9 ||
        key === 13 ||
        key === 35 ||
        key === 36 ||
        key === 37 ||
        key === 39 ||
        key === 190 ||
        key === 46 ||
        key === 45
      ) {
      } else {
        e.returnValue = false;
        if (e.preventDefault) e.preventDefault();
      }
    }
  };

  render() {
    const {
      refCash,
      refCart,
      paymentOrder,
      orderPay,
      deleteOrder,

      values,
      setFieldValue,
      setOrderDiscount,
      loaderPromocode
    } = this.props;
    return (
      <div className="sale">
        <div className="sale-heading">
          Выберите способ оплаты и распечатайте заказ
        </div>
        <div className="sale-box">
          <div className="sale-box__row">
            <div className="sale-box__col sale-icon">
              <span className="icon icon-money"></span>
            </div>
            <div className="sale-box__col sale-title">Наличные</div>
            <div className="sale-box__col sale-field">
              <div className="input-currency">
                <input
                  step={0.3}
                  ref={refCash}
                  name="CASH"
                  onChange={e => paymentOrder(e)}
                  onKeyDown={e => this.validateNumber(e)}
                  onKeyUp={e => this.validateNumber(e)}
                  type="text"
                  placeholder={0}
                />
                <span className="currency">BYN</span>
              </div>
            </div>
          </div>
          <div className="sale-box__row">
            <div className="sale-box__col sale-icon">
              <span className="icon icon-bank"></span>
            </div>
            <div className="sale-box__col sale-title">Банковская карта</div>
            <div className="sale-box__col sale-field">
              <div className="input-currency">
                <input
                  ref={refCart}
                  name="MS"
                  onChange={e => paymentOrder(e)}
                  onKeyDown={e => this.validateNumber(e)}
                  onKeyUp={e => this.validateNumber(e)}
                  type="text"
                  placeholder="0"
                />
                <span className="currency">BYN</span>
              </div>
            </div>
          </div>
        </div>
        <div className="sale-box">
          <div className="sale-box__row">
            <div className="sale-box__col sale-icon">
              <span className="icon icon-medal"></span>
            </div>
            <div className="sale-box__col sale-title">Подарочный сетификат</div>
            <div className="sale-box__col sale-field">
              <input

                onChange={(e) => {
                  setOrderDiscount(e.target.value, values.amount)
                  return setFieldValue('analog_code', e.target.value)
                }}
                value={values.analog_code}
                disabled={values.loaderPromocode}
                type="text" placeholder="Номер сертификата" />
            </div>
            <div className="sale-box__col sale-price"></div>
          </div>
          <div className="sale-box__row">
            <div className="sale-box__col sale-icon">
              <span className="icon icon-reward"></span>
            </div>
            <div className="sale-box__col sale-title">Бонусные баллы</div>
            <div className="sale-box__col sale-field">
              <input
                onChange={(e) => {
                  setOrderDiscount(values.analog_code, e.target.value)
                  return setFieldValue('amount', e.target.value)
                }}
                value={values.amount}
                disabled={values.loaderPromocode}
                type="text" placeholder="Номер бонуса" />
              {
                values.valueCoastPromo && (
                  <span className="icon icon-circle-check"></span>
                )}
            </div>
            {
              values?.errorPromo && (
                <div className="sale-box__col sale-error">{values.errorPromo}</div>
              )
            }
            {
              values.valueCoastPromo && (
                <div className="sale-box__col sale-price">{values.valueCoastPromo} BYN</div>
              )
            }
          </div>
        </div>
        {
          this.props.typesTickets.length ? (
            <div className="sale-layout">
            <span className="sale-layout__title">Макет билета</span>
            <select name="" onChange={e => {
              this.props.changeTypeTemplate(e.target.value)
            }}>
              {this.props.typesTickets.map(type => {
                return (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                );
              })}
            </select>
          </div>
          ) : null
        }
        {
          this.props.errorPay && (
            <div className="error-basket">
              {this.props.errorPay}
            </div>
          )
        }
        <div className="sale-button">
          <button 
            disabled={!this.props.disabledOrder}
          onClick={orderPay} type="button" 
          className="btn btn-orange">
            <span className="icon icon-circle-check"></span>Оплатить и
            распечатать
          </button>
          <button
            onClick={deleteOrder}
            name="deleteOrder"
            type="button"
            className="btn btn-cancel"
            disabled={!this.props.disabledOrder}
          >
            <span className="icon icon-delete"></span>Анулировать заказ
          </button>
        </div>
      </div>
    );
  }
}
