import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getBasket,
  orderCreate,
  orederPay,
  deletedOrder,
  payTwoSum,
  getTypesTickets,
  setOrderAnalog,
  orderDelete
} from "../../api/basket";
import Header from "../SwitchingHeader/index";
import {
  eventsList,
  eventsCitiesList,
  eventsPlacesList,
  categoriesEvent,
  svgTotalEvent
} from "../../api/event";
import Score from "./score";
import Payment from "./payment";
import EventType from "../EventType/eventType";
import Sale from "./Sale";
import Spinner from "../Spinner";
import _, { conforms } from "lodash";
import moment from "moment";
import { getCancelablePromise } from "../../helpers/index";
import { error } from "../../containers/const";
import DimmerLoader from "../DimmerLoader/dimmerLoader";
import PropTypes from "prop-types";
import Modal from "../Modal/index";
import { getPdfTickets, getTicketsTemplate, getAccessTicketsTemplate } from "../../api/ticket";
import CashRegister from "../../containers/CashRegister";
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';

import printJS from "print-js";

class Basket extends Component {
  refCash = React.createRef();
  refCart = React.createRef();
  state = {
    tickets: [],
    priceTicked: 0,
    payOrder: 0,
    cardMoney: 0,
    cashMoney: 0,
    quantity: 0,
    promo: 0,
    ident_type_id: true,
    cityCurrent: false,
    eventCurrent: false,
    placedCurrent: false,
    categoriesEvents: false,
    time: false,
    listEvents: [],
    preloader: true,
    errorBasket: false,
    paramsPay: false,
    errorCashRegister: false,
    typesTickets: [],
    choiceTypeTickets: false,
    errorPay: false,
    order_id: false,

    promocodes: {
      analog_code: '',
      amount: '',
      loaderPromocode: false,
      valueCoastPromo: false,
      errorPromo: false,
    },
    setTimeoutPromo: false,
    deals: {
      coast_cods: 0,
      coast_sertificate: 0,
    }
  };


  _orderDelete = () => {
    if (this.state.order_id) {
      this.setState({ preloader: true });
      orderDelete(this.state.order_id)
        .then(res => {
          // this.updateTickets();
          // this.clearCountTickets();
          this.setState({
            tickets: [],
            order_id: false,
          })
        })
        .catch(er => {
          console.log(er);
          ToastsStore.error(er.response?.data?.text || er.response?.data?.error)
        })
        .finally(() => {
          this.setState({ preloader: false });
        })
    }
  }

  _orderCreate = async () => {
    return orderCreate()
      .then(res => {
        console.log(res);
        this.setState({
          order_id: res.order_id
        })
      })
      .catch(er => {
        console.log(er);
        ToastsStore.error(er.response?.data?.text || er.response?.data?.error)
      })
    // .finally(() => {
    //   this.setState({ preloader: false });
    // })
  }

  componentDidMount = async () => {
    let basketOerder = await getBasket();
    if (basketOerder !== error.notFound) {
      this.setState({ tickets: basketOerder, preloader: true, }, async function () {
        if (this.state.tickets.length) {
          await this._orderCreate();

          getAccessTicketsTemplate(this.state.order_id)
          .then(res => {
            this.setState({
              typesTickets: res,
              choiceTypeTickets: res.length && res[0].id
            })
          })

          let ar = [];
          let total = [];
          _.filter(this.state.tickets, function (item) {
            return item.ticket_type_id < 5;
          })
            .concat(
              _.intersectionBy(
                _.filter(this.state.tickets, { ticket_type_id: 5 }),
                "parent_seat_id"
              )
            )
            .forEach(item => {
              total.push(item.event_id);
            });
          _.uniq(total).forEach(item => {
            let svgTotal = getCancelablePromise(svgTotalEvent(item));
            // let typesTicket = getCancelablePromise(getTypesTickets());
            Promise.all([svgTotal.promise])
              .then(data => {
                if (
                  // data[1].status === 200 && 
                  data[0]) {
                  //, typesTickets: data[1].data
                  ar.push(data[0]);
                  this.setState(
                    { listEvents: _.uniqBy(ar) },
                    async function () {
                      this.state.listEvents.length === _.uniq(total).length &&
                        setTimeout(() => {
                          this.setState({ preloader: false });
                        }, 0)
                    }
                  );
                }
              })
              .catch(err => {
                console.log(err);
                this.setState({ errorBasket: true, errorCashRegister: false, preloader: false });
                console.log("error");
              });
          });
        } else {
          this.setState({ preloader: false });
        }

        let price = 0;
        this.state.tickets.map(
          item => (price = +price.toFixed(2) + +item.price.toFixed(2))
        );

        this.setState({ priceTicked: this.state.priceTicked + price });
      });
    } else {
      this.setState({ preloader: false });
    }
  };

  onDeals = e => {
    if (e.event.target.deals.length > 10) {
    }
  };

  orderPay = async () => {
    const {
      ident_type_id,
      payOrder,
      priceTicked,
      tickets,
      paramsPay
    } = this.state;
    console.log(
      priceTicked.toFixed(2) === payOrder.toFixed(2)
    )
    console.log(
      this.state.priceTicked.toFixed(2) === 0
    )
    console.log(
      this.state.priceTicked
    )
    if (!(this.state.priceTicked.toFixed(2) === 0) && (this.state.priceTicked.toFixed(2) !== (+Number(this.refCash.current.value) + +Number(this.refCart.current.value)).toFixed(2))) {
      this.setState({
        errorPay: 'Недостаточная сумма',
      })
    } else {
      this.setState({
        errorPay: false,
      })
    }
    let terminal = await CashRegister.check(
      this.state.tickets,
      this.state.listEvents,
      this.state.priceTicked
    );
    var is_cash_active = false;
    var check_no = false;
    if (terminal) {
      is_cash_active = true;
      check_no = terminal;
    }
    if (terminal === undefined) {
      this.setState({ errorBasket: true, errorCashRegister: true });
      return;
    }

    if ((+this.state.priceTicked.toFixed(2) === 0) || priceTicked.toFixed(2) === payOrder.toFixed(2)  && ident_type_id && tickets.length) {
      this.setState({ preloader: true });

      // let order_id = getCancelablePromise(orderCreate());
      // Promise.all([order_id.promise])
      // .then(async orderList => {
      // if (orderList[0] && orderList[0].error) {
      // } else {
      // Cookies.set('order_id', orderList[0].order_id);

      // var order = getCancelablePromise(
      payTwoSum(
        this.state.order_id,
        paramsPay || {
          ident_type_1: "CASH",
          pay_sum_1: 0,
        },
        is_cash_active,
        check_no
      )
        .then(data => {
          ToastsStore.success('Заказ успешно подвержен')
          this.setState({
            order_id: false,
          })
          getPdfTickets(
            data.id,
            this.state.choiceTypeTickets
          )
          .then(async (res) => {
            await printJS(res?.path);
            this.setState({
              tickets: [],
              priceTicked: 0,
              preloader: false,
              listEvents: []
            });
          }).catch(er => {
            this.setState({
              tickets: [],
              priceTicked: 0,
              preloader: false,
              listEvents: []
            });
            ToastsStore.error(er.response?.data?.text || er.response?.data?.error)
          })
        })
        .catch(er => {
          this.setState({
            preloader: false,
          });
          ToastsStore.error(er.response?.data?.text || er.response?.data?.error)
        });
      // }
      // })
      // .catch(err => {
      //   console.log("error");

      //   // this.orderPay();
      //   this.setState({ preloader: false, errorBasket: true });
      // });
    } else if (!tickets.length) {
      ToastsStore.error('Не выбранны билеты');
    } else ToastsStore.error('Недостаточная сумма');
  };

  paymentOrder = e => {
    let pey;
    const { priceTicked } = this.state;
    var params;

    if (+this.refCash.current.value > 0) {
      const f = x =>
        x.toString().includes(".")
          ? x
            .toString()
            .split(".")
            .pop().length
          : 0;
      if (f(this.refCash.current.value) > 2) {
      }
      if (this.refCash.current.value.includes(".")) {
        const f = x =>
          x.toString().includes(".")
            ? x
              .toString()
              .split(".")
              .pop().length
            : 0;
        if (f(this.refCash.current.value) >= 2) {
          let a = parseFloat(this.refCash.current.value - 0).toFixed(2);
          this.refCash.current.value = a;
        }
      }
    }

    if (+this.refCart.current.value > 0) {
      if (this.refCart.current.value.includes(".")) {
        const f = x =>
          ~(x + "").indexOf(".") ? (x + "").split(".")[1].length : 0;
        if (f(this.refCart.current.value) >= 2) {
          let a = parseFloat(this.refCart.current.value - 0).toFixed(2);
          this.refCart.current.value = a;
        }
      }
    }

    if (
      +this.refCash.current.value >= priceTicked &&
      +this.refCart.current.value === 0
    ) {
      this.refCash.current.value = priceTicked.toFixed(2);
    }
    if (
      +this.refCart.current.value >= priceTicked &&
      +this.refCash.current.value === 0
    ) {
      this.refCart.current.value = priceTicked.toFixed(2);
    }
    if (e.target.name === "MS") {
      if (
        +this.refCart.current.value >
        priceTicked - this.refCash.current.value
      ) {
        this.refCart.current.value = parseFloat(
          priceTicked - +this.refCash.current.value
        ).toFixed(2);
      }
    }
    if (e.target.name === "CASH") {
      if (
        +this.refCash.current.value >
        priceTicked - this.refCart.current.value
      ) {
        this.refCash.current.value = parseFloat(
          priceTicked - +this.refCart.current.value
        ).toFixed(2);
      }
    }
    if (+this.refCash.current.value > 0 && +this.refCart.current.value === 0) {
      pey = "CASH";
      params = {
        ident_type_1: "CASH",
        pay_sum_1: this.refCash.current.value
      };
    } else if (
      +this.refCart.current.value > 0 &&
      +this.refCash.current.value > 0
    ) {
      params = {
        ident_type_1: "MS",
        pay_sum_1: this.refCart.current.value,
        ident_type_2: "CASH",
        pay_sum_2: this.refCash.current.value
      };
      pey = "MS";
    } else if (+this.refCart.current.value > 0) {
      pey = "MS";
      params = {
        ident_type_1: "CASH",
        pay_sum_1: this.refCart.current.value
      };
    } else {
      params = false;
    }

    this.setState({
      payOrder: +this.refCash.current.value + +this.refCart.current.value,
      ident_type_id: pey,
      paramsPay: params
    });
  };

  returnPage = () => {
    this.props.history.goBack();
  };

  deleteOrder = () => {
    const { tickets } = this.state;
    tickets.length !== 0 && this.setState({ preloader: true });
    tickets.length !== 0 &&
      _.filter(tickets, function (item) {
        return item.ticket_type_id < 5;
      })
        .concat(
          _.intersectionBy(
            _.filter(tickets, { ticket_type_id: 5 }),
            "parent_seat_id"
          )
        )
        .forEach(item => {
          const deleteOrder = getCancelablePromise(deletedOrder(item.id));
          Promise.all([deleteOrder.promise]).then(data => {
            if (data[0] !== error.notFound) {
              data[0].length === 0 &&
                this.setState({
                  preloader: false,
                  tickets: [],
                  listEvents: [],
                  priceTicked: 0,
                });
            }
          });
        });
  };

  handleCloseModal = () => {
    this.setState({ errorBasket: false, errorCashRegister: false });
  };

  formatedData = date => {
    if (date) {
      return (
        date.substring(8, 10) +
        " " +
        moment(date)
          .format("MMMM")
          .substring(0, 3) +
        "."
      );
    } else {
      return null;
    }
  };

  onChangePromocodes = (type, value) => {
    this.setState({
      promocodes: {
        ...this.state.promocodes,
        [type]: value,
        loaderPromocode: false,
        valueCoastPromo: false,
        errorPromo: false,
      },
    })
  }

  setOrderDiscount = (analog_code, amount) => {
    if (this.state.order_id) {
      this.setState({
        promocodes: {
          ...this.state.promocodes,
          loaderPromocode: true,
          valueCoastPromo: false,
        }
      })
      try {
        clearTimeout(this.state.setTimeoutPromo)
        if (analog_code && amount) {
          this.setState({
            promocodes: {
              ...this.state.promocodes,
              errorPromo: false,
            },
            setTimeoutPromo: setTimeout(() => {
              setOrderAnalog({
                order_id: this.state.order_id,
                analog_code,
                amount
              })
                .then(({ data }) => {
                  this.updateOrder(data)
                  this.setState({
                    promocodes: {
                      ...this.state.promocodes,
                      valueCoastPromo: amount
                    }
                  })
                })
                .catch(er => {
                  this.setState({
                    promocodes: {
                      ...this.state.promocodes,
                      errorPromo: er.response.data.text,
                    }
                  })
                })
            }, 1000)
          })
        }
      } catch (er) {
        console.error(er)
      }
    } else {
      ToastsStore.error('Необходимо создать заказ!')
    }
  }

  updateOrder = (data) => {
try {
    let coast_cods = 0;
    let coast_sertificate = 0;
    data.used_analogs.forEach(item => {
      console.log(item)
      if(item.analog_type_id === 2) {
          coast_cods = Number(coast_cods) + Number(item.used_amount)
      } else {
        coast_sertificate = Number(coast_sertificate) + Number(item.used_amount)
      }
    })
    this.setState({
      priceTicked: +data.sale_amount,
      deals: {
        coast_cods: coast_cods.toFixed(2),
        coast_sertificate: coast_sertificate.toFixed(2)
      },
      promocodes: {
        ...this.state.promocodes,
        analog_code: '',
        amount: '',
      }
    })
  } catch(er) {
    console.log(er)
  }
  }

  changeTypeTemplate = (e) => {
    console.log(e);
    this.setState({
      choiceTypeTickets: e,
    })
  }

  render() {
    const { listEvents, preloader, errorBasket } = this.state;

    return (
      <>
        <nav className="nav">
          <div onClick={this.returnPage} className="nav-btn">
            <button type="button" className="btn">
              <span className="icon-left svg-icon"></span>
            </button>
            <span>Назад</span>
          </div>
          <Header page="basket" />
        </nav>
        <div className="content-wrap">
          <section className="content">
            {listEvents.length ? (
              listEvents.map(
                item =>
                  item.event && (
                    <EventType
                      time={this.formatedData(item.event.date_time_from)}
                      date={moment(item.event.date_time_from).format("H:mm")}
                      name={item.event.descriptions[0].name}
                      location={item.venue.descriptions[0].name}
                      city={item.city.descriptions[0].name}
                      type={item.event_categories[0].descriptions[0].name}
                    />
                  )
              )
            ) : (
              <div></div>
            )}

            {/* {preloader && <Spinner />} */}

            <Sale
              refCash={this.refCash}
              refCart={this.refCart}
              paymentOrder={this.paymentOrder}
              orderPay={this.orderPay}
              deleteOrder={this._orderDelete}
              typesTickets={this.state.typesTickets}
              choiceTypeTickets={this.state.choiceTypeTickets}
              errorPay={this.state.errorPay}
              changeTypeTemplate={this.changeTypeTemplate}
              // promocodes
              values={this.state.promocodes}
              setFieldValue={this.onChangePromocodes}
              setOrderDiscount={this.setOrderDiscount}
              disabledOrder={this.state.order_id}
            />
          </section>
          <aside className="sidebar sidebar-right">
            <Payment
              coast={this.state.priceTicked}
              lengthTickets={
                _.filter(this.state.tickets, function (item) {
                  return item.ticket_type_id < 5;
                }).length +
                _.intersectionBy(
                  _.filter(this.state.tickets, { ticket_type_id: 5 }),
                  "parent_seat_id"
                ).length
              }
            />
            <div className="paid">
              <Score
              deals={this.state.deals}
                cart={
                  this.refCart.current !== null && (
                    <span>
                      {this.refCart.current.value === ""
                        ? 0
                        : this.refCart.current.value}
                    </span>
                  )
                }
                cash={
                  this.refCash.current !== null && (
                    <span>
                      {this.refCash.current.value === ""
                        ? 0
                        : this.refCash.current.value}
                    </span>
                  )
                }
              />
            </div>
          </aside>
          <div className="sidebar-icon">
            <button className="btn">
              <span className="icon icon-backet"></span>
            </button>
          </div>
          {preloader && <DimmerLoader active={preloader} />}
        </div>
        {errorBasket && (
          <Modal isClose hideModal={this.handleCloseModal}>
            <div className="modal-text">
              {!this.state.errorCashRegister ? (
                <>Произошла ошибка</>
              ) : (
                <>Произошла ошибка с кассовым апаратом, повторите операцию!</>
              )}
            </div>
          </Modal>
        )}
        <ToastsContainer position={ToastsContainerPosition.TOP_RIGHT} store={ToastsStore} />
      </>
    );
  }
}

Basket.propTypes = {
  tickets: PropTypes.array,
  listEvents: PropTypes.array
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, null)(Basket);
