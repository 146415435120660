import React from 'react';
import PropTypes from 'prop-types';

const TicketReturn = ( props ) => {
  const { id, price, data, checkTicket } = props;
  let firstOne, firstSecond, secondOne, secondSecond, thirdOne, thirdSecond, fourthOne, fourthSecond, places = [];

  data.forEach(item => {
    places.push(item)
  });

  if (places[0]) {
    firstOne = places[0][0] ? places[0][0] : '';
    firstSecond = places[0][1] ? places[0][1] : '';
  }
  if (places[1]) {
    secondOne = places[1][0] ? places[1][0] : '';
    secondSecond = places[1][1] ? places[1][1] : '';
  }
  if (places[2]) {
    thirdOne = places[2][0] ? places[2][0] : '';
    thirdSecond = places[2][1] ? places[2][1] : '';
  }
  if (places[3]) {
    fourthOne = places[3][0] ? places[3][0] : '';
    fourthSecond = places[3][1] ? places[3][1] : '';
  }

  return (
    <div className="ticket-list__box">
      <div className="ticket-list__box-body">
        <div className="ticket-list__box-group">
          <div className="ticket-list__box-seat">{firstOne} <b>{firstSecond}</b></div>
          <div className="ticket-list__box-seat">{secondOne} <b>{secondSecond}</b></div>
        </div>
        <div className="ticket-list__box-group">
          <div className="ticket-list__box-seat">{thirdOne} <b>{thirdSecond}</b></div>
          <div className="ticket-list__box-seat">{fourthOne} <b>{fourthSecond}</b></div>
        </div>
      </div>
      <div className="ticket-list__box-foot">
        <div className="ticket-list__box-price">{price}</div>
        <div className="ticket-list__box-checkbox">
          <input type="checkbox" id={id} onChange={checkTicket} />
          <label htmlFor={id} className="checkbox"></label>
        </div>
      </div>
    </div>
  );
}

TicketReturn.propTypes = {
  price: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  checkTicket: PropTypes.func.isRequired
}

export default TicketReturn;
