import axios from 'axios';
import { encodeXML, parseXML, paramsSerializer } from '../../helpers/index';
import moment from 'moment';
import Cookies from 'js-cookie';

export const getXML = (
  login, password, 
  errorLine = 'Error ErrorLine') => (
    axios.get(`https://stand.besmart.by/ticket-wc/xml_online.admin?XML=${encodeXML(
      `<BS_Request>
    <TerminalId>SSO_Client_Web_Cash</TerminalId>
    <TerminalTime>${moment().format("YYYYMMDDhhmmss")}</TerminalTime>
    <RequestType>Login</RequestType>
    <Subsystem>ClientAuth</Subsystem>
    <Login Type="PWD">
      <Parameter Id="Login">${login}</Parameter>
      <Parameter Id="Password">${password}</Parameter>
    </Login>
    </BS_Request>`
    )}`)
      .then(async (resp) => {
        const result = parseXML(resp.data, 'Login SID');
        if (result) {
          //Cookies.set('ssoclid', result);
          // const toTime = moment().add(20, 'minutes').toDate().getTime();
          // localStorage.setItem('timeTo', toTime);
          localStorage.setItem('ssoclid', result);
          if (localStorage.getItem('timeWork') === null) {
            localStorage.setItem('timeWork', moment().format('DD/MM/YYYY h:mm:ss'));
          }
        
          return {ssoc: result}
        }
        return {ssoc: false, error: parseXML(resp.data, errorLine)};
      })
  );  
