import { EVENT } from '../const'
import { eventsList } from '../../api/event';
import { getCancelablePromise } from '../../helpers/index';
import { error } from '../../containers/const';

export function setEvents(body) {
  return {
    type: EVENT.ADD_EVENTS,
    events: body
  }
}

export function setPreloader(body) {
  return {
    type: EVENT.PRELOADER,
    preloader: body,
  }
}

export function setSearch(body) {
  return {
    type: EVENT.SET_SEARCH_INPUT,
    searchInput: body
  }
}
export function setDefaultSearch() {
  return {
    type: EVENT.DEFAULT_SEARCH
  }
}

export function setEventsCategories(body) {
  return {
    type: EVENT.SET_EVENTS,
    value: body
  }
}
export function setVenues(body) {
  return {
    type: EVENT.SET_VENUES,
    value: body
  }
}
export function setCategories(body) {
  return {
    type: EVENT.SET_CATEGORIES,
    value: body
  }
}
export function setSelectTo(body) {
  return {
    type: EVENT.SET_SELECT_TO,
    value: body
  }
}
export function setSelectFrom(body) {
  return {
    type: EVENT.SET_SELECT_FROM,
    value: body
  }
}
export function setCities(body) {
  return {
    type: EVENT.SET_CITIES,
    value: body
  }
}
export function setMin(body) {
  return {
    type: EVENT.SET_MIN,
    value: body
  }
}
export function setMax(body) {
  return {
    type: EVENT.SET_MAX,
    value: body
  }
}
export function setCurrentPage(body) {
  return {
    type: EVENT.CURRENT_PAGE,
    page: body
  }
}
export function setCurrentButtons(body) {
  return {
    type: EVENT.BUTTONS,
    value: body
  }
}
export function setCurrentDay(body) {
  return {
    type: EVENT.SET_CURRENT_DAY,
    value: body
  }
}
export const setAsyncEvent = () => dispatch => {  
  let eventsinfo = getCancelablePromise(eventsList());
  return Promise.all([eventsinfo.promise])
    .then((data) => {
      if (data[0] === error.notFound) {
      } else {
        dispatch({
          type: EVENT.BUTTONS,
          value: Math.ceil(data[0].all_count / 15)
        });
        dispatch({
          type: EVENT.ADD_EVENTS,
          events: data[0]
        });
        dispatch({
          type: EVENT.PRELOADER,
          preloader: false
        });
        return data[0]
      }
    }).catch((err) => {
      dispatch({
        type: EVENT.PRELOADER,
        preloader: false
      });
      return err
    })
}