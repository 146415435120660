import React from 'react';
import { Component } from 'react';
import { clientInfo } from '../api/auth';
import { getXML } from '../server/implementation/index';
import { login, clientAuth, loading } from '../store/actions/auth';
import { connect } from 'react-redux';
import Logo from '../assets/img/logo.svg';
import DimmerLoader from '../components/DimmerLoader/dimmerLoader';

class Login extends Component {
    savePassword = React.createRef();

    state = {
        error: null,
        secretPassword: "password",
        savePassword: false,
        tryToLogin: false,
    };

    apiAuth = async (e) => {
        e.preventDefault();

        this.setState({ tryToLogin: true })
        if (this.state.savePassword) {
            localStorage.setItem('login', e.target.login.value);
        }

        localStorage.setItem('loginAuth', e.target.login.value);
        localStorage.setItem('passwordAuth', e.target.password.value);

        let tryToLogin = (await getXML(
            e.target.login.value,
            e.target.password.value,
        ).then());
        if (tryToLogin.ssoc) {
            this.props.login(tryToLogin.ssoc);
            let sso = await (clientInfo(tryToLogin.ssoc));
            if (!sso.error) {
                this.props.clientAuth(sso.response.data);
                this.props.loading(true);
            } else {
                this.setState({ error: "error server" });
                this.setState({ tryToLogin: false });
            }
        }
        else {
            this.setState({ tryToLogin: false })
            this.setState({ error: tryToLogin.error });
        }
    };

    secretPassword = () => {
        if (this.state.secretPassword === "password") {
            this.setState({ secretPassword: "text" });
        }
        else {
            this.setState({ secretPassword: "password" });
        }
    };

    savePassword = () => {
        this.setState({ savePassword: !this.state.savePassword });
    };

    render() {
        const { error, tryToLogin } = this.state;

        return (
            <>
                <div className="login-wrapper">
                    <form onSubmit={this.apiAuth} className="login">
                        <img src={Logo} className="login__logo" alt="Логотип" />
                        <h1 className="login__title">Войдите в акаунт</h1>
                        <div className="input">
                            <input defaultValue={localStorage.getItem('login')} type="text" name="login" placeholder="Логин" />
                            {error && <div className="error-message">{error}</div>}

                        </div>
                        <div className="input">
                            <input name="password" type={`${this.state.secretPassword}`} placeholder="Пароль" />
                            <span onClick={this.secretPassword} className="svg-icon icon-eue"></span>
                        </div>
                        <div onChange={this.savePassword} className="login__remember">
                            <input type="checkbox" name="" id="checkbox-remember" />
                            <label htmlFor="checkbox-remember" className="checkbox"></label>
                            <label htmlFor="checkbox-remember" className="login__remember-text">Запомнить меня</label>
                        </div>
                        <button type="submit" className="btn btn-orange">Войти</button>
                        <a href="/" className="privacy-policy">Политика конфиденциальности</a>
                    </form>
                </div>
                <DimmerLoader active={tryToLogin} />
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = {
    login,
    clientAuth,
    loading
};

export default connect(mapStateToProps, mapDispatchToProps)(Login)
