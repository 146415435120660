import React, {Component} from "react";
import {connect} from 'react-redux';
import moment from "moment";
import 'moment/locale/ru';
import Header from "../SwitchingHeader";
import TicketsOrder from "./TicketsOrder";
import {getCancelablePromise, parseNameAttribute} from "../../helpers";
import {getSchemeForSeats} from "../../api/event";
import {ordersList, anyOrder, orderEntityDelete, sellerOrderEntityReturn} from '../../api/ticket';
import { Pagination } from 'semantic-ui-react';
import Spinner from "../Spinner";
import _ from 'lodash';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TicketReturn from "./TicketReturn";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import 'react-perfect-scrollbar/dist/css/styles.css';

class Orders extends Component{
    constructor(props) {
        super(props);

        this.state = {
            seatScheme: [],
            listOrders: [],
            min: 1,
            max: 15,
            currentPage: 1,
            ordersPage: 0,
            maxItemForPage: 15,
            allCount: 0,
            currentOrderId: null,
            tickets: [],
            ticketsReturn: [],
            coastTickets: 0,
            dateFrom: null,
            dateTo: null,
            isSpinner: false,
            searchOrderValue: '',
            paymentType: null,
            showButtonReturn: false,
            showSidebar: false
        }
    }

    // componentWillUnmount() {
    //     document.removeEventListener('click', this.handleHideSidebar, false);
    // }

    componentDidMount() {
        const { auth } = this.props;
        const Seats = getCancelablePromise(getSchemeForSeats());
        Promise.all([Seats.promise])
            .then((data) => {
                this.setState({
                    seatScheme: data[0]
                })
            });
        this.getOrdersList();
        document.addEventListener('click', this.handleHideSidebar, false);
    }

    getOrdersList = async () => {
        this.setState({
            isSpinner: true,
            currentOrderId: null,
            tickets: [],
            ticketsReturn: [],
            coastTickets: 0,
            paymentType: null,
            showButtonReturn: false
        });
        const { auth } = this.props;
        const { maxItemForPage } = this.state;
        const { min, max, dateFrom, dateTo } = this.state;
        const currentDateFrom = !!dateFrom ? moment(dateFrom).format('YYYY-MM-DD'): dateFrom;
        const currentDateTo = !!dateTo ? moment(dateTo).format('YYYY-MM-DD') : dateTo;
        let listOrders = await ordersList(min, max, currentDateFrom, currentDateTo);
        console.log(listOrders);
        if (listOrders.orders.length) {
            this.setState({
                listOrders: listOrders.orders,
                ordersPage: Math.ceil(listOrders.all_count / maxItemForPage),
                allCount: listOrders.all_count,
            });
        } else {
            this.setState({
                listOrders: [],
            });
        }
        this.setState({
            isSpinner: false
        });
    };

    showOrderTickets = async (e) => {
        this.setState({
            ticketsReturn: [],
            showButtonReturn: false
        });
        const {listOrders, currentOrderId} = this.state;
        const currentId = +e.target.closest('.table-row').getAttribute('id');
        if (currentOrderId === currentId){
            this.setState({
                tickets: [],
                currentOrderId: null,
                coastTickets: 0,
                paymentType: null,
            })
        } else {
            let coast = 0;
            let basketTickets = _.find(listOrders, {id: currentId}).tickets;
            let paymentTypeId = _.find(listOrders, {id: currentId}).ident_type.id;
            basketTickets.forEach(ticket => {
                coast += +ticket.price;
            });
            this.setState({
                tickets: basketTickets,
                currentOrderId: currentId,
                coastTickets: coast,
                paymentType: paymentTypeId
            })
        }
    };

    searchOrders = async (e) => {
        const { auth } = this.props;
        const numberOrder = e.target.value;
        this.setState({
            isSpinner: true,
            tickets: [],
            currentOrderId: null,
            showButtonReturn: false,
            coastTickets: 0,
            searchOrderValue: numberOrder,
        });
        let anyOrderUser = await anyOrder( numberOrder);
        if(numberOrder == ''){
            this.getOrdersList();
        } else {
            if (!!anyOrderUser){
                this.setState({
                    listOrders: [anyOrderUser],
                });
            } else {
                this.setState({
                    listOrders: [],
                });
            }
            this.setState({
                isSpinner: false
            });
        }
    };

    setPageNum = (e, { activePage }) => {
        const { maxItemForPage } = this.state;
        this.setState({
            currentPage: activePage,
            min: (((activePage - 1) * maxItemForPage) + 1),
            max: activePage * maxItemForPage,
        }, function () {
            this.getOrdersList();
        })
    };

    handleChangeDateFrom = (date) => {
        this.setState({
            dateFrom: date,
        }, function () {
            this.getOrdersList();
        })
    };

    handleChangeDateTo = date => {
        this.setState({
            dateTo: date
        }, function () {
            this.getOrdersList();
        })
    };

    resetListOrders = () => {
        this.setState({
            dateFrom: null,
            dateTo: null,
            tickets: [],
            currentOrderId: null,
            coastTickets: 0,
            searchOrderValue: ''
        }, function () {
            this.getOrdersList();
        });

    };

    checkTicket = (e) => {
        const {ticketsReturn} = this.state;
        const isChecked = e.target.checked;
        const currentTicketId = +e.target.id;
        if (isChecked) {
            ticketsReturn.push(currentTicketId);
            this.setState({
                ticketsReturn: ticketsReturn,
                showButtonReturn: true
            })
        } else {
            ticketsReturn.splice(~ticketsReturn.indexOf(currentTicketId), 1);
            this.setState({
                ticketsReturn: ticketsReturn
            });
            if (!ticketsReturn.length){
                this.setState({
                    showButtonReturn: false
                });
            }
        }
    };

    returnOrderEntity = async () => {
        const {listOrders, currentOrderId, paymentType, ticketsReturn} = this.state;
        ticketsReturn.forEach(item => {
            sellerOrderEntityReturn(item, paymentType)
            .then(res => {
                ToastsStore.success('Успешно возвращено')
            })
            .catch(er => {
                ToastsStore.error(er.response?.data?.text || er.response?.data?.error)
            })
        });
    };

    paymentReturnType = (e) => {
        this.setState({
            paymentType: e.target.value
        })
    };

    handleShowSidebar = () => {
        this.setState({
            showSidebar: true
        })
    };

    handleHideSidebar = (e) => {
        const sidebar = document.getElementsByClassName('sidebar-right')[0];
        const sidebarButton = document.getElementsByClassName('sidebar-icon')[0];
        if (!e.path.includes(sidebar) && !e.path.includes(sidebarButton)){
            this.setState({
                showSidebar: false
            })
        }
    };

    render() {
        const {
            listOrders, currentPage, ordersPage, allCount, maxItemForPage, isSpinner,
            tickets, coastTickets, paymentType, showButtonReturn,
            dateFrom, dateTo, searchOrderValue,
            showSidebar
        } = this.state;

        const datePickerFrom = !!dateFrom ? dateFrom : new Date();
        const datePickerTo = !!dateTo ? dateTo : new Date();

        return(
            <>
                <nav className="nav">
                    <Header page="orders" />
                </nav>
                <div className="content-wrap">
                    <section className="content">
                        <div className="heading">
                            <h1>Мониторинг заказов</h1>
                        </div>
                        <div className="table-filter">
                            <div className="table-filter__search">
                                <input type="text" placeholder="Номер заказа" value={searchOrderValue} onChange={this.searchOrders} />
                                <span className="icon icon-search"></span>
                            </div>
                            <div className="table-filter__date-range">
                                <div className="input">
                                    <span className="svg-icon icon-calendar"></span>
                                    <DatePicker
                                        dateFormat="dd MMM yyyy"
                                        selected={datePickerFrom}
                                        onChange={this.handleChangeDateFrom}
                                        locale='ru'
                                        maxDate={datePickerTo}
                                    />
                                </div>
                                <div className="input">
                                    <span className="svg-icon icon-calendar"></span>
                                    <DatePicker
                                        dateFormat="dd MMM yyyy"
                                        selected={datePickerTo}
                                        onChange={this.handleChangeDateTo}
                                        locale='ru'
                                        minDate={datePickerFrom}
                                    />
                                </div>
                            </div>
                            <div className="table-filter__checkbox">
                                <input type="checkbox" id="checkbox" />
                                <label htmlFor="checkbox" className="checkbox"></label>
                                <label htmlFor="checkbox">Только мои</label>
                            </div>
                            <div className="table-filter__button">
                                <button type="button" className="btn btn-link" onClick={this.resetListOrders}>
                                    <span className="icon icon-close"></span>Сбросить
                                </button>
                            </div>
                        </div>
                        <div className="table-wrap">
                            <div className="table order-table">
                                <div className="table-body">
                                    {!isSpinner ?
                                        listOrders.length ?
                                        listOrders.map(item => {
                                            let totalPrice = 0;
                                            item.tickets.map(ticket => {
                                                totalPrice += ticket.price
                                            });
                                            return (
                                                <TicketsOrder
                                                    id={item.id}
                                                    currentOrderId={this.state.currentOrderId}
                                                    date={item.inserted.substring(8, 10) + ' ' + moment(item.inserted).format('MMMM').substring(0, 3) + ' ' + item.inserted.substring(0, 4)}
                                                    time={moment(item.inserted).format('HH:mm')}
                                                    price={totalPrice}
                                                    currency={item.currency}
                                                    method={item.ident_type.descriptions && item.ident_type.descriptions[0].name}
                                                    showOrderTickets={this.showOrderTickets}
                                                    key={item.id}
                                                />
                                            )
                                        }) : "Ничего не найдено"
                                        : <Spinner />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="pagination">
                            <div className="pagination__links">
                                {allCount > maxItemForPage && (
                                    <Pagination
                                        activePage={currentPage}
                                        totalPages={ordersPage}
                                        siblingRange={1}
                                        onPageChange={this.setPageNum}
                                        firstItem={null}
                                        lastItem={null}
                                        ellipsisItem={null}
                                        pageItem={{ className: "pagination__item" }}
                                        boundaryRange={0}
                                        prevItem={currentPage !== 1 && { className: "pagination__item", content: <span className="svg-icon icon-arr-left"></span>, icon: true }}
                                        nextItem={ordersPage !== currentPage && ordersPage !== 0 && { className: "pagination__item", content: <span className="svg-icon icon-right"></span>, icon: true }}
                                    />
                                )}
                            </div>
                        </div>
                    </section>
                    <aside className={`sidebar sidebar-right ${showSidebar ? 'show' : ''}`}>
                        <div className="basket">
                            <div className="sidebar-head"><span className="icon icon-tickets"></span>Билеты в заказе
                            </div>
                            <div className="sidebar-body">
                                <div className="ticket-list">
                                    <PerfectScrollbar>
                                        {tickets.length ? tickets.map((ticket, i) => (
                                            <TicketReturn
                                                id={ticket.id}
                                                key={ticket.id}
                                                price={ticket.price}
                                                data={parseNameAttribute(this.state.seatScheme, ticket.ticket_name)}
                                                checkTicket={this.checkTicket}
                                                onClickHandler={(e) => {
                                                    this.deleteOrderEntity(ticket.id);
                                                }}
                                            />
                                        )) : "Ничего не выбрано"
                                        }
                                    </PerfectScrollbar>
                                </div>
                            </div>
                        </div>
                        <div className="payment">
                            <div className="sidebar-body">
                                <div className="payment-icon">
                                    <span className="icon icon-tickets"></span>
                                </div>
                                <div className="payment-count">Всего билетов в заказе: <b>{tickets.length} шт</b></div>
                                <div className="payment-price">На сумму: <b>{Number.isInteger(coastTickets) ? coastTickets : coastTickets.toFixed(2)} BYN</b></div>
                                <div className="payment-type">
                                    <span>Выберите способ оплаты для возврата</span>
                                    <select name="payment_return_type" onChange={this.paymentReturnType} value={!!paymentType && paymentType}>
                                        <option value="CASH">Наличные</option>
                                        <option value="MS">Банковская карточка</option>
                                    </select>
                                </div>
                                {showButtonReturn && (
                                    <div className="payment-button">
                                        <button type="button" className="btn btn-orange" onClick={this.returnOrderEntity}>
                                            <span className="icon icon-exchange"></span>Вернуть
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </aside>
                    <div className="sidebar-icon">
                        <button className="btn" onClick={this.handleShowSidebar}><span className="icon icon-backet"></span></button>
                    </div>
                </div>
                <ToastsContainer position={ToastsContainerPosition.TOP_RIGHT} store={ToastsStore} />

            </>
        )
    }
}

const MapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(MapStateToProps)(Orders)
