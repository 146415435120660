import React, { Component } from 'react'

export default class Payment extends Component {
    render() {
        const {lengthTickets, coast} = this.props;
        return (
            <div className="payment">
            <div className="sidebar-head"><span className="icon icon-cards"></span>Оплата</div>
            <div className="sidebar-body">
                <div className="payment-icon">
                    <span className="icon icon-cards"></span>
                </div>
                <div className="payment-count">Итого билетов в корзине: <b>{lengthTickets} шт</b></div>
                <div className="payment-price">К оплате: <b>{Number.isInteger(coast) ? coast : coast.toFixed(2)} BYN</b></div>
                <div className="payment-discount">Скидка: <b>0,00 BYN</b></div>
            </div>
        </div>
        )
    }
}
