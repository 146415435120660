import Cookies from 'js-cookie';
import { AUTH } from '../const'

const initialState = {
    ssoclid: null,
    client: {
        client_id: false,
        is_it_seller: false,
        is_it_promoter: false,
        login: null,
        fio: null,
    },
    loading: false,
};

if (localStorage.getItem('ssoclid') && localStorage.getItem('ssoclid') !== null) {
    initialState.ssoclid = localStorage.getItem('ssoclid');
}

export default function auth(state = initialState, action) {

    switch (action.type) {
        case AUTH.LOGIN:
            return Object.assign({}, state, { ssoclid: action.payload });
        case AUTH.GET_CLIENT:
            return Object.assign({}, state, { client: action.payload });
        case AUTH.LOADING:
            return Object.assign({}, state, { loading: action.payload });
        case AUTH.LOGOUT:
            return Object.assign({}, state, { loading: false },
                {
                    client: {
                        client_id: false,
                        is_it_seller: false,
                        is_it_promoter: false,
                        login: null,
                        fio: null
                    }
                });
        default:
            return state
    }
}