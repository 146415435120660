import React, { Component } from 'react';
import Select from "react-select";

//import { SetPromoTicket } from '../../api/ticket'

export default class Category extends Component {

    state = {
        loader: false,
        valuesPromocode: {},
    }
    componentDidMount() {
    }

    selectedValues = (ticket) => {
        let newArray = [
            {
                id: 'all',
                descriptions: [
                    {
                        name: 'Выберите скидку'
                    }
                ]
            }
        ]
        newArray = [
            ...newArray,
            ...ticket.discounts,
        ]
        return newArray
    }

    renderPlace = (element) => {
        var places = [];
        const data = this.props.parseNameAttribute(this.props.planList, element);
        data.forEach(item => {
            places.push(item)
        });
        return [].concat(...places).join(' ');
    }

    setDiscountSelected = async (...params) => {
        this.setState(function (prevState) {
            let newValidate = prevState.validate;
            newValidate[params[0]] = ''
            return { validate: newValidate, loader: true, }
        });
        return true;
        // await SetPromoTicket(...params)
        //   .then(res => {
        //     this.setState(function (prevState) {
        //       let newValidate = prevState.validate;
        //       newValidate[params[0]] = 'has-success'
        //       return { validate: newValidate }
        //     });
        //   })
        //   .catch(er => {
        //     this.setState(function (prevState) {
        //       let newValidate = prevState.validate;
        //       newValidate[params[0]] = 'has-error'
        //       return { validate: newValidate }
        //     });
        //   })
        //   .finally(() => {
        //     clearTimeout(this.state.setTimeoutUpdate)
        //     this.setState({
        //       setTimeoutUpdate: setTimeout(() => {
        //         this.props.updateBasket()
        //         this.setState({
        //           loader: false,
        //         })
        //       }, 250)
        //     })
        //   })
    }

    render() {
        const { loader, valuesPromocode } = this.state;
        const { buyTicket, deleteTicket, checkedTicket, pricesList, pricesListZones, ticketsOrder, parseNameAttribute, planList } = this.props;
        return (
            pricesListZones.map(element => {
                return (
                    element.price.map(item =>
                        <div key={item.id} className="table-row">
                            <div className="table-col quick-sale-title">{this.renderPlace(element.node_path)}</div>
                            <div className="table-col quick-sale-price">
                                <div className="color">
                                    <span className="color-value"></span>
                                    <span></span>
                                </div>
                            </div>
                            <div className="table-col quick-sale-type">
                                <Select
                                    isOptionDisabled={(option) => option.hasOwnProperty('disabled')}
                                    classNamePrefix="select"
                                    isDisabled={loader}
                                    placeholder="Выберите из списка"
                                    isSearchable={false}
                                    menuPortalTarget={document.body}
                                    options={this.selectedValues(item)}
                                    defaultValue={
                                        item.hasOwnProperty('info') &&
                                        item.info.discount_id &&
                                        this.selectedValues(item).filter(itemPromo => String(itemPromo.id) === String(item.info.discount_id))[0]}
                                    onChange={(e) => {
                                        this.props.setDiscount(element.node_id, item.id, e.id, 'discount_id')
                                    }}
                                    getOptionLabel={(option) => {
                                        return option.descriptions[0].name
                                    }}
                                    getOptionValue={(item) => item.id}
                                />
                            </div>
                            <div className="table-col quick-sale-promocode">
                                <input
                                disabled={!item?.discount_id || (item?.discount_id && item?.discount_id !== 'all' &&
                                item.discounts.filter(itemD => itemD.id === item?.discount_id)[0].promo_needed === 0)} 
                                    onChange={(e) => {
                                        this.props.setDiscount(element.node_id, item.id, e.target.value, 'promo')
                                    }}
                                    type="text" placeholder="Промо-код" />
                                {/* <span className="icon icon-circle-check"></span> */}
                            </div>
                            <div className="table-col quick-sale-count">
                                <div className="count">
                                    <button onClick={() => deleteTicket(element.node_id, item.id)} className="count-dec">−</button>
                                    <input onChange={(e) => buyTicket(element.node_id, item.id, e.target.value)} type="text" id={item.id} className="count-field" value={item.countTicket} />
                                    <button onClick={() => buyTicket(element.node_id, item.id)} className="count-inc">+</button>
                                </div>
                            </div>
                            <div className="table-col quick-sale-total">
                                <b className={`
                                ${item?.discount_id && item?.discount_id !== 'all' ? 'quick-sale-total_red' : ''}
                                `}>{item.price} BYN</b>
                                <b>
                                    {
                                        item?.discount_id && item?.discount_id !== 'all' && (
                                            <>
                                                {item.discounts.filter(itemD => itemD.id === item?.discount_id)[0].price}
                                                {' '}BYN

                                            </>
                                        )
                                    }
                                </b>
                            </div>
                        </div>)
                )
            })
        )
    }
}
