import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getXML } from '../../src/server/implementation/index';
import { login, logout, loading } from '../store/actions/auth'
import moment from 'moment';

export default function (ComposedClass) {
    class Component extends React.Component {

        state = {
            auth: true,
        }

        componentDidMount = async () => {
            // this.authCheck();
            // setInterval(async () => { this.authCheck() }, 60000);
        }

        authCheck = async () => {
            const { auth } = this.props;
            let timeTo = localStorage.getItem('timeTo');
            if (timeTo !== false && timeTo < (moment().toDate().getTime())) {
                var login = localStorage.getItem('loginAuth');
                var password = localStorage.getItem('passwordAuth');
                var tryToLogin = (await getXML(
                    login,
                    password
                ));
                if (tryToLogin.ssoc) {
                    let loading = await this.props.login(tryToLogin.ssoc);
                    if(!loading.error) {
                        this.props.loading(true);
                    }
                } else {
                    this.props.logout();
                }
                // }
            } else if(timeTo !== false) {
                if (auth.ssoclid !== localStorage.getItem('ssoclid')) {
                    this.props.login(localStorage.getItem('ssoclid'));
                  }
            }
            this.setState({
                auth: true,
            })
        }

        render() {
            return (
                    this.state.auth ?  (<ComposedClass {...this.props} />) : null
            );

        }
    }


    const mapStateToProps = (state) => ({
        auth: state.auth,
    })

    const mapDispatchToProps = {
        login,
        logout,
        loading
    }
    return connect(mapStateToProps, mapDispatchToProps)(Component)
}
