import React, { Component } from 'react'
import './index.css';
export default class ReservesTicket extends Component {
    render() {
        const { id, code, fio, selectReserved, email, phone, currentId } = this.props;
        return (
            <div key={id} id={code} onClick={selectReserved} className={currentId === id ? "table-row activeReserved" : 'table-row'}>
                <div code={id} id={code} className="table-col order-number">{code}</div>
                <div code={id} id={code} className="table-col order-fio">{fio}</div>
                <div code={id} id={code} className="table-col order-email">{email}</div>
                <div code={id} id={code} className="table-col order-phone">{phone}</div>
                <div code={id} id={code} className="table-col order-icon"><span className="icon icon-hand"></span></div>
            </div>
        )
    }
}
