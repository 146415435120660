import React from 'react';
import PropTypes from 'prop-types';

const BasketTicket = ( props ) => {
  const { price, data, onClickHandler, packet, key } = props;
  let firstOne, firstSecond, secondOne, secondSecond, thirdOne, thirdSecond, fourthOne, fourthSecond, places = [];

  data.forEach(item => {
    places.push(item)
  });

  if (places[0]) {
    firstOne = places[0][0] ? places[0][0] : '';
    firstSecond = places[0][1] ? places[0][1] : '';
  }
  if (places[1]) {
    secondOne = places[1][0] ? places[1][0] : '';
    secondSecond = places[1][1] ? places[1][1] : '';
  }
  if (places[2]) {
    thirdOne = places[2][0] ? places[2][0] : '';
    thirdSecond = places[2][1] ? places[2][1] : '';
  }
  if (places[3]) {
    fourthOne = places[3][0] ? places[3][0] : '';
    fourthSecond = places[3][1] ? places[3][1] : '';
  }
  // console.log(packet)
  return (
    <div key = {key} className="ticket-list__box">
             {packet === 5 && <div className="ticket-lists">пакет</div>}
      <div className="ticket-list__box-body">
        <div className="ticket-list__box-group">
          <div className="ticket-list__box-seat">{firstOne} <b>{firstSecond}</b></div>
          <div className="ticket-list__box-seat">{secondOne} <b>{secondSecond}</b></div>
        </div>
        <div className="ticket-list__box-group">
          <div className="ticket-list__box-seat">{thirdOne} <b>{thirdSecond}</b></div>
          <div className="ticket-list__box-seat">{fourthOne} <b>{fourthSecond}</b></div>
        </div>
      </div>
      <div className="ticket-list__box-foot">
        <div className="ticket-list__box-price">{price}</div>
        <div className="ticket-list__box-delete"
          onClick={onClickHandler} >
          <span className="icon icon-delete"></span>
          Удалить
        </div>
      </div>
    </div>
  );
}

BasketTicket.propTypes = {
  price: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  onClickHandler: PropTypes.func.isRequired
}

export default BasketTicket;
