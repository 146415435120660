import React from 'react';
import PropTypes from 'prop-types';
import EmptyWrapper from '../../helpers/EmptyWrapper';

const TicketDetails = ( props ) => {
  let { data, color, price } = props;
  let priceString = price;
  if (price.toString() === parseInt(price).toString()) {
    priceString = price + ',00';
  }
  let details = data.map((name, index) => {
    return (
      <span key={index} className={name.attr === 'Место' ? "order order-location" : "order" }>
        <span>{name.attr}</span>
        &nbsp;
        <span className="order-value">{name.data}</span>
      </span>
    )
  })
  let discounts = null;
  if (props.discounts.length > 0) {
    discounts = (
      <span className="order">
        <span>Скидки:</span>
        &nbsp;
        <span className="order-discount">
          {
            props.discounts.map(( discount, index ) => {
              if (index === 0) {
                return discount
              }
              return ', ' + discount;
            })
          }
        </span>
      </span>
    );
  }
  return (
    <EmptyWrapper>
      <span className="order">
        <span className="color-value" style={{backgroundColor: color}}></span>
        <span className="order-value">{priceString}</span>
      </span>
      {details}
      {discounts}
    </EmptyWrapper>
  );
}

TicketDetails.propTypes = {
  price: PropTypes.number,
  color: PropTypes.string.isRequired,
  discounts: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired
}

export default TicketDetails;
