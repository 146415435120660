import React, {Component} from "react";

import './style.scss';

class Spinner extends Component{
    render() {
        return(
            <div className="spinner">
                <span className="spinner-icon">
                    <span></span>
                </span>
            </div>
        )
    }
}

export default Spinner;
