import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { eventsList } from "../../api/event";
import { connect } from "react-redux";
import {
  setSearch,
  setCurrentButtons,
  setPreloader,
  setDefaultSearch,
  setAsyncEvent
} from "../../store/actions/event";
import moment from "moment";
import Exit from "../../containers/exit";
import Logo from "../../assets/img/logo.svg";
import { error } from "../../containers/const";
import Modal from "../Modal/index";

class SearchHeader extends Component {
  state = {
    setDate: "",
    errorSearch: false,
    searchTimeout: false
  };

  Search = async e => {
    let { searchTimeout } = this.state;
    clearTimeout(searchTimeout);
    this.props.setSearch(e);
    let data;
    this.props.setPreloader(true);
    this.setState({
      searchTimeout: setTimeout(async () => {
        data = await this.props.setAsyncEvent();
        if (!data.events) {
          this.setState({ errorSearch: true });
          this.props.setDefaultSearch();
          this.Search("");
        }
      }, 300)
    });
  };

  componentDidMount() {
    let now = moment().format("DD/MM/YYYY H:mm:ss");
    if (
      moment(localStorage.getItem("timeWork"), "DD/MM/YYYY").format(
        "DD/MM/YYYY"
      ) !== moment(now, "DD/MM/YYYY").format("DD/MM/YYYY")
    ) {
      localStorage.removeItem("timeWork");
      localStorage.setItem("timeWork", now);
    }
    setInterval(() => {
      let now = moment().format("DD/MM/YYYY H:mm:ss");
      let dString = localStorage.getItem("timeWork");
      let date = moment
        .utc(
          moment(now, "DD/MM/YYYY HH:mm:ss").diff(
            moment(dString, "DD/MM/YYYY HH:mm:ss")
          )
        )
        .format("HH:mm:ss");
      this.setState({
        setDate: date
      });
    }, 1000);
  }

  exitFromProfile = () => {
    Exit.exit();
  };

  handleCloseModal = () => this.setState({ errorSearch: false });

  render() {
    const { setDate, errorSearch } = this.state;
    const { auth, searchInput } = this.props;
    return (
      <header className="header">
        <div className="header__content">
          <img src={Logo} className="logo" alt="Логотип" />
          <div className="input header__search">
            <span className="icon-search svg-icon"></span>
            <Link to={"/"}>
              <input
                disabled={errorSearch}
                onChange={e => this.Search(e.target.value)}
                type="text"
                placeholder="Поиск мероприятия"
                value={searchInput}
              />
            </Link>
          </div>
          <div className="user">
            <div className="user__info">
              <div className="user__name">{auth.client.fio}</div>
              <div className="user__date">В сети {setDate}</div>
            </div>
            <div className="user__image">
              <span className="icon-account svg-icon"></span>
            </div>
            <div onClick={this.exitFromProfile} className="user__logout">
              <span className="icon icon-logout"></span>
            </div>
          </div>
        </div>
        {errorSearch && (
          <Modal isClose hideModal={this.handleCloseModal}>
            <div className="modal-text">Произошла ошибка</div>
          </Modal>
        )}
      </header>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  searchInput: state.event.searchParameters.name_filter
});
const mapDispatchToProps = {
  setSearch,
  setCurrentButtons,
  setPreloader,
  setDefaultSearch,
  setAsyncEvent
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchHeader);
