import {AUTH} from '../const'

export function login(body) {
  return {
    type: AUTH.LOGIN,
    payload: body
  }
}

export function clientAuth(body) {
  return {
    type: AUTH.GET_CLIENT,
    payload: body
  }
}

export function loading(body) {
  return {
    type: AUTH.LOADING,
    payload: body
  }
}

export function logout() {
  return {
    type: AUTH.LOGOUT,
  }
}