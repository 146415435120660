import React, { Component } from 'react'

export default class Score extends Component {
    render() {
        const {cash, cart, deals} = this.props;
        return (
            <div className="sidebar-body">
                <div className="paid-heading">Оплачено:</div>
                <div className="paid-table">
                    <div className="paid-table__row">
                        <div className="paid-table__col">Наличные:</div>
                        <div className="paid-table__col">{cash} BYN</div  >
                    </div>
                    <div className="paid-table__row">
                        <div className="paid-table__col">Банковская карта:</div>
                        <div className="paid-table__col">{cart} BYN</div>
                    </div>
                    <div className="paid-table__row">
                        <div className="paid-table__col">Подарочный сертификат:</div>
                        <div className="paid-table__col">{deals?.coast_sertificate} BYN</div>
                    </div>
                    <div className="paid-table__row">
                        <div className="paid-table__col">Бонусные баллы:</div>
                        <div className="paid-table__col">{deals?.coast_cods} BYN</div>
                    </div>
                </div>
            </div>
        )
    }
}
