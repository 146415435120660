import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Authenticated from './containers/Authenticated';
import Event from './components/Event/events'
import SearchHeader from './components/Header/header'
import Basket from './components/Basket/basket'
import reserves from './components/Reserves/reserves';
import Checkout from './containers/Checkout';
import ListOrder from './components/Discounts/listOrder'
import fastBuy from './components/FastBuy'
import Footer from './components/Footer/index'
import Orders from "./components/Orders";
import Reports from "./components/Reports";
import withSubscriptionAuth from './hoc/AuthHoc'
class App extends Component {
  render() {
    return (
      <Router>
        <Authenticated>
            <div className="wrapper">
                <main className="main">
                    <SearchHeader />
                    <Switch>
                        <Route exact path='/' component={Event} />
                        <Route path='/event/:eventID' component={Checkout} />
                        <Route exact path='/basket' component={Basket} />
                        <Route exact path='/reserves' component={reserves} />
                        <Route exact path='/discount' component={ListOrder} />
                        <Route exact path='/fastbuy/:eventID' component={fastBuy} />
                        <Route exact path='/orders' component={Orders} />
                        <Route exact path='/reports' component={Reports} />
                    </Switch>
                </main>
                <Footer/>
            </div>
        </Authenticated>
      </Router>
    );
  }
}

const EnhancedAuthApi = withSubscriptionAuth(App);

export default EnhancedAuthApi
