import React, { Component } from 'react'
import { Link  } from 'react-router-dom';

const menu = [
    {
        title: 'Все мероприятия',
        section: 'event',
        url: '/',
    },
    {
        title: 'Текущее мероприятие',
        section: 'discount',
        url: '/discount',
    },
    {
        title: 'Печать билетов',
        section: 'basket',
        url: '/basket',
    },
    {
        title: 'Бронь',
        section: 'reserves',
        url: '/reserves',
    },
    {
        title: 'Заказы',
        section: 'orders',
        url: '/orders',
    },
    {
        title: 'Отчеты',
        section: 'reports',
        url: '/reports',
    }
];

export default class Index extends Component {
    render() {
        const { page } = this.props;

        return (
            <ul className="nav-list">
                {menu &&
                    menu.map((item, i) =>
                        <li className={`nav-list__item ${page === item.section && "active"}`} key={i}>
                            <Link to={item.url} className="nav-list__link">{item.title}</Link>
                        </li>
                    )
                }
            </ul>
        )
    }
}
