import Exit from '../containers/exit'
import { error } from '../containers/const'
import instance from './index';
import serviceApi from './serviceApi';

export const getBasket = () =>
    serviceApi(`/ticket-wc-api/v1/get-basket/`, {
    })
        .then(function (response) {
            return response.data.basket_entities
        }).catch((er) => {
            return error.notFound
        }
        )

export const deleteTicket = ( basket_id) =>
    serviceApi(`/ticket-wc-api/v1/ticket-delete/`, {
        basket_id: basket_id
    }).then(function (response) {
        return response.data.basket_entities
    }).catch((error) => {
        
        return error.notFound
    }
    )

export const orederPay = ( order_id, ident_type_id) =>
    serviceApi(`/ticket-wc-api/v1/seller-order-pay/`, {
        order_id: order_id,
        ident_type_id: ident_type_id,
        is_cash_active: false,
    })
        .then(function (response) {
            return response.data
        }).catch((error) => {
            
        }
        )

export const orderCreate = () =>
    serviceApi(`/ticket-wc-api/v1/order-create/`, {

    }
    ).then(function (response) {
        return { tickets: response.data.tickets, order_id: response.data.id }
    }).catch((err) => {
        throw err;
    }
    )
// create -> pay


export const transformSpecialToBasket = (special_basket_entity_id) =>
    serviceApi(`/ticket-wc-api/v1/move-special-basket-to-basket/`, {
        special_basket_entity_id: special_basket_entity_id,
    }
    ).then(function (response) {
        return response.data
    }).catch((er) => {
        return error.notFound
    }
    )

export const transformBasketToSpecial = () =>
    serviceApi(`/ticket-wc-api/v1/move-basket-to-special-basket/`, {
        basket_id: 146014,
    }
    ).then(function (response) {
        return response.data
    }).catch((err) => {
        return error.notFound
    }
    )

export const deletedOrder = (basket_id) =>
    serviceApi(`/ticket-wc-api/v1/basket-delete/`, {
        basket_id: basket_id,
    }
    ).then(function (response) {
        return response.data.basket_entities
    }).catch((err) => {
       
        return error.notFound
    }
    )

export const payTwoSum = ( order_id, { pay_sum_1, pay_sum_2, ident_type_1, ident_type_2 }, is_cash_active,check_no) => {
    return serviceApi(`/ticket-wc-api/v1/seller-order-twosum-pay/`, {
        order_id: `${order_id}`,
        ident_type_1: ident_type_1,
        pay_sum_1: pay_sum_1,
        ident_type_2: ident_type_2,
        pay_sum_2: pay_sum_2,
        is_cash_active: is_cash_active,
        // check_no: check_no ? check_no : ''
    }
    ).then(function (response) {
        return response.data
    }).catch((err) => {
        throw err
    }
    )
}

export const getTypesTickets = (order_id = 390152) => {
    return serviceApi('/ticket-wc-api/v1/get-order-valid-tictempl-types/', {
        order_id: order_id,
        min: 1,
        max: 100,
    })
}

export const setOrderAnalog = (data) => {
    return serviceApi(`/ticket-wc-api/v1/analog-use/`, data);
}
//Удаление заказа текущего пользователя
export const orderDelete = (order_id) =>
    serviceApi(`/ticket-wc-api/v1/order-delete/`, {
        order_id: order_id
    })
        .then(function (response) {
            return response.data
        })
        .catch((error) => {
            throw error
        });