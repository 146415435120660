import React, { Component } from 'react'
import _ from 'lodash';
import { Link } from 'react-router-dom';
import {ordersList, ticketCheckProm} from '../../api/ticket';
import {Dropdown} from "semantic-ui-react";
import {connect} from "react-redux";
import Spinner from "../Spinner";

class TicketItem extends Component {
    constructor() {
        super();

        this.state = {
            isPromo: false,
            promoValue: '',
            promoStatus: null,
            priceBase: null,
            ticketsDiscount: [],
            discountId: null,
            errorTickets: false,
            defaultSelected: false,
            discountsArray: [],
            // preloader: false
        }
    }

    componentDidMount() {
        this.setState({
            discountId: this.props.discountId
        }, function () {
            this.setOldPrice();
        });

        const {discountsArray} = this.state;
        const {discountsList, discountId, promoValue} = this.props;
        if (discountsList.length){
            discountsArray.push({
                key: 0,
                value: 0,
                text: 'Выберите скидку',
            })
        }
        discountsList.map(discount => {
            discountsArray.push({
                key: discount.id,
                value: discount.id,
                text: discount.descriptions[0].name,
                disabled: discount.reached_ticket_count && true,
                promo_needed: discount.promo_needed,
            });
            if (discountId !== null && promoValue !== null && discountId === discount.id){
                this.setState({
                    isPromo: true,
                    promoValue: promoValue
                })
            }
        });
    }

    checkedInput = (id) => {
        if ( this.props.include.includes((id))) {
            return true
        }
        return false
    };

    setOldPrice = () => {
        const {priceId, pricesList} = this.props;
        const oldPrice = _.find(pricesList, {id: priceId}).price;
        this.setState({
            priceBase: oldPrice
        });
    };

    changeDiscount = async (e, value) =>{
        this.setState({
            preloader: true,
            promoStatus: null
        });
        console.log('------- changeDiscount -------')
        console.log('value', value)
        // console.log('change');
        const promoNeeded = e.target.classList.contains('text') && e.target.closest('.item').hasAttribute('promo_needed') ?
            +e.target.closest('.item').getAttribute('promo_needed') :
            e.target.hasAttribute('promo_needed') ?
                +e.target.getAttribute('promo_needed') :
                false;
        if (promoNeeded){
            // console.log('promo')
            this.setState({
                isPromo: true,
                discountId: value,
                defaultSelected: value,
                // preloader: false
            });
            return false;
        } else {
            this.setState({
                isPromo: false,
                promoValue: '',
                defaultSelected: false,
            });
            const { id } = this.props;
            const discount = value ? value : null;
            const ticketsDiscount = await ticketCheckProm( id, discount);
            if (ticketsDiscount){
                this.setState({
                    discountId: value,
                    // preloader: false
                }, function () {
                    this.setOldPrice();
                });
                return(ticketsDiscount.basket_entities)
            } else {
                return ticketsDiscount.text
            }
        }
    };

    checkPromo = async () => {
        const {promoValue, discountId} = this.state;
        if (promoValue){
            const { id } = this.props;
            console.log('------ checkPromo ------')
            console.log('id', id)
            const discount = discountId ? discountId: null;
            const ticketsDiscount = await ticketCheckProm( id, discount, promoValue);
            if (!ticketsDiscount.error){
                this.setState({
                    promoStatus: true,
                })
            } else {
                this.setState({
                    promoStatus: false
                })
            }
        } else {
            this.setState({
                promoStatus: null
            })
        }
    };


    render() {
        const {isPromo, promoValue, promoStatus, priceBase, preloader, defaultSelected, discountsArray} = this.state;
        const { choiceTicket, coast, id, data, discountsList, setDiscount, discountId, ticket_type_id } = this.props;
        let firstOne, firstSecond, secondOne, secondSecond, thirdOne, thirdSecond, fourthOne, fourthSecond, places = [];

        // console.log('render ---------')
        // console.log('id', id)
        // console.log('discountsList', discountsList)
        // console.log('discountId', discountId)
        // console.log('promoValue', promoValue)

        data.forEach(item => {
            places.push(item);
        });

        if (places[0]) {
            firstOne = places[0][0] ? places[0][0] : '';
            firstSecond = places[0][1] ? places[0][1] : '';
        }
        if (places[1]) {
            secondOne = places[1][0] ? places[1][0] : '';
            secondSecond = places[1][1] ? places[1][1] : '';
        }
        if (places[2]) {
            thirdOne = places[2][0] ? places[2][0] : '';
            thirdSecond = places[2][1] ? places[2][1] : '';
        }
        if (places[3]) {
            fourthOne = places[3][0] ? places[3][0] : '';
            fourthSecond = places[3][1] ? places[3][1] : '';
        }

        let promoIcon = '';
        if (promoStatus !== null){
            if (promoStatus){
                promoIcon = <span className="icon icon-circle-check"></span>;
            } else {
                promoIcon = <span className="icon icon-circle-close"></span>;
            }
        }

        return (
            <div key={id} className="table-row">
                <div className="table-col discount-check">
                    <input onChange={choiceTicket} name={id} type="checkbox" checked={this.checkedInput(id)} id={`check-${id}`}></input>
                    <label name={id} htmlFor={`check-${id}`} className="checkbox"></label>
                </div>
                {ticket_type_id === 5 ? <div className="tickets-kit">Пакет</div> : null}
                <div className="table-col discount-seats">
                    <div className="discount-seats__group">
                        <div className="discount-seats__group-seat">{firstOne} <b>{firstSecond}</b></div>
                        <div className="discount-seats__group-seat">{secondOne} <b>{secondSecond}</b></div>
                    </div>
                    <div className="discount-seats__group">
                        <div className="discount-seats__group-seat">{thirdOne} <b>{thirdSecond}</b></div>
                        <div className="discount-seats__group-seat">{fourthOne} <b>{fourthSecond}</b></div>
                    </div>
                </div>
                <div className="table-col discount-type">
                    <Dropdown
                        placeholder="Выберите скидку"
                        selection
                        scrolling
                        options={discountsArray}
                        value={defaultSelected ? defaultSelected : discountId}
                        disabled={!discountsArray.length}
                        // onChange={(e, data) => setDiscount(id, data.value, promoValue, isPromo, discountsList)}
                        onChange={(e, data) => setDiscount(this.changeDiscount(e, data.value))}
                    />
                </div>
                <div className="table-col discount-promocode">
                    <input
                        type="text"
                        placeholder="Промо-код"
                        disabled={!isPromo}
                        value={promoValue}
                        onChange={e => this.setState({promoValue: e.target.value})}
                        onBlur={e => {this.checkPromo(e.target.value)}}
                    />
                    {promoIcon}
                </div>
                <div className="table-col discount-price">
                    <b>{coast} BYN</b>
                    {!!priceBase && (priceBase !== coast) &&
                        <div className="old-price">{priceBase} BYN</div>
                    }
                </div>
                {/*{preloader && <Spinner />}*/}
            </div>
        )
    }
}

const MapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(MapStateToProps)(TicketItem)
