import store from '../store/store';
import { AUTH } from '../store/const';
import { getXML } from '../server/implementation/index';
import { login, clientAuth, loading } from '../store/actions/auth';
import { clientInfo } from '../api/auth';
import { connect } from 'react-redux';

class Exit {
  static exit() {
    store.dispatch({
      type: AUTH.LOGOUT,
    });

    store.dispatch({
      type: AUTH.LOADING,
      payload: false,
    });

    localStorage.removeItem('ssoclid');
    localStorage.removeItem('timeWork');
    localStorage.setItem('timeTo', false);
  }

  static async reloadAuth() {

    var login = localStorage.getItem('loginAuth');
    var password = localStorage.getItem('passwordAuth');

    let tryToLogin = (await getXML(
      login,
      password
    ).then());
    if (tryToLogin.ssoc) {
      this.props.login(tryToLogin.ssoc);
      let sso = await (clientInfo(tryToLogin.ssoc));
      console.log(sso.response)
      if (!sso.error) {
        this.props.clientAuth(sso.response.data);
        this.props.loading(true);
      }
    }
  }
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = {
    login,
    clientAuth,
    loading
};

export default connect(mapStateToProps, mapDispatchToProps)(Exit)
