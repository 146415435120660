import { error } from '../containers/const'
import serviceApi from './serviceApi';

export const getPricesList = (event_id) =>
serviceApi(`/ticket-wc-api/v1/get-node-prices-list/`, {
      event_id: event_id,
      min: 1,
      max: 100,
     })
    .then(function (response) {
      return response.data
    }).catch((err) => {
      return error.notFound
    }
    )