import React from 'react';
import PropTypes from 'prop-types';

const Payment = ( props ) => {
  const { ticketQuantity, paymentAmount, currency, onClickHandler } = props;
  return (
    <div className="payment">
      <div className="sidebar-body">
        <div className="payment-icon">
          <span className="icon icon-backet"></span>
        </div>
        <div className="payment-count">Итого билетов в корзине: <b>{ticketQuantity} шт</b></div>
        <div className="payment-price">На сумму: <b>{Number.isInteger(paymentAmount) ? paymentAmount : paymentAmount.toFixed(2)} {currency}</b></div>
        <div className="payment-button">
          <button
            type="button"
            className="btn btn-orange"
            onClick={onClickHandler} >
            <span className="icon icon-brief"></span>
            К оплате
            </button>
        </div>
      </div>
    </div>
  );
}

Payment.propTypes = {
  ticketQuantity: PropTypes.number.isRequired,
  paymentAmount: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  onClickHandler: PropTypes.func.isRequired
}

export default Payment;
