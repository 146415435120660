import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Login from './Login';
import { loading, clientAuth } from '../store/actions/auth'
import { clientInfo } from '../api/auth'

import { Route } from 'react-router-dom';

class Authenticated extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    children: PropTypes.any,
  };

  state = {
    preloader: true
  }

  componentDidMount = async () => {
    if (this.props.auth.ssoclid !== null) {
      this.setState({ preloader: false });
      let log = await clientInfo();
      // console.log(log);
      this.props.loading(!log.error);
      log.error !== true && log.response && this.props.clientAuth(log.response.data);
      // log.error === true && localStorage.removeItem('ssoclid');
      // log.error === true && localStorage.removeItem('timeWork');
    }
    this.setState({ preloader: true });
  }

  render() {
    const { children } = this.props;
    if (this.state.preloader) {
      return (
        !this.props.auth.loading
          ? <Route path='/' component={Login}>
            <Route path="/" component={Login} />
          </Route>
          : children
      );
    } else {
      return <div></div>;
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth
})
const mapDispatchToProps = {
  loading,
  clientAuth
}
export default connect(mapStateToProps, mapDispatchToProps)(Authenticated)
