import PropTypes from 'prop-types';
import React, { Component } from 'react'

class ListItem extends Component {
  
    checkedInput = (id) => {
        if ( this.props.include.includes(String(id))) {
            return true
        }
        return false
    }
    
    render() {
        const { id, name } = this.props;
        return (
            <div name={id} className="filter__section-item">
                <input onChange={this.props.selectedIt} checked={this.checkedInput(id)} name={id} type="checkbox" id={`checkbox-${id}${name}`} />
                <label id="a12" htmlFor={`checkbox-${id}${name}`} className="checkbox"></label>
                <label htmlFor={`checkbox-${id}${name}`}>{name}</label>
            </div>
        );
    }
}

ListItem.propTypes = {
    include: PropTypes.array.isRequired,
    id: PropTypes.number.isRequired,
    selectedIt: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired
}

export default ListItem;
