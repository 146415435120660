import { find } from 'lodash';
import { parseNameAttributeMini } from '../../helpers/index';
import { totalPlanList } from '../../api/event';
import { getCancelablePromise } from '../../helpers/index';
import axios from "axios";

class CashRegister {
    static async check(tickets, event, priceTickets) {
        let checkTickets = [];
        let planList = [];
        let terminal = await axios.get(`http://169.254.126.248/cgi/dev_info`, {
            timeout: 1000
        }).then(res => {
            const plan = getCancelablePromise(totalPlanList());
            return Promise.all([plan.promise])
                .then((data) => {
                    planList = data[0];
                    tickets.map(item => {
                        checkTickets.push({ 'C': { 'cm': find(event, { 'event': { id: item.event_id } }) && find(event, { 'event': { id: item.event_id } }).event.descriptions[0].name } });
                        checkTickets.push({ 'C': { 'cm': parseNameAttributeMini(planList, item.ticket_name) } });
                        checkTickets.push({
                            "S": {
                                "code": `${find(event, { 'event': { id: item.event_id } }) && find(event, { 'event': { id: item.event_id } }).event.id}`, "name": "Покупка билетов", "qty": 1.000, "price": `${item.price}`
                                , "sum": `${item.price}`, "dep": 1, "grp": 1, "tax": 1, "ctype": 1
                            }
                        });
                        checkTickets.push({ "C": { "cm": "В том числе:" } });
                        checkTickets.push({ "C": { "cm": `Стоимость билета: ${item.promoter_amount}` } });
                        checkTickets.push({ "C": { "cm": `Сервисный сбор: ${item.commission_amount + item.other_commission_amount}` } });
                    });
                    checkTickets.push(({ "P": { "sum": `${priceTickets}` } }));
                    console.log(checkTickets)
                        return axios.post(`http://169.254.126.248/cgi/chk`, {
                            "F": checkTickets
                        }).then(res => {
                            console.log(res.data);
                            return res.data.no;
                        }).catch(err => {
                            return undefined;
                    })
                })
        })
            .catch(error => {
                return false;
            });
        return await terminal;
    }
}

export default CashRegister;
