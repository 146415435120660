import React, { Component } from 'react'

export default class EventType extends Component {
    render() {
        const { time, date, name, location, city, type } = this.props;
        return (
            <div className="scheme__header">
                <div className="table-row">
                    <span className="table-col event-date">{time}</span>
                    <span className="table-col event-time">{date}</span>
                    <span className="table-col event-name">{name}</span>
                    <span className="table-col event-location">{location}</span>
                    <span className="table-col event-city">{city}</span>
                    <span className="table-col event-type">{type}</span>
                </div>
            </div>
        )
    }
}
