import axios from 'axios';
import { getApiUrl } from '.././server/config';
import store from '../store/store';
import { getXML } from '../../src/server/implementation/index';
import { AUTH } from '../store/const';

const instance = axios.create({
  baseURL: getApiUrl,
});

let isAlreadyFetchingAccessToken = false
let subscribers = []

function onAccessTokenFetched(access_token) {
  subscribers = subscribers.filter(callback => callback(access_token))
}

function addSubscriber(callback) {
  subscribers.push(callback)
}
instance.interceptors.response.use(function (response) {
  return response
}, async function (error) {
  const { config, response: { status } } = error
  const originalRequest = config
  if (error.response.data.error === 801) {

    const retryOriginalRequest = new Promise((resolve) => {
      addSubscriber(token => {
        let arr = originalRequest.url.split("/");
        (arr[arr.length - 1] = token);
        originalRequest.url = arr.join('/');
        resolve(axios(originalRequest))
      })
    });

    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true
      var login = localStorage.getItem('loginAuth');
      var password = localStorage.getItem('passwordAuth');
      var { ssoc } = (await getXML(
        login,
        password
      ));
      store.dispatch({
        type: AUTH.LOGIN,
        payload: ssoc
      });
        isAlreadyFetchingAccessToken = false
        onAccessTokenFetched(ssoc)
    }
    return retryOriginalRequest
  }
  return Promise.reject(error)
})

export default instance;
