import React from 'react';
import PropTypes from 'prop-types';
import { UncontrolledReactSVGPanZoom } from 'react-svg-pan-zoom';
import { AutoSizer } from 'react-virtualized';

const SVG = ({ contWidth, contHeight, setViewer, svgWidth, svgHeight, svg }) => {

  return (
    <AutoSizer defaultWidth={contWidth} defaultHeight={contHeight}>
      {({ width, height }) => {
        return (
          <UncontrolledReactSVGPanZoom
            width={width}
            height={height}
            ref={Viewer => {
              return setViewer(Viewer);
            }}
            background="#FFFFFF">
              <svg
                width={svgWidth}
                height={svgHeight} >
                  <g dangerouslySetInnerHTML={{__html: svg}}/>
              </svg>
          </UncontrolledReactSVGPanZoom>
        )
      }}
    </AutoSizer>
  );
}

SVG.propTypes = {
  contWidth: PropTypes.number.isRequired,
  contHeight: PropTypes.number.isRequired,
  svgWidth: PropTypes.number.isRequired,
  svgHeight: PropTypes.number.isRequired,
  svg: PropTypes.string.isRequired,
  setViewer: PropTypes.func.isRequired
}

export default SVG;
