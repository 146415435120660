import React, { Component } from 'react'
import { reservedOrder, deleteTicketSpecialBasket, listSpecialBaskets, deleteSpecialBasket } from '../../api/ticket'
import { transformSpecialToBasket } from '../../api/basket'
import { connect } from 'react-redux';
import { getSchemeForSeats } from '../../api/event'
import _ from 'lodash';
import Ticket from '../CheckoutBasket/Ticket';
import { eventsList } from '../../api/event';
import { parseNameAttribute } from '../../helpers';
import Header from '../SwitchingHeader/index';
import ReservesTicket from '../ReservesTicket/index';
import { getCancelablePromise } from '../../helpers/index';
import { setMax } from '../../store/actions/event';
import { Pagination } from 'semantic-ui-react';
import { error } from '../../containers/const';
import Spinner from "../Spinner";
import Modal from '../Modal';

class Reservation extends Component {

    state = {
        tickets: [],
        id: null, fio: null, email: null, phone: null,
        seatScheme: [],
        eventsList: [],
        coastTickets: 0,
        listReserves: [],
        currentPage: 1,
        min: 1,
        pagesReserves: 0,
        max: 15,
        currentId: false,
        preloader: true,
        errorReserves: false,
        searchTimeout: false,
    }

    componentDidMount = async () => {
        this.props.setMax(500)

        const Seats = getCancelablePromise(getSchemeForSeats());

        Promise.all([Seats.promise])
            .then((data) => {
                this.setState({
                    seatScheme: data[0],
                    preloader: false,
                })
            })

        this.searchReserves();
    }

    setTotalEvent = (id) => {
        return (_.find(this.state.eventsList.events, { id: id })).date_time_from
    }

    selectReserved = async (e) => {
        if (this.state.currentId === +e.target.getAttribute('code')) {
            this.setState({ tickets: [], currentId: null, coastTickets: 0 })
        } else {
            this.setState({ tickets: [] })
            let coast = 0;
            let list = (_.find(this.state.listReserves, { code: e.target.id }));
            if(list) {
            let basketTickets = list.special_basket_entities
            basketTickets.forEach(item => coast = +coast.toFixed(2) + +item.price.toFixed(2))
            this.setState({ coastTickets: coast })
            this.setState({ tickets: basketTickets, currentId: +e.target.getAttribute('code') });
            }
        }
    }

    searchReservedId = async (e) => {
        let { searchTimeout } = this.state;
        clearTimeout(searchTimeout);

        this.setState({ tickets: [] })
        this.setState({ coastTickets: 0 })
        this.findReservedOrder(e.target.value)
    }

    findReservedOrder = async (id) => {
        this.setState({ preloader: true });

        if (!id.length) {
            this.searchReserves();
            this.setState({
                preloader: false,
            })
        } else {
            let searchData;
            if (id.includes('@')) {
                searchData = {'email': id.toUpperCase()};
            } else if (id.includes('+')) {
                searchData = {'phone': id.toUpperCase()};
            } else if (id.includes(' ')) {
                searchData = {'customer_name': id.toUpperCase()};
            } else {
                searchData = {'special_basket_code': id.toUpperCase()};
            }
            this.setState({
                searchTimeout: setTimeout((e) => {
                    let reservedOrderData = getCancelablePromise(reservedOrder(searchData));
                    Promise.all([reservedOrderData.promise])
                        .then((data) => {
                            if (data[0] === error.notFound) {
                                this.setState({ listReserves: [], preloader: false });
                            } else {
                                this.setState({
                                    listReserves: [data[0]],
                                    preloader: false,
                                })
                            }
                        })
                }, 300)
            })
        }
    }

    removeTicketFromBasket = async (special_basket_entity_id) => {
        let tickets = await deleteTicketSpecialBasket(special_basket_entity_id);
        if (tickets !== error.notFound ) {
        if(tickets && tickets.special_basket_entities && tickets.special_basket_entities.length === 0) {
            let newList = this.state.listReserves.filter(item => item.id !== this.state.currentId);
            this.setState({
                listReserves: newList
            });
        }
        this.setState({ tickets: tickets.special_basket_entities, coastTickets: 0 });
        tickets.special_basket_entities.forEach(item => this.setState({ coastTickets: this.state.coastTickets + +item.price.toFixed(2) }));
    } else {
        this.setState({ errorReserves: true })
    }
    }

    butTicketsReservation = () => {
        var ctr = 0;
        this.state.tickets.forEach(item => {
          transformSpecialToBasket(item.id).then(res => {
              if(res !== error.notFound) {
                ctr++;
                if(ctr === this.state.tickets.length) {
                  this.props.history.push('/basket');
                }
              } else {
                this.setState({ errorReserves: true })
              }
          });
        });
    }

    setPageNum = (e, { activePage }) => {
        this.setState({
            currentPage: activePage,
            min: (((activePage - 1) * 15) + 1),
            max: activePage * 15,

        }, function () {
            this.searchReserves();
        })
    }

    searchReserves = async () => {
        const { min, max } = this.state;
        this.setState({ preloader: true });
        let reservedOrder = await listSpecialBaskets(min, max)
        if (reservedOrder !== error.notFound && reservedOrder.special_baskets.length) {
            this.setState({
                listReserves: reservedOrder.special_baskets,
                countReserves: reservedOrder.all_count,
                pagesReserves: (Math.ceil(reservedOrder.all_count / 15)),
                preloader: false,
            });
        } else if (reservedOrder === error.notFound) {
            this.setState({ errorReserves: true })
        } else {
            this.setState({
                listReserves: [],
                countReserves: 0,
                pagesReserves: 0,
            })
        }
    }

    deletedSpecialBasket = () => {
        deleteSpecialBasket(this.state.currentId).then(res => {
            if(res !== error.notFound) {
                let newList = this.state.listReserves.filter(item => item.id !== this.state.currentId);
                this.setState({ tickets: [], coastTickets: 0, currentId: false, listReserves: newList });
            } else {
                this.setState({ errorReserves: true });
            }
        })
    }

    handleCloseModal = () => {
        this.setState({ errorReserves: false })
    }

    render() {
        const { preloader, errorReserves } = this.state;
        return (
            <>
                <nav className="nav">
                    <Header page="reserves" />
                </nav>
                <div className="content-wrap">
                    <section className="content">
                        <div className="heading">
                            <h1>Забронированные билеты</h1>
                        </div>
                        <div className="table-filter">
                            <div className="table-filter__search">
                                <input onChange={this.searchReservedId} type="text" placeholder="Номер брони" />
                                <span className="icon icon-search"></span>
                            </div>
                        </div>
                        <div className="table-wrap">
                            <div className="table order-table">
                                <div className="table-body">
                                    {preloader && <Spinner />}
                                    {this.state.listReserves.map(item => (
                                        <ReservesTicket
                                            key={item.id}
                                            id={item.id}
                                            code={item.code}
                                            selectReserved={this.selectReserved}
                                            fio={item.customer_name}
                                            currentId={this.state.currentId}
                                            email={item.email}
                                            phone={item.phone}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="pagination">
                            <div className="pagination__links">
                                <Pagination
                                    activePage={this.state.currentPage}
                                    totalPages={this.state.pagesReserves}
                                    siblingRange={1}
                                    onPageChange={this.setPageNum}
                                    firstItem={null}
                                    lastItem={null}
                                    prevItem={this.state.currentPage !== 1 && { className: "pagination__item", content: <span className="svg-icon icon-arr-left"></span>, icon: true }}
                                    ellipsisItem={null}
                                    pageItem={{ className: "pagination__item" }}
                                    boundaryRange={0}
                                    nextItem={this.state.pagesReserves !== 0
                                        && this.state.pagesReserves !== this.state.currentPage && { className: "pagination__item", content: <span className="svg-icon icon-right"></span>, icon: true }}
                                />
                            </div>
                        </div>
                    </section>
                    <aside className="sidebar sidebar-right">
                        <div className="basket">
                            <div className="sidebar-head"><span className="icon icon-backet"></span>Корзина</div>
                            <div className="sidebar-body">
                                {this.state.tickets.length !== 0 && this.state.tickets.map(ticket =>
                                    (
                                        <Ticket key={ticket.id}
                                            price={ticket.price + ' BYN'}
                                            data={parseNameAttribute(this.state.seatScheme, ticket.ticket_name)}
                                            onClickHandler={(e) => {
                                                this.removeTicketFromBasket(ticket.id);
                                            }} >
                                        </Ticket>
                                    )
                                )}
                                {this.state.currentId && this.state.tickets.length ?
                                    <div className="ticket-list__action">
                                        <div onClick={this.deletedSpecialBasket} className="ticket-list__action-clear"><span className="icon icon-delete"></span>Очистить все</div>
                                    </div> : null}
                            </div>
                        </div>
                        <div className="payment">
                            <div className="sidebar-body">
                                <div className="payment-icon">
                                    <span className="icon icon-backet"></span>
                                </div>
                                <div className="payment-count">Итого билетов в корзине: <b>{this.state.tickets.length} шт</b></div>
                                <div className="payment-price">На сумму: <b>{Number.isInteger(this.state.coastTickets) ? this.state.coastTickets : this.state.coastTickets.toFixed(2)} BYN</b></div>
                                <div className="payment-button">
                                    <button onClick={this.butTicketsReservation} type="button" className="btn btn-orange">
                                        <span className="icon icon-brief"></span>К оплате
                                    </button>
                                </div>
                            </div>
                        </div>
                    </aside>
                    <div className="sidebar-icon">
                        <button className="btn"><span className="icon icon-backet"></span></button>
                    </div>
                </div>
                {errorReserves && (
                    <Modal
                        isClose
                        hideModal={this.handleCloseModal}
                    >
                        <div className="modal-text">Произошла ошибка</div>
                    </Modal>
                )}
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
})

const mapDispatchToProps = {
    setMax
}

export default connect(mapStateToProps, mapDispatchToProps)(Reservation)
