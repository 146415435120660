import React from 'react';
import PropTypes from 'prop-types';

const PriceBox = ( props ) => {
  let priceValue = props.price;
  if (typeof priceValue === 'number' && priceValue.toString().indexOf('.') === -1) {
    priceValue += '.00';
  }
  return (
    <div className="color">
      <span
        style={{backgroundColor: props.color}}
        className="color-value" ></span>
      <span>{priceValue}</span>
    </div>
  );
}

PriceBox.propTypes = {
  price: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  color: PropTypes.string.isRequired
}

export default PriceBox;
